import React, { useState, useEffect } from "react";
import { Box, DateInput, Text, Heading, Form, FormField, TextInput, Button, Select, CheckBox } from "grommet";
import firebase from '../firebase';
import moment from 'moment';
import { Filter } from "grommet-icons";


export default function AddLav(props) {
   
  /**
   * Funzione che aggiunge un macchinario a database e aggiorna anche le manutenzioni
   */
  const onAdd = () => {
    const db = firebase.firestore();
    
    db.collection('Macchinari').add({ NomeMacchina: newNameMacchina, 
                                      Tagliando: newTagliando, 
                                      Telaio: newTelaio, 
                                      Marca: newMarca, 
                                      UltimoUtilizzo: newUltimoUtilizzo, 
                                      DimensioneTaglio: newDimensioneTaglio, 
                                      TempoMacchinaIniziale: newArea,
                                      TempoMacchinaAttuale: newArea, 
                                      Tipologia: newTipologia, 
                                      Alimentazione: newAlimentazione, 
                                      Societa: newSocieta, 
                                      Anno: newAnno, 
                                      Note: newNote, 
                                      CostoOrario: newCostoOrario, 
                                      ValoreMacchina: newValoreMacchina, 
                                      TariffaOraria: newTariffaOraria, 
                                      FiltroOlioIdraulico: newFiltroOlioIdraulico, 
                                      FiltroOlio: newFiltroOlio, 
                                      Lame: newLame,
                                      OlioMotore: newOlioMotore,
                                      FiltroOlioMotore: newFiltroOlioMotore,
                                      FiltroGasolio: newFiltroGasolio,
                                      FiltroBenzina: newFiltroBenzina,
                                      FiltroAria: newFiltroAria,
                                      OlioIdraulico: newOlioIdraulico,
                                      Ingrassatore: newIngrassatura,
                                      Lappatura: newLappatura,
                                      Candele: newCandele,
                                      Accessori: newAccessori,
                                      Cantiere: newCantiere, 
                                      Zona: newZona,
                                      Adl: newAdl,
                                      Lavori: newLav.Lav,
                                      CanId: newCanId,
                                      CodiceGestionale: newCode,
                                      Accesa: false
                                    });
    /*CHANGE PER MANUTENZIONE MACCHINE*/
    db.collection('Manutenzione').add({ NomeMacchina: newNameMacchina,
                                        Telaio: newTelaio,
                                        OreCalcolate: newArea,
                                        CambioFiltroOlioIdraulico: newFiltroOlioIdraulicoChange,
                                        CambioFiltroOlio: newFiltroOlioChange,
                                        AffilareLame: newLameChange,
                                        CambioOlioMotore: newOlioMotoreChange,
                                        CambioFiltroOlioMotore: newFiltroOlioMotoreChange,
                                        CambioFiltroGasolio: newFiltroGasolioChange,
                                        CambioFiltroBenzina: newFiltroBenzinaChange,
                                        CambioFiltroAria: newFiltroAriaChange,
                                        CambioOlioIdraulico: newOlioIdraulicoChange,
                                        EffettuareIngrassatura: newIngrassaturaChange,
                                        EffettuareLappatura: newLappaturaChange,
                                        EffettuareCandele: newCandeleChange,
                                        Cantiere: newCantiere, 
                                        Zona: newZona,
                                        CanId: newCanId,
                                        CodiceGestionale: newCode
                                      });
                                      props.onEscLayer();
    /*END MANUTENZIONE MACCHINE*/
  }
  
  const [newNameMacchina, setNewNameMacchina] = useState("");
  const [newTagliando, setNewTagliando] = useState("");
  const [newTelaio, setNewTelaio] = useState("");
  const [newMarca, setNewMarca] = useState("");
  const [newUltimoUtilizzo, setNewUltimoUtilizzo] = useState("");
  const [newDimensioneTaglio, setNewDimensioneTaglio] = useState("");
  const [newArea, setNewArea] = useState("");
  const [newTipologia, setNewTipologia] = useState("");
  const [newAlimentazione, setNewAlimentazione] = useState("");
  const [newSocieta, setNewSocieta] = useState("");
  const [newAnno, setNewAnno] = useState("");
  const [newNote, setNewNote] = useState("");
  const [newCostoOrario, setNewCostoOrario] = useState("");
  const [newValoreMacchina, setNewValoreMacchina] = useState("");
  const [newTariffaOraria, setNewTariffaOraria] = useState("");
  const [newFiltroOlioIdraulico, setNewFiltroOlioIdraulico] = useState(false);
  const [newFiltroOlioIdraulicoChange, setNewFiltroOlioIdraulicoChange] = useState("");
  const [newFiltroOlio, setNewFiltroOlio] = useState(false);
  const [newFiltroOlioChange, setNewFiltroOlioChange] = useState("");
  const [newLame, setNewLame] = useState(false);
  const [newLameChange, setNewLameChange] = useState("");
  /*CHANGE PER MANUTENZIONE MACCHINE*/
  const [newFiltroOlioMotore, setNewFiltroOlioMotore] = useState(false);
  const [newFiltroOlioMotoreChange, setNewFiltroOlioMotoreChange] = useState("");
  const [newOlioMotore, setNewOlioMotore] = useState(false);
  const [newOlioMotoreChange, setNewOlioMotoreChange] = useState("");
  const [newFiltroGasolio, setNewFiltroGasolio] = useState(false);
  const [newFiltroGasolioChange, setNewFiltroGasolioChange] = useState("");
  const [newFiltroBenzina, setNewFiltroBenzina] = useState(false);
  const [newFiltroBenzinaChange, setNewFiltroBenzinaChange] = useState("");
  const [newFiltroAria, setNewFiltroAria] = useState(false);
  const [newFiltroAriaChange, setNewFiltroAriaChange] = useState("");
  const [newOlioIdraulico, setNewOlioIdraulico] = useState(false);
  const [newOlioIdraulicoChange, setNewOlioIdraulicoChange] = useState("");
  const [newIngrassatura, setNewIngrassatura] = useState(false);
  const [newIngrassaturaChange, setNewIngrassaturaChange] = useState("");
  const [newLappatura, setNewLappatura] = useState(false);
  const [newLappaturaChange, setNewLappaturaChange] = useState("");
  const [newCandele, setNewCandele] = useState(false);
  const [newCandeleChange, setNewCandeleChange] = useState("");
  const [newCanId, setNewCanId] = useState("");
  const [newAccesa, setNewAccesa] = useState(false);
  const [elencoLavoriTot,setElencoLavoriTot]=React.useState([]);
  /*END MANUTENZIONE MACCHINE*/
  /*CHANGE PER ACCESSORI*/
  const [newAccessori, setNewAccessori] = useState(false);
  /*END ACCESSORI*/

  /*CHANGE CANTIERE*/
  const [newCantiere, setNewCantiere] = useState([]);
  const [newZona, setNewZona] = useState([]);
  const [newAdl, setNewAdl] = useState([]);
  const [newLav, setNewLav] = useState([]);
  const lav = firebase.firestore().collection('Lavori');
  let [lavori, setLavori] = useState([]);

  const [cantieri, setCantieri] = useState([]);
  const [cantieriZona, setCantieriZona] = useState([]);
  const cant = firebase.firestore().collection('Aree');

  /*CODICE GESTIONALE ESTERNO*/
  const [newCode, setNewCode] = useState("");
  /**/

   /**
   * Funzione che ottiene i cantieri presenti a sistema
   */
  function getCantieri() {
      cant.onSnapshot((querySnapshot) => {
        const items = [];
        querySnapshot.forEach((doc) => {
          items.push( { ... doc.data(), id: doc.id } );
        });
        setCantieri(items);
      });
  }

   /**
   * Funzione che in base al cantiere selezionato rileva le zone
   */
  function getCantieriZona() {
    if (newCantiere.length > 0) {
      cant.where('Nome', 'in', newCantiere).onSnapshot((querySnapshot) => {
        const items = [];
        querySnapshot.forEach((doc) => {
          items.push( { ... doc.data(), id: doc.id } );
        });
        setCantieriZona(items);
      });
    }
  }
  /*END CHANGE CANTIERE*/


  /**
   * Funzione che rileva tutti i lavori presenti a sistema
   */
  function getLavori() {

      lav.onSnapshot((querySnapshot) => {
          const items = [];
          querySnapshot.forEach((doc) => {
            items.push( { ... doc.data(), id: doc.id } );
          });
          
          setLavori(items);
          setElencoLavoriTot(items);
        });
  }

  /*
    Funzione che filtra i lavori in base al cantiere selezionato
  */
 function FilterCantiere(){
  if(newCantiere.length!==0){
    let lavoriApp=[];
    elencoLavoriTot.forEach((lavoro)=>{
      for(var i=0;i<lavoro.Cantiere.length;i++){
        if(newCantiere.indexOf(lavoro.Cantiere[i])!==-1){
          lavoriApp.push(lavoro);
        }
      } 
    })   
    setLavori(lavoriApp);
  }
 }

 /*
    Funzione che filtra i lavori in base alla zona selezionata
  */
 function FilterZona(){
  if(newZona.length!==0){
    let lavoriApp=[];
    elencoLavoriTot.forEach((lavoro)=>{
      if(lavoro.Zona!==undefined){
        for(var i=0;i<lavoro.Zona.length;i++){
          if(newZona.indexOf(lavoro.Zona[i])!==-1){
              lavoriApp.push(lavoro);
          }
        } 
      }   
    })   
    setLavori(lavoriApp);
  }
 }

 /*
    Funzione che filtra i lavori in base all'Adl selezionata
  */
 function FilterAdl(){
  if(newAdl.length!==0){
    let lavoriApp=[];
    elencoLavoriTot.forEach((lavoro)=>{
    if(lavoro.Adl!==undefined){
      for(var i=0;i<lavoro.Adl.length;i++){
        if(newAdl.indexOf(lavoro.Adl[i])!==-1){
            lavoriApp.push(lavoro);
        }
      } 
    }
  })   
  setLavori(lavoriApp);
  }
 }

 //Gestisce i filtri in base alle selezioni
  React.useEffect(()=>{
    if(newCantiere.length===0 && newZona.length===0 && newAdl.length===0){
      getLavori();
    }else{
      FilterCantiere();
      FilterZona();
      FilterAdl();
    }
  },[newCantiere,newZona,newAdl])

   /**
   * Controlla se apro il componente per modificare un anagrafica e aggiorna i campi di conseguenza
   */
  useEffect(() => {
    getCantieri();
    getLavori();
    if(props.macchina!==undefined){
      setNewNameMacchina(props.macchina.NomeMacchina);
      setNewAccessori(props.macchina.Accessori);
      setNewAlimentazione(props.macchina.Alimentazione);
      setNewAnno(props.macchina.Anno);
      setNewCostoOrario(props.macchina.CostoOrario);
      setNewDimensioneTaglio(props.macchina.DimensioneTaglio);
      setNewFiltroAria(props.macchina.FiltroAria);
      setNewFiltroBenzina(props.macchina.FiltroBenzina);
      setNewFiltroGasolio(props.macchina.FiltroGasolio);
      setNewFiltroOlio(props.macchina.FiltroOlio);
      setNewFiltroOlioIdraulico(props.macchina.FiltroOlioIdraulico);
      setNewFiltroOlioMotore(props.macchina.FiltroOlioMotore);
      setNewIngrassatura(props.macchina.Ingrassatore);
      setNewLame(props.macchina.Lame);
      setNewLappatura(props.macchina.Lappatura);
      setNewMarca(props.macchina.Marca);
      setNewNote(props.macchina.Note);
      setNewOlioIdraulico(props.macchina.OlioIdraulico);
      setNewOlioMotore(props.macchina.OlioMotore);
      setNewSocieta(props.macchina.Societa);
      setNewTagliando(props.macchina.Tagliando);
      setNewTariffaOraria(props.macchina.TariffaOraria);
      setNewTelaio(props.macchina.Telaio);
      setNewTipologia(props.macchina.Tipologia);
      setNewUltimoUtilizzo(props.macchina.UltimoUtilizzo);
      setNewValoreMacchina(props.macchina.ValoreMacchina);
      setNewCantiere(props.macchina.Cantiere);
      setNewZona(props.macchina.Zona);
      setNewAdl(props.macchina.Adl);
      setNewLav({Lav: props.macchina.Lavori});
      setNewCanId(props.macchina.CanId);
      if (props.macchina.CodiceGestionale === undefined) {
        setNewCode("");
      } else {
        setNewCode(props.macchina.CodiceGestionale);
      }
      setNewAccesa(props.macchina.Accesa);
    }
  }, []);

  useEffect(() => {
    getCantieriZona();
  }, [newCantiere]);

  /*
    Funzione che resetta i campi dell'anagrafica
  */
  const reset=()=>{
    setNewNameMacchina("");
    setNewAccessori("");
    setNewAlimentazione("");
    setNewAnno("");
    setNewCostoOrario("");
    setNewDimensioneTaglio("");
    setNewFiltroAria("");
    setNewFiltroBenzina("");
    setNewFiltroGasolio("");
    setNewFiltroOlio("");
    setNewFiltroOlioIdraulico("");
    setNewFiltroOlioMotore("");
    setNewIngrassatura("");
    setNewLame("");
    setNewLappatura("");
    setNewMarca("");
    setNewNote("");
    setNewOlioIdraulico("");
    setNewOlioMotore("");
    setNewSocieta("");
    setNewTagliando("");
    setNewTariffaOraria("");
    setNewTelaio("");
    setNewTipologia("");
    setNewUltimoUtilizzo("");
    setNewValoreMacchina("");
    setNewCantiere([]);
    setNewZona([]);
    setNewAdl([]);
    setNewLav({Lav: ""});
    setNewCanId("");
    setNewCode("");
  }

  
  /**
   * Funzione che aggiorna un macchinario con i nuovi dati 
   */
  const onUpdate=()=>{
    const db = firebase.firestore();
    db.collection('Macchinari').doc(props.macchina.id).set({
      NomeMacchina: newNameMacchina, 
      Tagliando: newTagliando, 
      Telaio: newTelaio, 
      Marca: newMarca, 
      UltimoUtilizzo: newUltimoUtilizzo, 
      DimensioneTaglio: newDimensioneTaglio, 
      TempoMacchinaIniziale: newArea,
      /*TempoMacchinaAttuale: 0,*/
      Tipologia: newTipologia, 
      Alimentazione: newAlimentazione, 
      Societa: newSocieta, 
      Anno: newAnno, 
      Note: newNote, 
      CostoOrario: newCostoOrario, 
      ValoreMacchina: newValoreMacchina, 
      TariffaOraria: newTariffaOraria, 
      FiltroOlioIdraulico: newFiltroOlioIdraulico, 
      FiltroOlio: newFiltroOlio, 
      Lame: newLame,
      OlioMotore: newOlioMotore,
      FiltroOlioMotore: newFiltroOlioMotore,
      FiltroGasolio: newFiltroGasolio,
      FiltroBenzina: newFiltroBenzina,
      FiltroAria: newFiltroAria,
      OlioIdraulico: newOlioIdraulico,
      Ingrassatore: newIngrassatura,
      Lappatura: newLappatura,
      Accessori: newAccessori,
      Cantiere: newCantiere, 
      Zona: newZona,
      Adl: newAdl,
      Lavori: newLav.Lav,
      CanId: newCanId,
      Accesa: newAccesa,
      CodiceGestionale: newCode,
    });
    props.onEscLayer();
  }

  return (
    <Box round pad="medium" direction="column" background="white" align="center" overflow="scroll">
      <Heading level="2" margin="none" size="small">
        <Box direction="row" gap="large">
          {props.macchina===undefined?"Inserisci il macchinario":"Aggiorna il macchinario"}
          <Button label="Scarica modello" href={"./modelliCsv/Modello_Macchinari.zip"} style={{width:'200px',fontSize:'20px',background:'#006837',color:'white',fontWeight:'bold',textTransform: 'uppercase',textAlign:"center"}}></Button>  
        </Box>
      </Heading>
      <Box fill gap="medium" pad={{ vertical: "small" }} >
        <Form>
        <Box direction="row" align="center">
          <Box pad="xsmall">
            <FormField required label="Nome">
              <TextInput
                placeholder="es. Tosaerba 2mm
                "
                value={newNameMacchina}
                onChange={e => setNewNameMacchina(e.target.value)}
              />
            </FormField>
          </Box>
          <Box pad="xsmall">
            <FormField required label="Data del Tagliando">
              <DateInput
                format="dd/mm/yyyy"
                value={newTagliando}
                onChange={({ value }) => {setNewTagliando(value.split('T')[0])}}
                calendarProps={{ size: "small", locale: "it-IT", daysOfWeek: true, firstDayOfWeek: 1 }}
              />
            </FormField>
          </Box>
          <Box pad="xsmall">
            <FormField required label="Telaio">
              <TextInput
                placeholder="es. 134528736"
                value={newTelaio}
                onChange={e => setNewTelaio(e.target.value)}
              />
            </FormField>
          </Box>
        </Box>
        <Box direction="row" align="center">
          <Box pad="xsmall">
            <FormField required label="Ultimo Utilizzo (SOLO per prima creazione)">
              <DateInput
                format="dd/mm/yyyy"
                value={newUltimoUtilizzo}
                onChange={({ value }) => {setNewUltimoUtilizzo(value.split('T')[0])}}
                calendarProps={{ size: "small", locale: "it-IT", daysOfWeek: true, firstDayOfWeek: 1 }}
              />
            </FormField>
          </Box>
          <Box pad="xsmall">
            <FormField required label="Marca">
              <TextInput
                placeholder="es. Cat"
                value={newMarca}
                onChange={e => setNewMarca(e.target.value)}
              />
            </FormField>
          </Box>
          <Box pad="xsmall">
            <FormField required label="Can ID">
              <TextInput
                placeholder="es. B819C0F0"
                value={newCanId}
                onChange={e => setNewCanId(e.target.value)}
              />
            </FormField>
          </Box>
        </Box>
        <Box direction="row" align="center">
          <Box pad="xsmall">
            <FormField required label="Dimensione Di Taglio (cm)">
              <TextInput
                placeholder="es. 150"
                value={newDimensioneTaglio}
                onChange={e => setNewDimensioneTaglio(e.target.value)}
              />
            </FormField>
          </Box>
          <Box pad="xsmall">
            {props.macchina===undefined?
            <FormField required label="Ore Macchina Iniziale (SOLO per prima creazione)">
              <TextInput
                placeholder="es. 20000"
                onChange={e => setNewArea(parseInt(e.target.value))}
              />
            </FormField>
            :
            null
          }
          </Box>
          <Box pad="xsmall">
            <FormField required label="Modello">
              <TextInput
                placeholder="es. JD 2750 E-CUT"
                value={newTipologia}
                onChange={e => setNewTipologia(e.target.value)}
              />
            </FormField>
          </Box>
        </Box>
        <Box direction="row" align="center">
          <Box pad="xsmall">
            <FormField required label="Alimentazione">
              <TextInput
                placeholder="es. Benzina"
                value={newAlimentazione}
                onChange={e => setNewAlimentazione(e.target.value)}
              />
            </FormField>
          </Box>
          <Box pad="xsmall">
            <FormField required label="Società">
              <TextInput
                placeholder="es. Viridia"
                value={newSocieta}
                onChange={e => setNewSocieta(e.target.value)}
              />
            </FormField>
          </Box>
          <Box pad="xsmall">
            <FormField required label="Anno (acquisto)">
              <TextInput
                placeholder="es. 2015"
                value={newAnno}
                onChange={e => setNewAnno(e.target.value)}
              />
            </FormField>
          </Box>
        </Box>
        <Box direction="row" align="center">
          <Box pad="xsmall">
            <FormField required label="Note">
              <TextInput
                placeholder="es. Note Aggiuntive"
                value={newNote}
                onChange={e => setNewNote(e.target.value)}
              />
            </FormField>
          </Box>
          <Box pad="xsmall">
            <FormField required label="Costo Orario €/h (interno)">
              <TextInput
                placeholder="es. 5,5"
                value={newCostoOrario}
                onChange={e => setNewCostoOrario(e.target.value)}
              />
            </FormField>
          </Box>
          <Box pad="xsmall">
            <FormField required label="Valore Macchina €">
              <TextInput
                placeholder="es. 15.000,00"
                value={newValoreMacchina}
                onChange={e => setNewValoreMacchina(e.target.value)}
              />
            </FormField>
          </Box>
        </Box>
        <Box direction="row" align="center">
          <Box pad="xsmall">
            <FormField required label="Tariffa Oraria Noleggio (€/h)">
              <TextInput
                placeholder="es. 7,5"
                value={newTariffaOraria}
                onChange={e => setNewTariffaOraria(e.target.value)}
              />
            </FormField>
          </Box>
            <Box pad="xsmall">
              <FormField required label="Lavori Eseguibili">
                <Select
                  multiple={true}
                  closeOnChange={false}
                  options={ (newCantiere.length!==0 || newZona.length!==0 || newAdl.length!==0)?[... new Set(lavori.sort(function(a, b) { return a.Nome.localeCompare(b.Nome) }).map(lavoro => (lavoro.Nome)))]:[]}
                  value={newLav.Lav}
                  onChange={event =>
                  setNewLav({
                    Lav: event.value
                  })
                }
                />
              </FormField>
            </Box>
            <Box pad="xsmall">
            <FormField required label="Codice Gestionale (ESTERNO)">
              <TextInput
                placeholder="es. "
                value={newCode}
                onChange={e => setNewCode(e.target.value)}
              />
            </FormField>
          </Box>
        </Box>
        <Box direction="row" align="center">
          <Box pad="xsmall">
            <CheckBox
              checked={newAccessori}
              label="Selezionare se il macchinario può montare accessori"
              onChange={(event) => setNewAccessori(event.target.checked)}
            />
          </Box>
        </Box>
        <Box direction="row" align="center">
          <Box pad="xsmall">
            <CheckBox
              checked={newFiltroOlioIdraulico}
              label="Filtro Olio Idraulico"
              onChange={(event) => setNewFiltroOlioIdraulico(event.target.checked)}
            />
          </Box>
          <Box pad="xsmall">
            { newFiltroOlioIdraulico === true ?
              <FormField required label="Cambio Filtro Olio Idraulico (ore)">
                <TextInput
                  placeholder="es. 3000"
                  value={newFiltroOlioIdraulicoChange}
                  onChange={e => setNewFiltroOlioIdraulicoChange(e.target.value)}
                />
              </FormField>
              :
              null
            }
          </Box>
          <Box pad="xsmall">
            <CheckBox
              checked={newFiltroOlio}
              label="Filtro Olio"
              onChange={(event) => setNewFiltroOlio(event.target.checked)}
            />
          </Box>
          <Box pad="xsmall">
            { newFiltroOlio === true ?
              <FormField required label="Cambio Filtro Olio (ore)">
                <TextInput
                  placeholder="es. 3000"
                  value={newFiltroOlioChange}
                  onChange={e => setNewFiltroOlioChange(e.target.value)}
                />
              </FormField>
              :
              null
            }
          </Box>
        </Box>
        <Box direction="row" align="center">
          <Box pad="xsmall">
            <CheckBox
              checked={newLame}
              label="Lame"
              onChange={(event) => setNewLame(event.target.checked)}
            />
          </Box>
          <Box pad="xsmall">
            { newLame === true ?
              <FormField required label="Affilare Lame (ore)">
                <TextInput
                  placeholder="es. 3000"
                  value={newLameChange}
                  onChange={e => setNewLameChange(e.target.value)}
                />
              </FormField>
              :
              null
            }
          </Box>
          <Box pad="xsmall">
            <CheckBox
              checked={newFiltroOlioMotore}
              label="Filtro Olio Motore"
              onChange={(event) => setNewFiltroOlioMotore(event.target.checked)}
            />
          </Box>
          <Box pad="xsmall">
            { newFiltroOlioMotore === true ?
              <FormField required label="Cambio Filtro Olio Motore (ore)">
                <TextInput
                  placeholder="es. 3000"
                  value={newFiltroOlioMotoreChange}
                  onChange={e => setNewFiltroOlioMotoreChange(e.target.value)}
                />
              </FormField>
              :
              null
            }
          </Box>
        </Box>
        <Box direction="row" align="center">
          <Box pad="xsmall">
            <CheckBox
              checked={newOlioMotore}
              label="Olio Motore"
              onChange={(event) => setNewOlioMotore(event.target.checked)}
            />
          </Box>
          <Box pad="xsmall">
            { newOlioMotore === true ?
              <FormField required label="Cambio Olio Motore (ore)">
                <TextInput
                  placeholder="es. 3000"
                  value={newOlioMotoreChange}
                  onChange={e => setNewOlioMotoreChange(e.target.value)}
                />
              </FormField>
              :
              null
            }
          </Box>
          <Box pad="xsmall">
            <CheckBox
              checked={newFiltroGasolio}
              label="Filtro Gasolio"
              onChange={(event) => setNewFiltroGasolio(event.target.checked)}
            />
          </Box>
          <Box pad="xsmall">
            { newFiltroGasolio === true ?
              <FormField required label="Cambio Filtro Gasolio (ore)">
                <TextInput
                  placeholder="es. 3000"
                  value={newFiltroGasolioChange}
                  onChange={e => setNewFiltroGasolioChange(e.target.value)}
                />
              </FormField>
              :
              null
            }
          </Box>
        </Box>
        <Box direction="row" align="center">
          <Box pad="xsmall">
            <CheckBox
              checked={newFiltroBenzina}
              label="Filtro Benzina"
              onChange={(event) => setNewFiltroBenzina(event.target.checked)}
            />
          </Box>
          <Box pad="xsmall">
            { newFiltroBenzina === true ?
              <FormField required label="Cambio Filtro Benzina (ore)">
                <TextInput
                  placeholder="es. 3000"
                  value={newFiltroBenzinaChange}
                  onChange={e => setNewFiltroBenzinaChange(e.target.value)}
                />
              </FormField>
              :
              null
            }
          </Box>
          <Box pad="xsmall">
            <CheckBox
              checked={newFiltroAria}
              label="Filtro Aria"
              onChange={(event) => setNewFiltroAria(event.target.checked)}
            />
          </Box>
          <Box pad="xsmall">
            { newFiltroAria === true ?
              <FormField required label="Cambio Filtro Aria (ore)">
                <TextInput
                  placeholder="es. 3000"
                  value={newFiltroAriaChange}
                  onChange={e => setNewFiltroAriaChange(e.target.value)}
                />
              </FormField>
              :
              null
            }
          </Box>
        </Box>
        <Box direction="row" align="center">
          <Box pad="xsmall">
            <CheckBox
              checked={newOlioIdraulico}
              label="Olio Idraulico"
              onChange={(event) => setNewOlioIdraulico(event.target.checked)}
            />
          </Box>
          <Box pad="xsmall">
            { newOlioIdraulico === true ?
              <FormField required label="Cambio Olio Idraulico (ore)">
                <TextInput
                  placeholder="es. 3000"
                  value={newOlioIdraulicoChange}
                  onChange={e => setNewOlioIdraulicoChange(e.target.value)}
                />
              </FormField>
              :
              null
            }
          </Box>
          <Box pad="xsmall">
            <CheckBox
              checked={newIngrassatura}
              label="Ingrassatore"
              onChange={(event) => setNewIngrassatura(event.target.checked)}
            />
          </Box>
          <Box pad="xsmall">
            { newIngrassatura === true ?
              <FormField required label="Effettuare Ingrassatura (ore)">
                <TextInput
                  placeholder="es. 3000"
                  value={newIngrassaturaChange}
                  onChange={e => setNewIngrassaturaChange(e.target.value)}
                />
              </FormField>
              :
              null
            }
          </Box>
        </Box>
        <Box direction="row" align="center">
          <Box pad="xsmall">
            <CheckBox
              checked={newLappatura}
              label="Lappatura"
              onChange={(event) => setNewLappatura(event.target.checked)}
            />
          </Box>
          <Box pad="xsmall">
            { newLappatura === true ?
              <FormField required label="Effettuare Lappatura (ore)">
                <TextInput
                  placeholder="es. 3000"
                  value={newLappaturaChange}
                  onChange={e => setNewLappaturaChange(e.target.value)}
                />
              </FormField>
              :
              null
            }
          </Box>
          <Box pad="xsmall">
            <CheckBox
              checked={newCandele}
              label="Candele"
              onChange={(event) => setNewCandele(event.target.checked)}
            />
          </Box>
          <Box pad="xsmall">
            { newCandele === true ?
              <FormField required label="Effettuare Manutenzione Candele (ore)">
                <TextInput
                  placeholder="es. 3000"
                  value={newCandeleChange}
                  onChange={e => setNewCandeleChange(e.target.value)}
                />
              </FormField>
              :
              null
            }
          </Box>
        </Box>
        <Box direction="row" align="center">
          <Box pad="xsmall">
            <FormField required label="Cantiere">
              <Select
                multiple={true}
                closeOnChange={false}
                options={[... new Set(cantieri.sort(function(a, b) { return a.Nome.localeCompare(b.Nome) }).map(cantiere => (cantiere.Nome)))]}
                value={newCantiere}
                onChange={event =>
                  setNewCantiere(event.value)
                }
              />
            </FormField>
          </Box>
            <Box pad="xsmall">
              <FormField required label="Zona">
                <Select
                  multiple={true}
                  closeOnChange={false}
                  options={[... new Set(cantieriZona.sort(function(a, b) { return a.Zona.localeCompare(b.Zona) }).map(cantiere => (cantiere.Zona)))]}
                  value={newZona}
                  onChange={event =>
                  setNewZona(event.value)
                }
                />
              </FormField>
            </Box>
            <Box pad="xsmall">
              <FormField required label="Area Di Lavoro">
                <Select
                  multiple={true}
                  closeOnChange={false}
                  options={[... new Set(cantieriZona.sort(function(a, b) { return a.Localita.localeCompare(b.Localita) }).map(cantiere => (cantiere.Localita)))]}
                  value={newAdl}
                  onChange={event =>
                  setNewAdl(event.value)
                }
                />
              </FormField>
            </Box>
        </Box>
      </Form>
        <Box direction="row" gap="medium" alignSelf="end">
          <Button label={(props.macchina===undefined)?"Aggiungi":"Aggiorna"} onClick={(props.macchina===undefined)?onAdd:onUpdate} style={{width:'138px',height:'34px',fontSize:'15px',background:'#006837',color:'white',fontWeight:'bold',textTransform: 'uppercase'}}/>
          <Button type="reset" label={(props.macchina===undefined)?"Reset":"Cancella"} onClick={(props.macchina===undefined)?reset:props.onDelete} style={{width:'138px',height:'34px',fontSize:'15px',background:'#c51d34',color:'white',fontWeight:'bold',textTransform: 'uppercase', borderColor:'#c51d34'}}/>
        </Box>
      </Box>
    </Box>
  )
}