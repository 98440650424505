//import { Box, Grommet, Form, FormField, Text, Table, TableHeader, TableBody, TableRow, TableCell, Clock, TextInput, Button, Calendar, Anchor, CheckBoxGroup, WorldMap, DataChart, DataTable, Meter, Heading, Collapsible, Layer, ResponsiveContext, DropButton } from 'grommet';
import React, { Component, useState, useEffect, useRef } from "react";
import { Map, GoogleApiWrapper, Polyline, Polygon } from 'google-maps-react';
import { Box } from "grommet";
import firebase from '../firebase';

const App = (props) => {

	  const coords = firebase.firestore().collection('Attivita');
	  const [coordinate, setCoords] = useState([]);
	  const coordsPoly = firebase.firestore().collection('Coordinate');
	  const [coordinatePoly, setCoordsPolygon] = useState([]);
	  let home = useRef({lat: 45.1133724, 
	      				 lng: 10.6885045});
	  /*const home = {lat: 45.1133724, 
	      			lng: 10.6885045};*/

		function getCoords() {

		    coords.where(firebase.firestore.FieldPath.documentId(), '==', props.foo).onSnapshot((querySnapshot) => {
		        const items = [];
		        const coord = [];
		        querySnapshot.forEach((doc) => {
		          items.push( { ... doc.data(), id: doc.id } );
		        });		        
		        
		        for (var i = 0; i < items[0].Coordinate.length; i++ )
		        {
		        	items.map(coordinate => (
			     		
			     		coord.push( { lat: coordinate.Coordinate[i].lat, lng: coordinate.Coordinate[i].lng } )
		     		));
		        }
		     	setCoords(coord);
		     	
		    });

		}

		function getPolygon() {
			
			
		    coordsPoly.where('Tracciato', '==', 'si').where('Buca', '==', props.buca).where('Cantiere', '==', props.cantiere).where('ZonaFilter', '==', props.zona).onSnapshot((querySnapshot) => {
		        const items = [];
		        const coord = [];
		        querySnapshot.forEach((doc) => {
		          items.push( { ... doc.data(), id: doc.id } );
		        });		        
				
		        for (var i = 0; i < items.length; i++)
					{	
						items.forEach(buca => {
							
							for (var j = 0; j < buca.Coords.length; j++)
							{
								coord.push({percorso: buca.Coords[j].percorso, colore: buca.Colore})	
							}
						})
							
					}
		     	setCoordsPolygon(coord);
		     	
		    });

		}

		function setHome() {
			home.current = coordinate[0];
			
			return (home.current)
		}

		useEffect(() => {
		 getCoords();
		 getPolygon();
		}, []);


	  return(
	  	<Box flex style={{position: 'absolute', height: '100%', width: '100%', top: '0', left: '0'}}>
		    {/*<Map google={this.props.google}
		    	style={{width: 'auto', top: '0', left: '0', border: '1px solid #000'}}
		        className={'map'}
		        initialCenter={{
		            lat: 45.55075,
		            lng: 9.30122
	          	}}
		        zoom={16}>
		        <Polyline
		          path={triangleCoords}
		          strokeColor="#0000FF"
		          strokeOpacity={0.8}
		          strokeWeight={2} />
		    </Map>*/}
		    { setHome() !== undefined ?
		    <Map google={window.google}
		        style={{width: '100%', height: '100%', position: 'relative'}}
		        className={'map'}
		        initialCenter={setHome()}
		        zoom={18}>
		        <Polyline
		          path={coordinate}
		          strokeColor="#0000FF"
		          strokeOpacity={0.8}
		          strokeWeight={2}
		          fillColor="#0000FF"
		          fillOpacity={0.35} />
		        {
					coordinatePoly.map(coordinata=>(
						
						<Polygon
							key={coordinata.zIndex}
							editable={false}
							paths={coordinata.percorso}
							zIndex={coordinata.zIndex}
							strokeColor={coordinata.colore}
							strokeOpacity={0.8}
							strokeWeight={2}
							fillColor={coordinata.colore}
							fillOpacity={0.35}
							>
						</Polygon>
											
						)
					)
				}
		    </Map>
			: 
			null
			}
	    </Box>
	  )
}
 
export default GoogleApiWrapper({
  apiKey: 'AIzaSyB9_PMSiHZgKMAgg1dtD-DtdrsfszcC-hg'
})(App)