import React, { useState } from "react";
import { Box, DateInput, Text, Heading, Form, FormField, TextInput, Button } from "grommet";
import firebase from '../firebase';

export default function AddArea(props) {

  /**
   * Funzione che aggiunge un anagrafica a database
   */
  const onAdd = () => {
    const db = firebase.firestore();
    let home = [];
    home.push({ lat: Number(newLat), lng: Number(newLng) });
    db.collection('Aree').add({ Nome: newNomeArea, InizioCantiere: newInizioCantiere, FineCantiere: newFineCantiere, Zona: newZona, InizioZona: newInizioZona, FineZona: newFineZona, Localita: newLocalita, InizioAdl: newInizioLocalita, FineAdl: newFineLocalita, Home: home, CodiceGestionale: newCode });
    props.onEscLayer();
  }

  /**
   * Funzione che aggiorna un anagrafica a database
   */
  const onUpdate = () => {
    const db = firebase.firestore();
    let home = [];
    home.push({ lat: Number(newLat), lng: Number(newLng) });
    db.collection('Aree').doc(props.area.id).set({ Nome: newNomeArea, InizioCantiere: newInizioCantiere, FineCantiere: newFineCantiere, Zona: newZona, InizioZona: newInizioZona, FineZona: newFineZona, Localita: newLocalita, InizioAdl: newInizioLocalita, FineAdl: newFineLocalita, Home: home, CodiceGestionale: newCode });
    props.onEscLayer();
  }

  /*CANTIERE*/
  const [newNomeArea, setNewNomeArea] = useState("");
  const [newInizioCantiere, setNewInizioCantiere] = useState("");
  const [newFineCantiere, setNewFineCantiere] = useState("");
  /**/

  /*ZONA*/
  const [newZona, setNewZona] = useState("");
  const [newInizioZona, setNewInizioZona] = useState("");
  const [newFineZona, setNewFineZona] = useState("");
  /**/

  /*AREA DI LAVORO*/
  const [newLocalita, setNewLocalita] = useState("");
  const [newInizioLocalita, setNewInizioLocalita] = useState("");
  const [newFineLocalita, setNewFineLocalita] = useState("");
  /**/

  /*LATITUDINE LONGITUDINE HOME*/
  const [newLat, setNewLat] = useState("");
  const [newLng, setNewLng] = useState("");
  /**/

  /*CODICE GESTIONALE ESTERNO*/
  const [newCode, setNewCode] = useState("");
  /**/

  /**
   * Controlla se apro il componente per modificare un anagrafica e aggiorna i campi di conseguenza
   */
  React.useEffect(()=>{
    if(props.area!==undefined) {
      setNewNomeArea(props.area.Nome);
      setNewInizioCantiere(props.area.InizioCantiere);
      setNewFineCantiere(props.area.FineCantiere);
      setNewZona(props.area.Zona);
      setNewInizioZona(props.area.InizioZona);
      setNewFineZona(props.area.FineZona);
      setNewLocalita(props.area.Localita);
      setNewInizioLocalita(props.area.InizioAdl)
      setNewFineLocalita(props.area.FineAdl);
      setNewLat(props.area.Home[0].lat);
      setNewLng(props.area.Home[0].lng);
      if (props.area.CodiceGestionale === undefined) {
        setNewCode("");
      } else {
        setNewCode(props.area.CodiceGestionale);
      }
    }
  },[])

  /*
    Funzione che resetta i campi dell'anagrafica
  */
  const reset=()=>{
    setNewNomeArea("");
    setNewInizioCantiere("");
    setNewFineCantiere("");
    setNewZona("");
    setNewInizioZona("");
    setNewFineZona("");
    setNewLocalita("");
    setNewInizioLocalita("")
    setNewFineLocalita("");
    setNewLat("");
    setNewLng("");
    setNewCode("");
  }

  return (
    <Box round pad="medium" direction="column" background="white" align="center" overflow="auto">
      <Heading level="2" margin="none" size="small" >
        <Box direction="row" gap="small">
          {props.area===undefined?"Inserisci L'Anagrafica del Cantiere":"Aggiorna anagrafiche cantiere"}
          <Button label="Scarica modello" href={"./modelliCsv/Modello_Aree.csv"} style={{width:'200px',fontSize:'20px',background:'#006837',color:'white',fontWeight:'bold',textTransform: 'uppercase',textAlign:"center"}}></Button>  
        </Box>
      </Heading>
      <Box fill gap="medium" pad={{ vertical: "small" }} align="center">
      <Form>
        <Box direction="row" align="center">
          <Box pad="xsmall">
            <FormField required label="Cantiere">
              <TextInput
                value={newNomeArea}
                placeholder="es. Barlassina"
                onChange={e => setNewNomeArea(e.target.value)}
              />
            </FormField>
          </Box>
          <Box pad="xsmall">
            <FormField required label="Inizio Cantiere">
              <TextInput
               value={newInizioCantiere}
                placeholder="es. 01-01-2021"
                onChange={e => setNewInizioCantiere(e.target.value)}
              />
            </FormField>
          </Box>
          <Box pad="xsmall">
            <FormField required label="Scadenza Cantiere">
              <TextInput
                placeholder="es. 12-12-2021"
                value={newFineCantiere}
                onChange={e => setNewFineCantiere(e.target.value)}
              />
            </FormField>
          </Box>
        </Box>
        <Box direction="row" align="center">
          <Box pad="xsmall">
            <FormField required label="Zona">
              <TextInput
                placeholder="es. Golf"
                value={newZona}
                onChange={e => setNewZona(e.target.value)}
              />
            </FormField>
          </Box>
          <Box pad="xsmall">
            <FormField required label="Inizio Zona">
              <TextInput
                placeholder="es. 01-01-2021"
                value={newInizioZona}
                onChange={e => setNewInizioZona(e.target.value)}
              />
            </FormField>
          </Box>
          <Box pad="xsmall">
            <FormField required label="Scadenza Zona">
              <TextInput
                placeholder="es. 12-12-2021"
                value={newFineZona}
                onChange={e => setNewFineZona(e.target.value)}
              />
            </FormField>
          </Box>
        </Box>
        <Box direction="row" align="center">
          <Box pad="xsmall">
            <FormField required label="Area di Lavoro">
              <TextInput
                placeholder="es. Green"
                value={newLocalita}
                onChange={e => setNewLocalita(e.target.value)}
              />
            </FormField>
          </Box>
          <Box pad="xsmall">
            <FormField required label="Inizio Area di Lavoro">
              <TextInput
                placeholder="es. 01-01-2021"
                value={newInizioLocalita}
                onChange={e => setNewInizioLocalita(e.target.value)}
              />
            </FormField>
          </Box>
          <Box pad="xsmall">
            <FormField required label="Scadenza Area di Lavoro">
              <TextInput
                placeholder="es. 12-12-2021"
                value={newFineLocalita}
                onChange={e => setNewFineLocalita(e.target.value)}
              />
            </FormField>
          </Box>
        </Box>
        <Box direction="row" align="center">
          <Box pad="xsmall">
            <FormField required label="Latitudine Home">
              <TextInput
                placeholder="es. 45.1143043"
                value={newLat}
                onChange={e => setNewLat(e.target.value)}
              />
            </FormField>
          </Box>
          <Box pad="xsmall">
            <FormField required label="Longitudine Home">
              <TextInput
                placeholder="es. 10.6855563"
                value={newLng}
                onChange={e => setNewLng(e.target.value)}
              />
            </FormField>
          </Box>
          <Box pad="xsmall">
            <FormField required label="Codice Gestionale (ESTERNO)">
              <TextInput
                placeholder="es. "
                value={newCode}
                onChange={e => setNewCode(e.target.value)}
              />
            </FormField>
          </Box>
        </Box>
      </Form>
      <Box direction="row" gap="medium" alignSelf="end">
          <Button label={props.area===undefined?"Aggiungi":"Aggiorna"} onClick={props.area===undefined?onAdd:onUpdate} style={{width:'138px',height:'34px',fontSize:'15px',background:'#006837',color:'white',fontWeight:'bold',textTransform: 'uppercase'}}/>
          <Button label={(props.area!==undefined)?"Cancella":"Cancella"} onClick={(props.area!==undefined)?props.onDelete:reset}style={{width:'138px',height:'34px',marginLeft:'5px',fontSize:'15px',background:'#c51d34',color:'white',fontWeight:'bold',textTransform: 'uppercase', borderColor:'#c51d34'}}/>    
      </Box>
      </Box>
    </Box>
  )
}
