import React, { useState, useEffect } from "react";
import { Box, Text, Heading, Form, FormField, Select, TextInput, DateInput, Button } from "grommet";
import firebase, { auth } from '../firebase';


export default function AddDip(props) {
  
   /**
   * Funzione che aggiunge un anagrafica a database
   */
  const onAdd = () => {
    const db = firebase.firestore();
    db.collection('Dipendenti').add({ Nome: newName, Cognome: newCognome, Matricola: newMatricola, Mansione: newMansione, DataNascita: newDataNascita, CodiceFiscale: newCodiceFiscale, email: newEmail, Cellulare: newCellulare, CostoInt: newCostoInt, CostoEst: newCostoEst, Ruolo: newRuolo, Cantiere: newCantiere.Cantiere, Zona: newZona.Zona });
    db.collection('Ruoli').add({ Ruolo: newRuolo, Utente: newEmail });
    props.onEscLayer();
  }
  
  const Roles = ['Admin', 'Admin_Cantiere', 'Admin_Zona', 'Meccanico', 'Operatore'];
  const [newName, setNewName] = useState("");
  const [newCodiceFiscale, setNewCodiceFiscale] = useState("");
  const [newRuolo, setNewRuolo] = useState("");
  const [newCognome, setNewCognome] = useState("");
  const [newMatricola, setNewMatricola] = useState("");
  const [newMansione, setNewMansione] = useState("");
  const [newDataNascita, setNewDataNascita] = useState((new Date()).toISOString().split('T')[0]);
  const [newEmail, setNewEmail] = useState("");
  const [newCellulare, setNewCellulare] = useState("");
  const [newCostoInt, setNewCostoInt] = useState("");
  const [newCostoEst, setNewCostoEst] = useState("");
  /*CHANGE CANTIERE*/
  const [newCantiere, setNewCantiere] = useState([]);
  const [newZona, setNewZona] = useState([]);

  const [cantieri, setCantieri] = useState([]);
  const [cantieriZona, setCantieriZona] = useState([]);
  const cant = firebase.firestore().collection('Aree');

   /**
   * Funzione che ottiene i cantieri presenti a sistema
   */
  function getCantieri() {
      cant.onSnapshot((querySnapshot) => {
        const items = [];
        querySnapshot.forEach((doc) => {
          items.push( { ... doc.data(), id: doc.id } );
        });
      setCantieri(items);        
      });
  }

  /**
   * Funzione che in base al cantiere selezionato rileva le zone
   */
  function getCantieriZona() {
    if (newCantiere.Cantiere !== undefined && newCantiere.Cantiere.length > 0) {
      cant.where('Nome', 'in', newCantiere.Cantiere).onSnapshot((querySnapshot) => {
        const items = [];
        querySnapshot.forEach((doc) => {
          items.push( { ... doc.data(), id: doc.id } );
        });
        setCantieriZona(items);
        
      });
    }
  }


  /**
   * Controlla se apro il componente per modificare un anagrafica e aggiorna i campi di conseguenza
   */
  useEffect(() => {
    getCantieri();
    if(props.dipendente!==undefined){
      setNewName(props.dipendente.Nome);
      setNewCognome(props.dipendente.Cognome);
      setNewMatricola(props.dipendente.Matricola);
      setNewMansione(props.dipendente.Mansione);
      setNewEmail(props.dipendente.email);
      setNewDataNascita(props.dipendente.DataNascita);
      setNewCellulare(props.dipendente.Cellulare);
      setNewCostoInt(props.dipendente.CostoInt);
      setNewCostoEst(props.dipendente.CostoEst);
      setNewRuolo(props.dipendente.Ruolo);
      setNewCantiere({Cantiere: props.dipendente.Cantiere});
      setNewZona({Zona: props.dipendente.Zona});
    }
  }, []);

  /*
    Funzione che resetta i campi dell'anagrafica
  */
  const reset=()=>{
    setNewName("");
    setNewCognome("");
    setNewMatricola("");
    setNewMansione("");
    setNewEmail("");
    setNewDataNascita("");
    setNewCellulare("");
    setNewCostoInt("");
    setNewCostoEst("");
    setNewRuolo("");
    setNewCantiere([]);
    setNewZona([]);
  }

  useEffect(() => {
    getCantieriZona();
    
  }, [newCantiere]);

  /**
   * Funzione che aggiorna un anagrafica con i nuovi dati
   */
  const onUpdate=()=>{
    const db = firebase.firestore();
    db.collection('Dipendenti').doc(props.dipendente.id).set({ Nome: newName, Cognome: newCognome, Matricola: newMatricola, Mansione: newMansione, DataNascita: newDataNascita, CodiceFiscale: newCodiceFiscale, email: newEmail, Cellulare: newCellulare, CostoInt: newCostoInt, CostoEst: newCostoEst, Ruolo: newRuolo, Cantiere: newCantiere.Cantiere, Zona: newZona.Zona });
    var ruoli_query = db.collection('Ruoli').where('Utente', '==', newEmail);
    ruoli_query.get().then(function(querySnapshot) {
      querySnapshot.forEach(function(doc) {
        doc.ref.update({'Ruolo': newRuolo});
      });
    });
    props.onEscLayer();
  }

  return (
    <Box round pad="medium" direction="column" background="white" align="center" overflow="auto">
      <Heading level="2" margin="none" size="small" style={{fontWeight:'bold',textTransform: 'uppercase'}}>
        <Box direction="row" gap="large">
          {props.dipendente===undefined?"Inserisci personale":"Aggiorna personale"}
          <Button label="Scarica modello" href={"./modelliCsv/Modello_Personale.csv"} style={{width:'200px',fontSize:'20px',background:'#006837',color:'white',fontWeight:'bold',textTransform: 'uppercase',textAlign:"center"}}></Button>  
        </Box>
      </Heading>
      <Box fill gap="medium" pad={{ vertical: "small" }} align="center">
        <Form>
        <Box direction="row" align="center">
          <Box pad="xsmall">
            <FormField required label="Nome">
              <TextInput
                placeholder=""
                value={newName}
                onChange={e => setNewName(e.target.value)}
              />
            </FormField>
          </Box>
          <Box pad="xsmall">
            <FormField required label="Cognome">
              <TextInput
                placeholder=""
                value={newCognome}
                onChange={e => setNewCognome(e.target.value)}
              />
            </FormField>
          </Box>
          <Box pad="xsmall">
            <FormField required label="Matricola">
              <TextInput
                placeholder="es. 123456"
                value={newMatricola}
                onChange={e => setNewMatricola(e.target.value)}
              />
            </FormField>
          </Box>
        </Box>
        <Box direction="row" align="center">
          <Box pad="xsmall">
            <FormField required label="Mansione">
              <TextInput
                placeholder="es. Giardiniere"
                value={newMansione}
                onChange={e => setNewMansione(e.target.value)}
              />
            </FormField>
          </Box>
          <Box pad="xsmall">
            <FormField required label="Data di nascita">
              <TextInput
                placeholder="es. 12-12-1990"
                value={newDataNascita}
                onChange={e => setNewDataNascita(e.target.value)}
              />
            </FormField>
          </Box>
          <Box pad="xsmall">
            <FormField required label="Codice Fiscale">
              <TextInput
                placeholder="es. RSSMRA30A01H501I"
                value={newCodiceFiscale}
                onChange={e => setNewCodiceFiscale(e.target.value)}
              />
            </FormField>
          </Box>
        </Box>
        <Box direction="row" align="center">
          <Box pad="xsmall">
            <FormField required label="Email">
              <TextInput
                placeholder="es. prova@gmail.com"
                value={newEmail}
                onChange={e => setNewEmail(e.target.value)}
              />
            </FormField>
          </Box>
          <Box pad="xsmall">
            <FormField required label="Cellulare">
              <TextInput
                placeholder="es. 3332277890"
                value={newCellulare}
                onChange={e => setNewCellulare(e.target.value)}
              />
            </FormField>
          </Box>
          <Box pad="xsmall">
            <FormField required label="Costo Interno (€/Ora)">
              <TextInput
                placeholder="es. 10,5"
                value={newCostoInt}
                onChange={e => setNewCostoInt(e.target.value)}
              />
            </FormField>
          </Box>
        </Box>
        <Box direction="row" align="center">
          <Box pad="xsmall">
            <FormField required label="Costo Esterno (€/Ora)">
              <TextInput
                placeholder="es. 15"
                value={newCostoEst}
                onChange={e => setNewCostoEst(e.target.value)}
              />
            </FormField>
          </Box>
          <Box pad="xsmall">
            <FormField required label="Ruolo">
              <Select
                options={Roles}
                value={newRuolo}
                onChange={({ option }) => setNewRuolo(option)}
              />
            </FormField>
          </Box>
        </Box>
        <Box direction="row" align="center">
          <Box pad="xsmall">
            <FormField required label="Cantiere">
              <Select
                multiple={true}
                closeOnChange={false}
                options={[... new Set(cantieri.sort(function(a, b) { return a.Nome.localeCompare(b.Nome) }).map(cantiere => (cantiere.Nome)))]}
                value={newCantiere.Cantiere}
                onChange={event =>
                  setNewCantiere({
                    Cantiere: event.value
                  })
                }
              />
            </FormField>
          </Box>
            <Box pad="xsmall">
              <FormField required label="Zona">
                <Select
                  multiple={true}
                  closeOnChange={false}
                  options={[... new Set(cantieriZona.sort(function(a, b) { return a.Zona.localeCompare(b.Zona) }).map(cantiere => (cantiere.Zona)))]}
                  value={newZona.Zona}
                  onChange={event =>
                  setNewZona({
                    Zona: event.value
                  })
                }
                />
              </FormField>
            </Box>
        </Box>
      </Form>
      <Box direction="row" gap="medium" alignSelf="end">
          <Button label={props.dipendente===undefined?"Aggiungi":"Aggiorna"} onClick={props.dipendente===undefined?onAdd:onUpdate} style={{width:'138px',height:'34px',fontSize:'15px',background:'#006837',color:'white',fontWeight:'bold',textTransform: 'uppercase'}}/>
          <Button label={(props.dipendente!==undefined)?"Cancella":"Cancella"} onClick={(props.dipendente!==undefined)?props.onDelete:reset}style={{width:'138px',height:'34px',marginLeft:'5px',fontSize:'15px',background:'#c51d34',color:'white',fontWeight:'bold',textTransform: 'uppercase', borderColor:'#c51d34'}}/>    
      </Box>
      </Box>
     
    </Box>
  )
}