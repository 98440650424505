import React, { useState, useEffect } from "react";
import firebase from '../firebase';
import { Box, Button, Text, Notification } from "grommet";
import { FormClose, Windows } from "grommet-icons";
import { useHistory } from "react-router-dom";

/**
 * Componente che implementa la lista di notificheà
 * Verrà poi inserita in Notifiche.js
 */
const NotificationApp = (props) => {

  const notify = firebase.firestore().collection('Notifiche');
  const [notifiche, setNotifiche] = useState([]);

  const history = useHistory();

  /**
   * Funzione che rileva tutte le notifiche a sistema dell'utente loggato
   */
  function getNotifiche() {
      notify.where("email","==",userLogged.email).onSnapshot((querySnapshot) => {
        const items = [];
        querySnapshot.forEach((doc) => {
          items.push( { ... doc.data(), id: doc.id } );
        });
       setNotifiche(items);
      });   
  }

  /**
   * Funzione che cancella una notifica
   */
  function handleNotification(notificaid) {
    const db = firebase.firestore();
    db.collection('Notifiche').doc(notificaid).delete();
  }

  function clickNotify(){
    sessionStorage.setItem("clickNotify","true");
    window.location.reload();
  }

  useEffect(() => {
    getNotifiche();
  }, []);

  const userLogged = JSON.parse(sessionStorage.getItem("firebase:authUser:AIzaSyACdCazXkw1sBqvo1W7d066eLqk7Crzr0A:[DEFAULT]"));

  return (
    <Box width="90%" align="center">
    {
    notifiche.map(notifica =>
    <Box
      width="70%"
      round
      pad="small"
      margin="1%"
      direction="column"
      background="#FAFAFABF"
      style={{display: 'inline-table',borderStyle: 'solid',borderColor:'#707070',borderWidth:"1px"}}
    >
      <Box  direction="row" justify="between" pad={{ vertical: "small" }} style={{display: 'table!important'}}>
        <Box onClick={clickNotify} direction="column" gap="small" pad={{ vertical: "small" }}>
          {
            notifica.data!==undefined?
            <Text
            color="#000000"
            size="large"
            weight="bold"
            margin={{ horizontal: "small" }}
          >
            {notifica.data+" - "+notifica.title}  
          </Text>
          :
          <Text
          color="#000000"
          size="large"
          weight="bold"
          margin={{ horizontal: "small" }}
        >
          {notifica.title}  
        </Text>
          }   
          <Text size="medium" color="#000000" margin={{ horizontal: "small" }}>
            {notifica.body}
          </Text>
        </Box>
        <Box>
          <Button plain icon={<FormClose color="#000000" />} onClick={() =>handleNotification(notifica)} />
        </Box>
      </Box>
    </Box>
    )
    }
  </Box>
  );
}

export default NotificationApp;