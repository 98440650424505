import React, { useState, useEffect } from "react";
import { Box, Menu, Grid, Grommet, Text, Card,Image, CardHeader, CardBody, Select, CardFooter, TableCell, Clock, TextInput, Button, Calendar, Anchor, CheckBoxGroup, WorldMap, DataChart, DataTable, Meter, Heading, Collapsible, Layer, ResponsiveContext, DropButton, Notification as NotificationApp } from 'grommet';
import { FormClose, Notification, Home, CircleInformation, Add, Favorite, ShareOption, LinkPrevious, UserWorker, MapLocation, Analytics, Task, Configure, Windows } from "grommet-icons";
import firebase from '../firebase';
import Map from "./Map";
import  { Redirect,useHistory } from 'react-router-dom';
import SideBar from "./SideBar";
import SideBarMobile from "./SideBarMobile";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import moment from "moment";

const theme = {
  global: {
    colors: {
      brand: '#006837',
      'accent-1': 'white',
      'focus': '#006837',
      "my-text-color": "",
      background: '#0350281A',
    },
    font: {
      family: '',
      size: '18px',
      height: '20px',
    },
  },
};

const App = (props) => {
  const att = firebase.firestore().collection('Attivita');
  const [attivita, setAttivita] = useState([]);
  const [Dipendente,setDipendente]=useState("");
  const dip = firebase.firestore().collection('Dipendenti');

  const role=firebase.firestore().collection('Ruoli');
  const [ruoli, setRuoli] = useState([]);

  const [showSideBarMob,setShowSideBarMob]=useState(false);
  const [showLayer,setShowLayer]=useState(false);

  const [width, setWidth]   = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const [landScapeError,setLandScapeError]=React.useState(false);
  
    /**
     * Funzione che permette di sapere le dimensioni aggiornate dello schermo
     */
  const updateDimensions = () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
  }
  useEffect(() => {
      window.addEventListener("resize", updateDimensions);
      return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  /**
   * Controllo in base a width e height l'orientamento dello schermo.
   * Per essere respondive blocco la web app in LandScape.
   */
  React.useEffect(()=>{
    if(width<1100){
      if(width<height){
        setLandScapeError(true);
      }else{
        setLandScapeError(false);
      }    
    }
  },[width,height])

 
  /*CHANGE PER IMPLEMENTAZIONE RUOLI*/
  function getRuoli() {
    role.where('Utente', '==', userLogged.email).onSnapshot((querySnapshot) => {
      const items = [];
      querySnapshot.forEach((doc) => {
        items.push( { ... doc.data(), id: doc.id } );
      });
      setRuoli(items);     
    });
 }

 /**
  * Funzione che ottiene i dati dell'attività passata come props (tramite id) e dala matricola dell'attività
  * che trova a sistema trova il nome e il cognome del dipendente che l'ha svolta
  */
  function getAttivita() {
    att.where(firebase.firestore.FieldPath.documentId(), '==', props.match.params.id).onSnapshot((querySnapshot) => {
      const items = [];
      querySnapshot.forEach((doc) => {
        items.push( { ... doc.data(), id: doc.id } );
      });
      dip.where("Matricola","==",items[0].Matricola).onSnapshot((querySnapshot)=>{
        var userLoggedApp;
        querySnapshot.forEach((doc)=>{
          userLoggedApp=doc.data().Nome+" "+doc.data().Cognome;
        })
        setDipendente(userLoggedApp);
      })
      setAttivita(items);
    });
  }

  useEffect(() => {
    getAttivita();
    getRuoli();
  }, []);

  if (sessionStorage.getItem("firebase:authUser:AIzaSyACdCazXkw1sBqvo1W7d066eLqk7Crzr0A:[DEFAULT]") == null) {
    return <Redirect to='/'  />
  }

  /**
   * Funzione che ritorna la data dell'attività in formato dd-MM-yyyy
   */
  function dataTrasformation(data){
    var dataArray= data.split('-');
    var newData=dataArray[2]+'-'+dataArray[1]+'-'+dataArray[0];
    return newData;    
  }

  /**
   * Funzione che trasforma la durata dell'attività nel formato hh:mm
   */
  function test(durata){
    console.log(durata);
    if(durata>59){
      var oraT=durata/60;
      var ora=parseInt(oraT);
      var minuti=(oraT-ora)*60;
      var durataF=ora+'h '+Math.round(minuti)+'m';
    } else{
      var durataF=Math.round(durata)+'m'
    }
    return durataF;
  }

  const userLogged = JSON.parse(sessionStorage.getItem("firebase:authUser:AIzaSyACdCazXkw1sBqvo1W7d066eLqk7Crzr0A:[DEFAULT]"));

  /**
   * Implementa il layer che mostra le note dell'admin e dell'operatore inerente all'attività analizzata
   */
  const LayerChat=(props)=>{
    return(
    <Layer onEsc={()=>setShowLayer(false)} onClickOutside={()=>setShowLayer(false)}>
       <Box width="300px" align="center" style={{borderStyle:"solid",borderColor:"grey",borderWidth:"0.3px"}} margin="small" direction="column" gap="small" pad="small" round="medium">
        {props.attivita.Messaggio!==undefined && props.attivita.Messaggio!==""?
          <Box flex round="medium" background="grey" alignSelf="start" pad="small" textColor="white" >
            <Text weight="bold"  color="white"> ADMIN</Text>
            <Text size="small" color="white"> {props.attivita.Messaggio} </Text>
          </Box>
          :
          null
          }
          {props.attivita.NotaOperatore!==undefined && props.attivita.NotaOperatore!==""?
          <Box flex round="medium"  background="green" alignSelf="end" pad="small">
            <Text weight="bold"  color="white"> OPERATORE </Text>
            <Text size="small" color="white"> {props.attivita.NotaOperatore}</Text>
          </Box>
          :
          null
        }
      </Box>
      <Button margin='medium' alignSelf="center" label="Chiudi" onClick={() => setShowLayer(false)} style={{ borderColor:"#c51d34",padding:'10px',fontSize:'28px',/*marginLeft: 'auto',*/background:'#c51d34',color:'white',fontWeight:'bold',textTransform: 'uppercase'}}></Button>
    </Layer>
    );
  } 

  return (
    <Grommet theme={theme} themeMode="dark" className="font-link">
      <ResponsiveContext.Consumer>
        {size => (
       !landScapeError?
        <Box fill>
           <Box height="97vh" direction={width>=1100?"row":"column"}>
              {
                width>=1100?
                <SideBar pathLogo={props.match===undefined?"./images/Logo1.png":props.match.params.id!==undefined?"../images/Logo1.png":""} page="Attività" ruolo={(ruoli[0]!==undefined)?ruoli[0].Ruolo:null}/>      
              :
              <AppBar position="static" style={{ background: '#006837'}}>
              <Toolbar>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                  Taskonnect
                </Typography>
                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  onClick={() => {
                   if(!showSideBarMob){
                    setShowSideBarMob(true);
                   }else{
                     setShowSideBarMob(false);
                   }      
                  }}
                >
                  <MenuIcon />
                </IconButton>
              </Toolbar>
            </AppBar>
            }
                {showSideBarMob?
                  <SideBarMobile page="Attività" ruolo={(ruoli[0]!==undefined)?ruoli[0].Ruolo:null}/>
                :
              <Box direction='row' flex overflow={{ horizontal: 'hidden' }}>
                  <Box flex pad="small" >
                  <Button style={{width:'150px',fontSize:'15px',background:"#006837",color:'white',fontWeight:'bold',textTransform: 'uppercase',borderStyle: 'solid',borderColor:'#006837',borderWidth:"3px", borderRadius:"10px"}}  onClick={() =>window.location.href = window.location.origin + "/home"}>
                    <Box pad="small" direction="row" align="center" gap="medium">
                        <LinkPrevious color="white"/>
                        <Text size="small" color="white">Indietro</Text>
                    </Box>
                  </Button>
                    <Box direction='row' flex overflow={{ horizontal: 'hidden' }}>
                    {attivita.map(attivita => (
                        <Card margin="small" background="light-1" width="xlarge" height="large" style={{marginLeft:'auto',marginRight:'auto'}}>
                          <CardHeader justify="center">
                          <Box >
                            <Heading level="3" textAlign="center">{attivita.Lavoro} - {attivita.Cantiere} - {attivita.ZonaFilter} - {attivita.Zona}</Heading>
                          </Box>
                          </CardHeader>
                          <CardBody>
                          <Grid 
                           gap="small"
                           columns={['auto','auto']}
                           areas={[
                             [ 'colonna1', 'colonna2'],
                           ]}
                         >
                          <Box align="center" gridArea='colonna2' >
                            <Text style={{fontWeight:'bold'}}>Data Lavoro: {dataTrasformation(attivita.Data)}</Text>
                            <Text style={{fontWeight:'bold'}}>Percentuale Completamento: {attivita.percentuale !== undefined ? parseInt(attivita.percentuale) : 0 }% </Text>
                            <Text style={{fontWeight:'bold'}}>Ore Lavorate: {attivita.durataLavoro!==undefined ? attivita.Approvata == 'si' || attivita.Approvata == 'manuale' ? test(Number(attivita.durataLavoro)) : 'Attività non terminata' : "0"}</Text>
                            {(attivita.Messaggio!=="" && attivita.Messaggio!==undefined )||( attivita.NotaOperatore!==undefined && attivita.NotaOperatore!=="")?
                            <Button margin="small" label="Mostra note" onClick={() => setShowLayer(true)} style={{width:'170px',height:'34px',fontSize:'15px',background:'#006837',color:'white',fontWeight:'bold',textTransform: 'uppercase'}}></Button>
                            :
                            null  
                           }
                          </Box>
                          {showLayer?
                            <LayerChat attivita={attivita}/>
                            :
                            null
                          }
                           
                          <Box style={{paddingLeft:'50px',lineHeight: '40px' }} gridArea='colonna1'> 
                            Dipendente:<Text style={{fontWeight:'bold',textTransform:'uppercase'}}>{Dipendente}</Text>
                            {attivita.Accessorio == true ?
                            <Text> <br></br> Macchinario+Accessorio: <br></br> <Text style={{fontWeight:'bold', textTransform:'uppercase'}}> {attivita.Macchina}+{attivita.AccessorioUtilizzato} </Text> </Text>
                            :
                            <Text> <br></br> Macchinario: <br></br> <Text style={{fontWeight:'bold', textTransform:'uppercase'}}> {attivita.Macchina} </Text> </Text>
                            }
                            Durata: <Text style={{fontWeight:'bold'}}>{attivita.Approvata == 'si' || attivita.Approvata == 'manuale' ? test(Number(parseFloat(((moment(Date.parse(attivita.End)).set('second',0) - moment(Date.parse(attivita.Start)).set('second',0))/1000/60), 10).toFixed(2))) : 'Attività non terminata'}</Text>
                            <br></br>
                            <br></br>
                            <br></br>
                          </Box>
                        </Grid> 
                          </CardBody>
                          <CardFooter height="medium" pad={{horizontal: "small"}} background="light-2" style={{position: 'relative'}}> 
                            {attivita.Approvata == 'si' ? <Map foo={props.match.params.id} buca={attivita.Zona} cantiere={attivita.Cantiere} zona={attivita.ZonaFilter}/> : null}
                          </CardFooter>
                        </Card>
                    ))}
                    </Box>
              </Box>
              </Box>
              }
            </Box>
        </Box>
        :
        <Box fill gap="small">
        <AppBar position="static" style={{ background: '#006837'}}>
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Taskonnect
          </Typography>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={() => {
             if(!showSideBarMob){
              setShowSideBarMob(true);
             }else{
               setShowSideBarMob(false);
             }      
            }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
        <Box width="100%" height="100%" align="center" alignSelf="center" background="white">
        <Text margin="small" color="#04671E" size="large" weight="bold">GIRA LO SCHERMO PER UTILIZZARE TASKONNECT</Text>
       </Box>
      </Box>
        
        )}
      </ResponsiveContext.Consumer>
    </Grommet>
  );
}

export default App;