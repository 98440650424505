import React, { useState, useEffect } from "react";
import { Box, DateInput, Text, Heading, Form, FormField, Select, TextInput, Button } from "grommet";
import firebase from '../firebase';


export default function AddAccessorio(props) {
  
  /**
   * Funzione che aggiunge un anagrafica a database
   */
  const onAdd = () => {
    const db = firebase.firestore();
    db.collection('Accessori').add({ Nome: newNomeAccessorio, Cantiere: newCantiere.Cantiere, Zona: newZona.Zona, Matricola: matricola });
    props.onEscLayer();
  }
  
  const [newNomeAccessorio, setNewNomeAccessorio] = useState("");

  /*CHANGE CANTIERE*/
  const [newCantiere, setNewCantiere] = useState([]);
  const [newZona, setNewZona] = useState([]);

  const [cantieri, setCantieri] = useState([]);
  const [cantieriZona, setCantieriZona] = useState([]);
  const [matricola,setMatricola]=React.useState();
  const cant = firebase.firestore().collection('Aree');

  /**
   * Funzione che ottiene i cantieri presenti a sistema
   */
  function getCantieri() {
      cant.onSnapshot((querySnapshot) => {
        const items = [];
        querySnapshot.forEach((doc) => {
          items.push( { ... doc.data(), id: doc.id } );
        });
        setCantieri(items);
        
      });
  }

  /**
   * Funzione che in base al cantiere selezionato rileva le zone
   */
  function getCantieriZona() {
    if (newCantiere.Cantiere !== undefined && newCantiere.Cantiere.length > 0) {
      cant.where('Nome', 'in', newCantiere.Cantiere).onSnapshot((querySnapshot) => {
        const items = [];
        querySnapshot.forEach((doc) => {
          items.push( { ... doc.data(), id: doc.id } );
        });
        setCantieriZona(items);
        ;
      });
    }
  }

  /**
   * Controlla se apro il componente per modificare un anagrafica e aggiorna i campi di conseguenza
   */
  useEffect(() => {
    getCantieri();
    if(props.accessorio!==undefined){
      setNewNomeAccessorio(props.accessorio.Nome);
      setNewCantiere({Cantiere: props.accessorio.Cantiere});
      setNewZona({Zona: props.accessorio.Zona});
      setMatricola(props.accessorio.Matricola);
    }
  }, []);

  useEffect(() => {
    getCantieriZona();
  }, [newCantiere]);

  /**
   * Funzione che aggiorna un anagrafica con i nuovi dati
   */
  const onUpdate=()=>{
    const db = firebase.firestore();
    db.collection('Accessori').doc(props.accessorio.id).set({ Nome: newNomeAccessorio, Cantiere: newCantiere.Cantiere, Zona: newZona.Zona, Matricola:matricola });
    props.onEscLayer();
  }

  /*
    Funzione che resetta i campi dell'anagrafica
  */
  const reset=()=>{
    setNewNomeAccessorio("");
    setMatricola("");
  }

  return (
    <Box round pad="medium" direction="column" background="white" align="center" overflow="auto">
      <Heading level="2" margin="none" size="small">
        <Box direction="row" gap="large">
          { props.accessorio == undefined ? "Inserisci L'Accessorio" : "Aggiorna L'Accessorio" }
          <Button label="Scarica modello" href={"./modelliCsv/Modello_Accessorio.csv"} style={{width:'200px',fontSize:'20px',background:'#006837',color:'white',fontWeight:'bold',textTransform: 'uppercase',textAlign:"center"}}></Button>  
        </Box>
      </Heading>
      <Box fill gap="medium" pad={{ vertical: "small" }} align="center">
      <Form>
        <Box direction="row" align="center">
          <Box pad="xsmall">
            <FormField required label="Nome Accessorio">
              <TextInput
                placeholder="es. Spargi Concime"
                value={newNomeAccessorio}
                onChange={e => setNewNomeAccessorio(e.target.value)}
              />
            </FormField>
          </Box>
          <Box pad="xsmall">
            <FormField required label="Id Accessorio">
              <TextInput
                placeholder="es. 123456"
                value={matricola}
                onChange={e => setMatricola(e.target.value)}
              />
            </FormField>
        </Box>
        </Box>

        <Box direction="row" align="center">
          <Box pad="xsmall">
            <FormField required label="Cantiere">
              <Select
                multiple={true}
                closeOnChange={false}
                options={[... new Set(cantieri.sort(function(a, b) { return a.Nome.localeCompare(b.Nome) }).map(cantiere => (cantiere.Nome)))]}
                value={newCantiere.Cantiere}
                onChange={event =>
                  setNewCantiere({
                    Cantiere: event.value
                  })
                }
              />
            </FormField>
          </Box>
            <Box pad="xsmall">
              <FormField required label="Zona">
                <Select
                  multiple={true}
                  closeOnChange={false}
                  options={[... new Set(cantieriZona.sort(function(a, b) { return a.Zona.localeCompare(b.Zona) }).map(cantiere => (cantiere.Zona)))]}
                  value={newZona.Zona}
                  onChange={event =>
                  setNewZona({
                    Zona: event.value
                  })
                }
                />
              </FormField>
            </Box>
        </Box>
      </Form>   
      <Box direction="row" gap="medium" alignSelf="end">
          <Button margin="small" label={(props.accessorio==undefined) ? "Aggiungi" : "Aggiorna"} onClick={(props.accessorio==undefined) ? onAdd : onUpdate} style={{width:'138px',height:'34',fontSize:'15px',background:'#006837',color:'white',fontWeight:'bold',textTransform: 'uppercase'}} />
          <Button margin="small" type="reset" label={(props.accessorio==undefined)?"Reset":"Cancella"}  onClick={(props.accessorio==undefined)?reset:props.onDelete} style={{marginLeft:'5px', width:'138px',height:'34px',fontSize:'15px',background:'#c51d34',color:'white',fontWeight:'bold',textTransform: 'uppercase', borderColor:'#c51d34'}}/>
          <Button margin="small" type="reset" onClick={props.onEscLayer} label="Chiudi"style={{marginLeft:'5px', width:'138px',height:'34px',fontSize:'15px',background:'#c51d34',color:'white',fontWeight:'bold',textTransform: 'uppercase', borderColor:'#c51d34'}}/>
        </Box>
      </Box>
    </Box>
  )
}