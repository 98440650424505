import React, { useState, useEffect } from "react";
import { Box, DateInput, Text, Heading, Form, FormField, TextInput, Button, Select, CheckBox } from "grommet";
import firebase from '../firebase';
import moment from 'moment';
import ReactExport from "react-export-excel";
import {LinkBottom} from "grommet-icons";

export default function AddLav(props) {
 
   /**
   * Funzione che aggiunge un anagrafica a database
   */
  const onAdd = () => {
    const db = firebase.firestore();
    db.collection('Errori').add({ codiceDTC: codiceDTC, descrizioneDTC: descrizioneDTC, unitaComando: unitaComando, Modello: modello });
    props.onEscLayer();
  }
  
  const [codiceDTC, setCodiceDTC] = useState("");
  const [descrizioneDTC, setDescrizioneDTC] = useState("");
  const [unitaComando, setUnitaComando] = useState("");
  const [modello, setModello] = useState("");

  /**
   * Controlla se apro il componente per modificare un anagrafica e aggiorna i campi di conseguenza
   */
  useEffect(() => {
    if(props.errore!==undefined){
      setCodiceDTC(props.errore.codiceDTC);
      setDescrizioneDTC(props.errore.descrizioneDTC);
      setUnitaComando(props.errore.unitaComando);
      setModello(props.errore.Modello);
    }
  }, []);

  /*
    Funzione che resetta i campi dell'anagrafica
  */
  const reset=()=>{
    setCodiceDTC("");
    setDescrizioneDTC("");
    setUnitaComando("");
    setModello("");
  }

  /**
   * Funzione che aggiorna un anagrafica con i nuovi dati
   */
  const onUpdate=()=>{
    const db = firebase.firestore();
    db.collection('Errori').doc(props.errore.id).set({
      codiceDTC: codiceDTC, 
      descrizioneDTC: descrizioneDTC, 
      unitaComando: unitaComando, 
      Modello: modello
    });
    props.onEscLayer();
  }

  return (
    <Box round pad="medium" direction="column" background="white" overflow="auto">
      <Heading level="2" margin="none" size="small">
      <Box direction="row" gap="large">
        {props.macchina===undefined?"Inserisci errore":"Aggiorna errore"}
        <Button label="Scarica modello" href={"./modelliCsv/Modello_Errori.csv"} style={{width:'200px',fontSize:'20px',background:'#006837',color:'white',fontWeight:'bold',textTransform: 'uppercase',textAlign:"center"}}></Button>  
      </Box>
      </Heading>
      <Box fill gap="medium" pad={{ vertical: "small" }}>
        <Form>
          <Box direction="row" align="center">
            <FormField required label="Codice DTC">
              <TextInput
                placeholder="es. 523652.31"
                value={codiceDTC}
                onChange={e => setCodiceDTC(e.target.value)}
              />
            </FormField>
            <FormField required label="Descrizione DTC">
              <TextInput
                placeholder="es. Rilevato software non corretto"
                value={descrizioneDTC}
                onChange={e => setDescrizioneDTC(e.target.value)}
              />
            </FormField>
          </Box>
          <Box direction="row" align="center">
            <FormField required label="Unita Comando">
              <TextInput
                placeholder="es. MCU"
                value={unitaComando}
                onChange={e => setUnitaComando(e.target.value)}
              />
            </FormField>
            <FormField required label="Modello Macchinario">
              <TextInput
                placeholder="es. JD 2750 E-CUT"
                value={modello}
                onChange={e => setModello(e.target.value)}
              />
            </FormField>
          </Box>
        </Form>
        <Box direction="row" gap="medium" alignSelf="end">
            <Button label={(props.errore===undefined)?"Aggiungi":"Aggiorna"} onClick={(props.errore===undefined)?onAdd:onUpdate} style={{width:'138px',height:'34px',fontSize:'15px',background:'#006837',color:'white',fontWeight:'bold',textTransform: 'uppercase'}}/>
            <Button type="reset" label={(props.errore===undefined)?"Reset":"Cancella"} onClick={(props.errore===undefined)?reset:props.onDelete} style={{marginLeft:'5px',width:'138px',height:'34px',fontSize:'15px',background:'#c51d34',color:'white',fontWeight:'bold',textTransform: 'uppercase', borderColor:'#c51d34'}}/>         
          </Box>
      </Box>
    </Box>
  )
}