import React, { useState, useEffect } from "react";
import { Box, DateInput, Grommet, Button, TextInput, Table, TableHeader, TableBody, TableRow, TableCell ,Layer} from 'grommet';
import { Edit, Checkmark, FormClose, Trash } from "grommet-icons";
import firebase from '../firebase';
import AddAccessorio from "./AddAccessorio";

export default function AnagraficheAccessorio({ accessorio }) {

const [modify, setModify] = useState(false);

  /**
   * Funzione che cancella un anagrafica
   */
  const onDelete = () => {
	var result = window.confirm("Sei sicuro di voler eliminare l'accessorio?");
	if(result){
		const db = firebase.firestore();
    db.collection('Accessori').doc(accessorio.id).delete();
    setModify(false);
	}
  }

  /**
   * Funzione che chiude il layer di modifica anagrafica
   */
  const onEscLayer=()=>{
	  setModify(false);
  }


return (
<>
	<TableRow id={accessorio.id}>
		<TableCell scope="row">
				<>
				<strong>{accessorio.Nome}</strong>
				</>
		</TableCell>
		<TableCell scope="row">
				<>
				<strong>{accessorio.Matricola}</strong>
				</>
			
		</TableCell>
		<TableCell>
			{ modify ?
				<>
				<Layer full={window.screen.width<1100?true:false} onClickOutside={onEscLayer}>
					<Box  height="100%"round="medium" pad="30px" background="#E5EDE9">
						<AddAccessorio accessorio={accessorio} onEscLayer={onEscLayer} onDelete={onDelete}/>
					</Box>
				</Layer>
				</>
				:
				<>
				<Button icon=<Edit /> onClick={() => setModify(true)} label="" />
				</>
			}
		</TableCell>
	</TableRow>
</>
)

}