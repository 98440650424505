import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { Box, Menu, Grid, Grommet,Image, Text, Card, CardHeader, CardBody, Select, CardFooter, Table, TableHeader, TableBody, TableRow, TableCell, Clock, TextInput, Button, Calendar, Anchor, CheckBox, WorldMap, DataChart, DataTable, Meter, Heading, Collapsible, Layer, ResponsiveContext, DropButton, Tabs, Tab, DateInput, Notification as NotificationApp } from 'grommet';
import { Task, FormClose, UserWorker, MapLocation, Analytics, Notification, Home, Filter, LinkBottom, CircleInformation, Add, Favorite, ShareOption, LinkPrevious, FormDown, FormUp, Table as TableIcon, Configure, System ,Download} from "grommet-icons";
import Notifications from "./Notifications";
import AddAtt from "./AddAtt";
import AnagraficheAtt from "./AnagraficheAtt";
import firebase from '../firebase';
//import Map from "./Map";
import  { Redirect, useHistory } from 'react-router-dom';
import { Calendar as RBCalendar, Views, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import "react-big-calendar/lib/css/react-big-calendar.css";
import {Overlay} from 'react-bootstrap';
import {OverlayTrigger} from 'react-bootstrap';
import {Popover} from 'react-bootstrap';
import {Modal} from 'react-bootstrap';
import ReactExport from "react-export-excel";
import { MapOutlined } from "@material-ui/icons";
import { CSVLink, CSVDownload } from "react-csv";
import { CircularProgress } from '@mui/material';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

require ('moment/locale/it.js')

const localizer = momentLocalizer(moment);

const messages = {
  allDay: 'Giorno Intero',
  previous: 'Precedente',
  next: 'Prossimo',
  today: 'Oggi',
  month: 'Mese',
  week: 'Settimana',
  day: 'Giorno',
  work_week: 'Settimana Lavorativa',
  agenda: 'Agenda',
  date: 'Data',
  time: 'Orario',
  event: 'Evento',
  showMore: total => `+ ${total} Altri Eventi`
}

const theme = {
  global: {
    colors: {
      brand: '#006837',
      'accent-1': 'white',
      'focus': 'rgba(255, 0, 0, 0)',
      "my-text-color": "",
      background: '#0350281A',
      border:'#006837'
    },
    font: {
      family: '',
      size: '18px',
      height: '20px',
    },
  },
  table: {
    header:{
      extend:{
        position:'sticky',
      },
    },
  },
  tabs: {
    color:'accent-1',
    header: {
      background:'none',
      color: 'brand',
      extend: {
        fontWeight:'bold',
        marginTop:'-54px'
      },
    },
  },
  tab: {
    extend:{
      textTransform:'uppercase'
    },
    color:'dark',
    disabled:{
      color:'dark',
    },
    hover:{
      color:'brand'
    },
    active:{
      color:'brand',
    },
    border: {
      active:{
        color:'brand',
      },
      hover:{
        color:'brand'
      },
      side: 'bottom',
      color: 'dark',
      disabled:{
        color:'accent-1',
      },
    },
  },
};

const App = () => {

  const history = useHistory();
  const getCurDate = new Date();

  const getStartDate = new Date();

  const getStarWeek = new Date();
  const first = getStarWeek.getUTCDate() - getStarWeek.getDay();
  const last = first + 7;

  const getStarWeeks = new Date();
  const firsts = getStarWeeks.getUTCDate() - getStarWeeks.getDay();
  const lasts = firsts + 14;

  const att = firebase.firestore().collection('Attivita');
  const dip = firebase.firestore().collection('Dipendenti');
  var db = firebase.firestore();
  const [attivita, setAttivita] = useState([]);
  /*const [dropdownFiltri, setDropdownFiltri] = useState('Questa Settimana');*/

  let [dropdownFiltriDip, setDropdownFiltriDip] = useState('');

  /*CHANGE PER IMPLEMENTAZIONE RUOLI*/
  const role=firebase.firestore().collection('Ruoli');
  const [ruoli, setRuoli] = useState([]);
  /*END CHANGE IMPLEMENTAZIONE RUOLI*/

  const mac = firebase.firestore().collection('Macchinari');

  /*CHANGE PER IMPLEMENTAZIONE NOTIFICHE*/
  const notify = firebase.firestore().collection('Notifiche');
  const [notifiche, setNotifiche] = useState([]);
  /*END CHANGE PER IMPLEMENTAZIONE NOTIFICHE*/

  const [dropdownFiltriLav, setDropdownFiltriLav] = useState('');
  const [dropdownFiltriProd, setDropdownFiltriProd] = useState('');
  const [dropdownFiltriMac, setDropdownFiltriMac] = useState('');
  const [dropdownFiltriAdl, setdropdownFiltriAdl] = useState('');
  const [dropdownFiltriStato, setDropdownFiltriStato] = useState('');
  const [dropdownFiltriCant,setDropdownFiltriCant]=React.useState('');
  const [dropdownFiltriZona,setDropdownFiltriZona]=React.useState('');
  const [mappaCostiEst,setMappaCostiEst]=React.useState(new Map());//Aggiunta dev-mattia-mora
  const [mappaCostiInt,setMappaCostiInt]=React.useState(new Map());//Aggiunta dev-mattia-mora
  const [mappaCostiMac,setMappaCostiMac]=React.useState(new Map());//Aggiunta dev-mattia-mora
  const [dataExport,setDataExport]=React.useState([]);
  const [mappaGestionaleDip,setMappaGestionaleDip]=React.useState(new Map());
  const [mappaGestionaleMac,setMappaGestionaleMac]=React.useState(new Map());
  const [mappaNomeCognome,setMappaNomeCognome]=React.useState(new Map());
  const [showLayerCaricamento,setShowLayerCaricamento]=React.useState(false);
  const headerExport=[
    { label: "Codice Attivita", key: "codiceAttivita" },
    { label: "Nome", key: "nome" },
    { label: "Codice Operatore", key: "codiceOperatore" },
    { label: "Data", key: "data" },
    { label: "Stato", key: "stato" },
    { label: "Durata Stimata (min)", key: "durataStimata" },
    { label: "Inizio Attività", key: "start" },
    { label: "Fine Attività", key: "end" },
    { label: "Attività", key: "attivita" },
    { label: "Macchinario", key: "macchinario" },
    { label: "Codice Macchinario", key: "codiceMacchinario" },
    { label: "Area di Lavoro", key: "adl" },
    { label: "Codice Commessa", key: "codiceCommessa" },
    { label: "Zona", key: "zona" },
    { label: "Cantiere", key: "cantiere" },
    { label: "Prodotto", key: "prodotto" },
    { label: "Nota", key: "nota" },
    { label: "Lavoro", key: "lavoro" },
    { label: "Ordinaria/Straordinaria", key: "ordinaria" },
    { label: "Durata Consuntivo (min)", key: "durataConsuntivo" },
  ]
  const  [MapExportCsv,setMappaExportCsv]=React.useState(new Map());
  const  [selectedTab,setTab]=React.useState(0);

  //IMPLEMENTAZIONE PER ARZAGA
  //Se torno al tab del calendario resetto l'array dei dati per l'export CSV
  //Mi permette di bloccare il download del CSV se ritorno nel tab Tabella
  React.useEffect(()=>{
    if(selectedTab==0){
      setDataExport([]);
    }
  },[selectedTab])

  React.useEffect(()=>{
    setShowLayerCaricamento(false);
  },[attivita])

  /**
   * IMPLEMENTAZIONE PER ARZAGA
   * Crea una mappa che assoccia alle ADL di ARZAGA il codice del loro gestionale
   */
  function createMapCsv(){
    const MapExportCsvApp=new Map();
    MapExportCsvApp.set("Jack Nicklaus","1.01.01");
    MapExportCsvApp.set("Gary Player","1.02.01");
    MapExportCsvApp.set("Campo Pratica","1.03.01");
    MapExportCsvApp.set("Blu Hotel","1.04.01");
    MapExportCsvApp.set("Varie","1.05.01");
    MapExportCsvApp.set("Officina","1.06.01");
    MapExportCsvApp.set("Vivaio","1.07.01");
    MapExportCsvApp.set("Pitch & Putt","1.08.01");
    setMappaExportCsv(MapExportCsvApp);
  }

  /**
   * Funzione che crea 3 mappe
   * MappaCostiEst: Mappa che associa le matricole ai costi esterni dei dipendenti
   * MappaCostiInt: Mappa che associa le matricole ai costi interni dei dipendenti
   * MappaNomeCognome: Mappa che associa la matricola al nome - cognome del dipendente
   */
   function getDipMap(){
    db.collection("Dipendenti").get().then((querySnapshot) => {
      const mappaCostiEstApp = new Map();
      const mappaCostiIntApp=new Map();
      const mappaNomeCognomeApp=new Map();
      querySnapshot.forEach((doc) => {
        var data=doc.data();
        mappaCostiEstApp.set(data.Matricola,data.CostoEst);
        mappaCostiIntApp.set(data.Matricola,data.CostoInt);
        mappaNomeCognomeApp.set(data.Matricola,data.Nome+" "+data.Cognome);
      });
    setMappaCostiEst(mappaCostiEstApp);
    setMappaCostiInt(mappaCostiIntApp);
    setMappaNomeCognome(mappaNomeCognomeApp);
  });
  }

  /**
   * Funzione che crea una mappa che associa il nome dei macchinari al loro costo orario
   */
  function getMacMap(){
    db.collection("Macchinari").get().then((querySnapshot) => {
      const mappaCostiMacApp = new Map();
      querySnapshot.forEach((doc) => {
        var data=doc.data();
        mappaCostiMacApp.set(data.NomeMacchina,data.CostoOrario)
      });
      setMappaCostiMac(mappaCostiMacApp);
  });
  }

  let [visualization, setView] = useState(sessionStorage.getItem('visualization') !== null ? (sessionStorage.getItem('visualization') !== '' ? sessionStorage.getItem('visualization') : 'day') : 'day');

  const handleView = (view) => {
    setView(view);
    sessionStorage.setItem('visualization', view);
    if (view === 'month') {
     // sessionStorage.removeItem('visualizationDate');
      if(sessionStorage.getItem("ricarica")!==null){
        sessionStorage.removeItem("ricarica");
        window.location.reload(); 
      } 
    }
  }

  let [visualizationDate, setViewDate] = useState(getCurDate);

  const handleDateView = (date) => {
    setViewDate(date);
   // if(sessionStorage.getItem('visualization')!=="month"){
      sessionStorage.setItem('visualizationDate', date);
    //}
  }

  const [resourceMap, setResourceMap] = useState([]);
  const [events, setEvents] = useState([]);

  let durataTotaleAttivita;
  const [TotalDurata, setTotalDurata] = useState([]);

  let [filtroDip, setFiltroDip] = useState(sessionStorage.getItem('dipendente') !== null ? (sessionStorage.getItem('dipendente') !== '' ? true : false) : false);

  /**
   * Funzione che salva il dipendente selezionato nel filtro Dipendente
   */
  const handleSelectDipendente = (dipendente) => {
    if(dipendente == true) {
      sessionStorage.setItem('dipendente', dipendente);
      setFiltroDip(true);
    } else {
      sessionStorage.setItem('dipendente', '');
      setFiltroDip(false);
    }
  }

  let [filtroArea, setfiltroArea] = useState(sessionStorage.getItem('area') !== null ? (sessionStorage.getItem('area') !== '' ? true : false) : false);

  /**
   * Funzione che salva l'adl selezionata nel filtro Adl
   */
  const handleSelectAdl = (area) => {
    if(area == true) {
      sessionStorage.setItem('area', area);
      setfiltroArea(true);
    } else {
      sessionStorage.setItem('area', '');
      setfiltroArea(false);
    }
  }

  let [filtroMac, setFiltroMac] = useState(sessionStorage.getItem('mac') !== null ? (sessionStorage.getItem('mac') !== '' ? true : false) : false);

  /**
   * Funzione che salva il macchinario selezionato nel filtro Macchinario
   */
  const handleSelectMacchinari = (mac) => {
    if(mac == true) {
      sessionStorage.setItem('mac', mac);
      setFiltroMac(true);
    } else {
      sessionStorage.setItem('mac', '');
      setFiltroMac(false);
    }
  }

  let [filtroLav, setFiltroLav] = useState(sessionStorage.getItem('lav') !== null ? (sessionStorage.getItem('lav') !== '' ? true : false) : false);

  /**
   * Funzione che salva il lavoro selezionato nel filtro Lavoro
   */
  const handleSelectLavori = (lav) => {
    if(lav == true) {
      sessionStorage.setItem('lav', lav);
      setFiltroLav(true);
    } else {
      sessionStorage.setItem('lav', '');
      setFiltroLav(false);
    }
  }

  let [filtroProd, setFiltroProd] = useState(sessionStorage.getItem('prod') !== null ? (sessionStorage.getItem('prod') !== '' ? true : false) : false);

  /**
   * Funzione che salva il prodotto selezionato nel filtro Prodotto
   */
  const handleSelectProdotti = (prod) => {
    if(prod == true) {
      sessionStorage.setItem('prod', prod);
      setFiltroProd(true);
    } else {
      sessionStorage.setItem('prod', '');
      setFiltroProd(false);
    }
  }

  let [filtroStato, setFiltroStato] = useState(sessionStorage.getItem('stato') !== null ? (sessionStorage.getItem('stato') !== '' ? true : false) : false);

  /**
   * Funzione che salva lo stato selezionato nel filtro Stato
   */
  const handleSelectStato = (stato) => {
    if(stato == true) {
      sessionStorage.setItem('stato', stato);
      setFiltroStato(true);
    } else {
      sessionStorage.setItem('stato', '');
      setFiltroStato(false);
    }
  }

  let [filtroData, setFiltroData] = useState(sessionStorage.getItem('data') !== null ? (sessionStorage.getItem('data') !== '' ? true : false) : false);

   /**
   * Funzione che salva le date selezionata nel filtro Data
   */
  const handleSelectData = (data) => {
    if(data == true) {
      sessionStorage.setItem('data', data);
      setFiltroData(true);
    } else {
      sessionStorage.setItem('data', '');
      setFiltroData(false);
      sessionStorage.setItem("DateStartFilter",null);
      sessionStorage.setItem("DateEndFilter",null);
    }
  }

  let [filtroCantiere, setFiltroCantiere ]=useState(sessionStorage.getItem('cantiere') !== null ? (sessionStorage.getItem('cantiere') !== '' ? true : false): false);

   /**
   * Funzione che salva il cantiere selezionato nel filtro Cantiere
   */
  const handleSelectCantiere=(cantiere)=>{
    if(cantiere == true) {
      sessionStorage.setItem('cantiere', cantiere);
      setFiltroCantiere(true);
    } else {
      sessionStorage.setItem('cantiere', '');
      setFiltroCantiere(false);
    }
  }

  let [filtroZona,setFiltroZona]=useState(sessionStorage.getItem('zona') !== null ? (sessionStorage.getItem('zona') !== '' ? true : false): false);

   /**
   * Funzione che salva la zona selezionata nel filtro Zona
   */
  const handleSelectZona=(zona)=>{
    if(zona == true) {
      sessionStorage.setItem('zona', zona);
      setFiltroZona(true);
    } else {
      sessionStorage.setItem('zona', '');
      setFiltroZona(false);
    }
  }

  /**
   * Funzione che gestisce il trascinamento su calendario.
   * Salva in session storage i dati utili e riporta al componente Attivita.js
   */
  const handleSelectSlot = ({start,end,resourceId}) => {
    var os = new Date();
    os = os.getTimezoneOffset();
    var index=0;
    for(var i=0;i<resourceMap.length;i++){
      if(resourceMap[i].resourceId===resourceId){
        index=i;
        break;
      }
    }
    sessionStorage.setItem('StartDateSelected', moment.parseZone(new Date(start)).local().format());
    sessionStorage.setItem('EndDateSelected', moment.parseZone(new Date(end)).local().format());
    sessionStorage.setItem('Dipendente', resourceMap[index].resourceTitle);
    sessionStorage.setItem('Matricola',resourceId);
    window.location.href = window.location.origin + '/attivitaDettaglio';
  }

  let [newStartFilter, setNewStartFilter] = useState( sessionStorage.getItem("DateStartFilter")==="null" || sessionStorage.getItem("DateStartFilter")==="Invalid date"?moment(new Date()).startOf('day').add(-7, 'd').format():sessionStorage.getItem("DateStartFilter"));
  let [newEndFilter, setNewEndFilter] = useState( sessionStorage.getItem("DateEndFilter")==="null" || sessionStorage.getItem("DateEndFilter")==="Invalid date"?moment(new Date()).endOf('day').format():sessionStorage.getItem("DateEndFilter"));

  useEffect(()=>{
    if(newStartFilter!==moment(new Date()).startOf('day').add(-7, 'd').format()){
      sessionStorage.setItem("DateStartFilter",newStartFilter);
    }
   
  },[newStartFilter])

  useEffect(()=>{
    if(newEndFilter!==moment(new Date()).endOf('day').format()){
      sessionStorage.setItem("DateEndFilter",newEndFilter);
    }
   
  },[newEndFilter])

  
  /*
   *  Funzione che trasforma la durata dell'attivita in formato hh:MM
   */
  function durataTrasform(durata){
    if(durata>59){
      var oraT=durata/60;
      var ora=parseInt(oraT);
      var minuti=(oraT-ora)*60;
      var durataF=ora+'h '+Math.round(minuti)+'m';
    }
    else{
      var durataF=Math.round(durata)+'m'
    }
    return durataF;
  }

  /*
   *  Funzione che trasforma la durata dell'attivita nell'excel e nel CSV in formato hh:MM
   */
  function durataTrasformTable(durata){
    if(durata>59){
      var oraT=durata/60;
      var ora=parseInt(oraT);
      var minuti=(oraT-ora)*60;
      var durataF=ora.toLocaleString('it-IT', {
                    minimumIntegerDigits: 2,
                    useGrouping: false
                  })+':'+Math.round(minuti).toLocaleString('it-IT', {
                          minimumIntegerDigits: 2,
                          useGrouping: false
                        });
    }
    else{
      var durataF='00:'+Math.round(durata).toLocaleString('it-IT', {
                          minimumIntegerDigits: 2,
                          useGrouping: false
                        });
    }

    return durataF;
  }


  /**
   * Funzione che crea due mappe
   * MappaGestionaleMac: Associa i telai dei macchinari al codice gestionale di Arzaga
   * MappaGestionaleDip: Associa le matricole dei dipendenti al codice gestionale di Arzaga
   */
   function getMapsGestionale(){
    db.collection("Macchinari").get().then((querySnapshot) => {
      const mappaGestionaleMacApp = new Map();
      querySnapshot.forEach((doc) => {
        var data=doc.data();
        if(data.CodiceGestionale!==undefined){
          mappaGestionaleMacApp.set(data.Telaio,data.CodiceGestionale)
        }
      });
      setMappaGestionaleMac(mappaGestionaleMacApp);
    });
    db.collection("Dipendenti").get().then((querySnapshot) => {
      const mappaGestionaleDipApp = new Map();
      querySnapshot.forEach((doc) => {
        var data=doc.data();
        if(data.CodiceGestionale!==undefined){
          mappaGestionaleDipApp.set(data.Matricola,data.CodiceGestionale)
        }
      });
      setMappaGestionaleDip(mappaGestionaleDipApp);
    });
  }

  /**
   * IMPLEMENTAZIONE PER ARZAGA
   * Funzione che crea il dataset per la generazione del file CSV utilizzato per il gestionale di Arzaga
   */
  function getDataExportCsv(){
    const dataApp=[];
    attivita.forEach((attivita)=>{
      if(attivita.Approvata==="si" || attivita.Approvata==="manuale"){
        dataApp.push({
          codiceAttivita:"DUMMY", //?
          nome: mappaNomeCognome.get(attivita.Matricola) !== undefined ? mappaNomeCognome.get(attivita.Matricola) : "",
          codiceOperatore: (mappaGestionaleDip.get(attivita.Matricola)!==undefined)?mappaGestionaleDip.get(attivita.Matricola):"", 
          data: attivita.Data,
          stato: "Terminata",
          durataStimata:  durataTrasformTable(Number(parseFloat(attivita.Durata).toFixed(0))),
          start: attivita.Start,
          end: attivita.End,
          attivita: attivita.Lavoro,
          macchinario: attivita.Macchina,
          codiceMacchinario: (mappaGestionaleMac.get(attivita.Telaio)!==undefined)?mappaGestionaleMac.get(attivita.Telaio):"",
          adl: attivita.Zona,
          codiceCommessa: MapExportCsv.get(attivita.ZonaFilter),
          zona: attivita.ZonaFilter,
          cantiere: attivita.Cantiere,
          prodotto: attivita.Prodotto,
          nota: attivita.Messaggio,
          lavoro: attivita.Lavoro,
          ordinaria: attivita.Ordinaria,
          durataConsuntivo: durataTrasformTable(Number(parseFloat(((Date.parse(attivita.End) - Date.parse(attivita.Start))/1000/60), 10).toFixed(0))) ,
        })
      }
    })
    setDataExport(dataApp);
  }

  /**
   * Funzione che ottiene da firestore tutte le attività, crea l'array degli eventi per il calendario,calcola la durata totale
   * delle attività e filtra in base ai dati selezionati
   */
   function getAttivita(dropdownFiltriDip,dropdownFiltriMac,dropdownFiltriLav,dropdownFiltriProd,dropdownFiltriStato,dropdownFiltriCant,dropdownFiltriZona,dropdownFiltriAdl,newStartFilter,newEndFilter){
    //Prendo tutte le attività
    
    if(sessionStorage.getItem("Role")==="Admin"){
      att.where('Data', '>=', newStartFilter).where('Data', '<=', newEndFilter).onSnapshot((querySnapshot) => {
        let items = [];
        querySnapshot.forEach((doc) => {
        
          items.push( { ... doc.data(), id: doc.id } );
            
        });
  
  
        //Filtro l'array delle attività in base ai filtri selezionati dall'utente
        if(dropdownFiltriDip!==""){
          items=items.filter((attivita)=>attivita.Matricola===matricolaDipendenteSel)
        }
  
        if(dropdownFiltriMac!==""){
          items=items.filter((attivita)=>attivita.Telaio===telaioMacchinarioSel)
        }
  
        if(dropdownFiltriLav!==""){
          items=items.filter((attivita)=>attivita.Lavoro===dropdownFiltriLav)
        }
  
        if(dropdownFiltriProd!==""){
          items=items.filter((attivita)=>attivita.Sku===sku)
        }
  
        if(dropdownFiltriStato!==""){
          var stato=null;
          if(dropdownFiltriStato==="Chiusura giornaliera"){
            stato="chiusura";
          }else if(dropdownFiltriStato==="Chiusura manuale"){
            stato="manuale"
          }else if(dropdownFiltriStato==="Terminata"){
            stato="si";
          }else if(dropdownFiltriStato==="In corso"){
            stato="partita"
          }
          items=items.filter((attivita)=>attivita.Approvata===stato);
        }
  
        if(dropdownFiltriCant!==""){
          items=items.filter((attivita)=>attivita.Cantiere===dropdownFiltriCant);
        }
  
        if(dropdownFiltriZona!==""){
          items=items.filter((attivita)=>attivita.ZonaFilter===dropdownFiltriZona);
        }
  
        if(dropdownFiltriAdl!==""){
          items=items.filter((attivita)=>attivita.Zona===dropdownFiltriAdl);
        }
       
        setAttivita(items)
  
        //In base all'attività popolo l'array degli eventi diversamente (utilizzato per calendario)
        const eventi = [];
        items.forEach((item) => {
          if (item.Approvata == 'si' || item.Approvata == 'manuale') {
            eventi.push( { id: item.id, durata: item.Durata, title: moment.parseZone(new Date(item.Start)).local().format('HH:mm') + '-' + moment.parseZone(new Date(item.End)).local().format('HH:mm')+' '+item.Lavoro+' '+item.Zona, start: new Date(item.Start), end: new Date(item.End), resourceId: item.Matricola, approvata: item.Approvata } );
          } else if (item.Dipendente == '') {
            eventi.push( { id: item.id, durata: item.Durata, title: item.Start !== undefined && item.End !== undefined ? moment.parseZone(new Date(item.Start)).local().format('HH:mm') + '-' + moment.parseZone(new Date(item.End)).local().format('HH:mm')+' '+item.Lavoro+' '+item.Zona : item.Lavoro+' '+item.Zona, start: item.Start !== undefined ? new Date(item.Start) : new Date(Number(item.Data.substring(0, 4)), Number(item.Data.substring(5, 7))-1, Number(item.Data.substring(8, 10)), 0, 0, 0), end: item.End !== undefined ? new Date(item.End) : new Date(Number(item.Data.substring(0, 4)), Number(item.Data.substring(5, 7))-1, Number(item.Data.substring(8, 10)), 0, 0, 0), resourceId: 'NON_ASSEGNATA', approvata: item.Approvata } );
          } else if (item.Start == undefined || item.End == undefined) {
            eventi.push( { id: item.id, durata: item.Durata, title: item.Lavoro+' '+item.Zona, start: new Date(Number(item.Data.substring(0, 4)), Number(item.Data.substring(5, 7))-1, Number(item.Data.substring(8, 10), 9, 0, 0)), end: new Date(Number(item.Data.substring(0, 4)), Number(item.Data.substring(5, 7))-1, Number(item.Data.substring(8, 10), 10, 0, 0)), resourceId: item.Matricola, approvata: item.Approvata } );
          } else {
            eventi.push( { id: item.id, durata: item.Durata, title: moment.parseZone(new Date(item.Start)).local().format('HH:mm') + '-' + moment.parseZone(new Date(item.End)).local().format('HH:mm')+' '+item.Lavoro+' '+item.Zona, start: new Date(item.Start), end: new Date(item.End), resourceId: item.Matricola, approvata: item.Approvata } );
          }
        });
        setEvents(eventi);
  
      //Creo un array per poi calcolare la durata totale delle attività
        const times = [];
        items.forEach((item) => {
        if (item.Approvata == 'si' || item.Approvata == 'manuale') {
            times.push( { Start: item.Start, End: item.End } );
          } else {
            times.push( { Start: '2021-07-02T13:09:31.561Z', End: '2021-07-02T13:09:31.561Z' } );
          }
        });
        durataTotaleAttivita = times.reduce((totalTime, durata) => totalTime + Number(parseFloat(((Date.parse(durata.End) - Date.parse(durata.Start))/1000/60), 10).toFixed(2)), 0);
  
        setTotalDurata(durataTotaleAttivita);
      
      });
    }else{
      att.where('Data', '>=', newStartFilter).where('Data', '<=', newEndFilter).where("Cantiere","==",sessionStorage.getItem("Cantiere")).onSnapshot((querySnapshot) => {
        let items = [];
        querySnapshot.forEach((doc) => {
        
          items.push( { ... doc.data(), id: doc.id } );
            
        });
  
  
        //Filtro l'array delle attività in base ai filtri selezionati dall'utente
        if(dropdownFiltriDip!==""){
          items=items.filter((attivita)=>attivita.Matricola===matricolaDipendenteSel)
        }
  
        if(dropdownFiltriMac!==""){
          items=items.filter((attivita)=>attivita.Telaio===telaioMacchinarioSel)
        }
  
        if(dropdownFiltriLav!==""){
          items=items.filter((attivita)=>attivita.Lavoro===dropdownFiltriLav)
        }
  
        if(dropdownFiltriProd!==""){
          items=items.filter((attivita)=>attivita.Sku===sku)
        }
  
        if(dropdownFiltriStato!==""){
          var stato=null;
          if(dropdownFiltriStato==="Chiusura giornaliera"){
            stato="chiusura";
          }else if(dropdownFiltriStato==="Chiusura manuale"){
            stato="manuale"
          }else if(dropdownFiltriStato==="Terminata"){
            stato="si";
          }else if(dropdownFiltriStato==="In corso"){
            stato="partita"
          }
          items=items.filter((attivita)=>attivita.Approvata===stato);
        }
  
        if(dropdownFiltriCant!==""){
          items=items.filter((attivita)=>attivita.Cantiere===dropdownFiltriCant);
        }
  
        if(dropdownFiltriZona!==""){
          items=items.filter((attivita)=>attivita.ZonaFilter===dropdownFiltriZona);
        }
  
        if(dropdownFiltriAdl!==""){
          items=items.filter((attivita)=>attivita.Zona===dropdownFiltriAdl);
        }
       
        setAttivita(items)
  
        //In base all'attività popolo l'array degli eventi diversamente (utilizzato per calendario)
        const eventi = [];
        items.forEach((item) => {
          if (item.Approvata == 'si' || item.Approvata == 'manuale') {
            eventi.push( { id: item.id, durata: item.Durata, title: moment.parseZone(new Date(item.Start)).local().format('HH:mm') + '-' + moment.parseZone(new Date(item.End)).local().format('HH:mm')+' '+item.Lavoro+' '+item.Zona, start: new Date(item.Start), end: new Date(item.End), resourceId: item.Matricola, approvata: item.Approvata } );
          } else if (item.Dipendente == '') {
            eventi.push( { id: item.id, durata: item.Durata, title: item.Start !== undefined && item.End !== undefined ? moment.parseZone(new Date(item.Start)).local().format('HH:mm') + '-' + moment.parseZone(new Date(item.End)).local().format('HH:mm')+' '+item.Lavoro+' '+item.Zona : item.Lavoro+' '+item.Zona, start: item.Start !== undefined ? new Date(item.Start) : new Date(Number(item.Data.substring(0, 4)), Number(item.Data.substring(5, 7))-1, Number(item.Data.substring(8, 10)), 0, 0, 0), end: item.End !== undefined ? new Date(item.End) : new Date(Number(item.Data.substring(0, 4)), Number(item.Data.substring(5, 7))-1, Number(item.Data.substring(8, 10)), 0, 0, 0), resourceId: 'NON_ASSEGNATA', approvata: item.Approvata } );
          } else if (item.Start == undefined || item.End == undefined) {
            eventi.push( { id: item.id, durata: item.Durata, title: item.Lavoro+' '+item.Zona, start: new Date(Number(item.Data.substring(0, 4)), Number(item.Data.substring(5, 7))-1, Number(item.Data.substring(8, 10), 9, 0, 0)), end: new Date(Number(item.Data.substring(0, 4)), Number(item.Data.substring(5, 7))-1, Number(item.Data.substring(8, 10), 10, 0, 0)), resourceId: item.Matricola, approvata: item.Approvata } );
          } else {
            eventi.push( { id: item.id, durata: item.Durata, title: moment.parseZone(new Date(item.Start)).local().format('HH:mm') + '-' + moment.parseZone(new Date(item.End)).local().format('HH:mm')+' '+item.Lavoro+' '+item.Zona, start: new Date(item.Start), end: new Date(item.End), resourceId: item.Matricola, approvata: item.Approvata } );
          }
        });
        setEvents(eventi);
  
      //Creo un array per poi calcolare la durata totale delle attività
        const times = [];
        items.forEach((item) => {
        if (item.Approvata == 'si' || item.Approvata == 'manuale') {
            times.push( { Start: item.Start, End: item.End } );
          } else {
            times.push( { Start: '2021-07-02T13:09:31.561Z', End: '2021-07-02T13:09:31.561Z' } );
          }
        });
        durataTotaleAttivita = times.reduce((totalTime, durata) => totalTime + Number(parseFloat(((Date.parse(durata.End) - Date.parse(durata.Start))/1000/60), 10).toFixed(2)), 0);
  
        setTotalDurata(durataTotaleAttivita);
      
      });
    }
    
}


   /**
    * Funzione che in bae al ruolo dell'utente loggato e al cantiere/zona selezionato/a crea il dataset di dipendenti
    * che formeranno le colonne del calendario 
    */
  function getResources() {
    const risorse = [];
    if (sessionStorage.getItem('Role') == 'Admin') {
      dip.where('Ruolo', 'not-in', ['Admin']).orderBy("Ruolo", "asc").orderBy("Cognome", "asc").onSnapshot((querySnapshot) => {
        const items = [];
        const elencoNomi=[];
        querySnapshot.forEach((doc) => {
          if(doc.data().email!="admin.arzaga@arzagagolf.it" && doc.data().email!="m.goffi@arzagagolf.it"){  //Implementazione ruoli MOMENTANEA
            items.push( { ... doc.data(), id: doc.id } );
          }
        });
        items.forEach((item) => {
          if(elencoNomi.indexOf(item.Nome+" "+item.Cognome)===-1){
            elencoNomi.push(item.Nome+" "+item.Cognome);
            risorse.push( {resourceId: item.Matricola, resourceTitle: item.Nome+' '+item.Cognome} );
          }else{
            risorse.push( {resourceId: item.Matricola, resourceTitle: item.Nome+' '+item.Cognome+" "+item.Matricola} );
          }

        });
        risorse.push( {resourceId: 'NON_ASSEGNATA', resourceTitle: 'NON ASSEGNATE'} );

        setResourceMap(risorse);

      });
    } else if (sessionStorage.getItem('Role') == 'Admin_Cantiere') {
      dip.where('Cantiere', 'array-contains', sessionStorage.getItem('Cantiere')).orderBy("Cognome", "asc").onSnapshot((querySnapshot) => {
        const items = [];
        const elencoNomi=[];
        querySnapshot.forEach((doc) => {
          if(doc.data().email!="admin.arzaga@arzagagolf.it"  && doc.data().email!="m.goffi@arzagagolf.it"){ //Implementazione ruoli MOMENTANEA
            items.push( { ... doc.data(), id: doc.id } );
          }
        });
        items.forEach((item) => {
          if(elencoNomi.indexOf(item.Nome+" "+item.Cognome)===-1){
            elencoNomi.push(item.Nome+" "+item.Cognome);
            risorse.push( {resourceId: item.Matricola, resourceTitle: item.Nome+' '+item.Cognome} );
          }else{
            risorse.push( {resourceId: item.Matricola, resourceTitle: item.Nome+' '+item.Cognome+" "+item.Matricola} );
          }
        });
        risorse.push( {resourceId: 'NON_ASSEGNATA', resourceTitle: 'NON ASSEGNATE'} );
        setResourceMap(risorse);
      });
    } else {
      dip.where('Zona', 'array-contains', sessionStorage.getItem('Cantiere')).orderBy("Cognome", "asc").onSnapshot((querySnapshot) => {
        const items = [];
        const elencoNomi=[];
        querySnapshot.forEach((doc) => {
          if(doc.data().email!="admin.arzaga@arzagagolf.it"  && doc.data().email!="m.goffi@arzagagolf.it"){ //Implementazione ruoli MOMENTANEA
            items.push( { ... doc.data(), id: doc.id } );
          }
        });
        items.forEach((item) => {
          if(elencoNomi.indexOf(item.Nome+" "+item.Cognome)===-1){
            elencoNomi.push(item.Nome+" "+item.Cognome);
            risorse.push( {resourceId: item.Matricola, resourceTitle: item.Nome+' '+item.Cognome} );
          }else{
            risorse.push( {resourceId: item.Matricola, resourceTitle: item.Nome+' '+item.Cognome+" "+item.Matricola} );
          }
        });
        risorse.push( {resourceId: 'NON_ASSEGNATA', resourceTitle: 'NON ASSEGNATE'} );

        setResourceMap(risorse);
      });
    }
  }



  const [matricolaDipendenteSel,setmatricolaDipendenteSel]=React.useState(); //Matricola del dipendente utilizzata per le query
  const [telaioMacchinarioSel,setTelaioMacchinarioSel]=React.useState(); //Telaio del macchinario utilizzato per le query
  const [sku,setSku]=React.useState(); //Codice Sku del prodotto utilizzato per le query
  const elencoMatricole=[];//Per tab Calendario, array ordinato che permette di avere il numero di matricola corretto in base all'indice del dipendente selezionato nel Select
  const elencoMacchinari=[];//Per tab Calendario(Contiene i telai),array ordinato che permette di avere il numero di telaio corretto in base all'indice del macchinario selezionato nel Select
  const elencoSku=[];//Per tab Calendario, array ordinato che permette di avere il numero Sku corretto in base all'indice del prodotto selezionato nel Select

  const elencoMatricoleTabella=[]; //Per tab tabella(Contiene i telai)
  const elencoMacchinariTabella=[];//Per tab tabella
  const elencoSkuTabella=[];//Per tab tabella

  /**
   * Serie di funzioni che impostano la chiave univoca dell'anagrafica selezionata
   */

  function onChangedSetDip(option){
    setmatricolaDipendenteSel(elencoMatricole[option.selected]);
    setDropdownFiltriDip(option.value);
    sessionStorage.setItem("dipFilter",option.value);
    sessionStorage.setItem("matricolaFilter",elencoMatricole[option.selected])
  }

  function onChangedSetMac(option){
    setTelaioMacchinarioSel(elencoMacchinari[option.selected]);
    setDropdownFiltriMac(option.value);
    sessionStorage.setItem("macFilter",option.value);
    sessionStorage.setItem("telaioFilter",elencoMacchinari[option.selected])
  }

  function onChangedSetSku(option){
    setSku(elencoSku[option.selected]);
    setDropdownFiltriProd(option.value);
    sessionStorage.setItem("prodFilter",option.value);
    sessionStorage.setItem("skuFilter",elencoSku[option.selected]);
  }

   function onChangedSetDipTabella(option){
    setmatricolaDipendenteSel(elencoMatricoleTabella[option.selected]);
    setDropdownFiltriDip(option.value);
    sessionStorage.setItem("dipFilter",option.value);
    sessionStorage.setItem("matricolaFilter",elencoMatricole[option.selected])
  }

  function onChangedSetMacTabella(option){
    setTelaioMacchinarioSel(elencoMacchinariTabella[option.selected]);
    setDropdownFiltriMac(option.value);
    sessionStorage.setItem("macFilter",option.value);
    sessionStorage.setItem("telaioFilter",elencoMacchinari[option.selected])
  }

  function onChangedSetSkuTabella(option){
    setSku(elencoSkuTabella[option.selected]);
    setDropdownFiltriProd(option.value);
    sessionStorage.setItem("prodFilter",option.value);
    sessionStorage.setItem("skuFilter",elencoSku[option.selected]);
  }

  /**
   * Funzione che implementa un Event personalizzato del calendario
   * Implementa un attività (posta in alto nel calendario) della chiusura giornaliera
   */
  function Event({ event }) {
    let popoverClickRootClose = (
      <Popover id="popover-trigger-click-root-close" style={{ zIndex: 10000 ,margin:"0px"}}>
        <Box>
          <Button style={{backgroundColor: "white",color:'#006837',fontWeight:'bold'}} color="brand" margin="xsmall" icon=<Task color="brand"/> onClick={() => window.location.href = window.location.origin + "/dettaglio/"+event.id} label="Dettaglio" />
          <Button style={{backgroundColor: "white",color:'#006837',fontWeight:'bold'}} color="brand" margin="xsmall" icon=<FormClose color="brand"/> onClick={() => onDelete(event.id)} label="Elimina" />
        </Box>
      </Popover>
    );

    return (
      <Box>
        <Box>
          <OverlayTrigger id="help" trigger="click" rootClose container={this} overlay={popoverClickRootClose}>
            <Box width="xsmall">
            {moment.parseZone(new Date(event.start)).local().format('HH:mm') !== '00:00' && moment.parseZone(new Date(event.end)).local().format('HH:mm') !== '00:00'
            ?
            <Text>{event.title}</Text>
            :
            event.approvata == 'chiusura'
            ?
              <Text>ORE: {Number(event.durata).toFixed(2)}</Text>
            :
              <Text truncate='true'>{event.title}</Text>}
            </Box>
          </OverlayTrigger>
        </Box>
      </Box>
    );
  }

  
  /**
   * Funzione che implementa un Event personalizzato del calendario
   * Implementa la comparsa dei bottoni "Dettaglio" ed "Elimina" al click sul testo di un attività
   * presente sul calendario
   */
  function Event2({ event }) {
    let popoverClickRootClose = (
      <Popover id="popover-trigger-click-root-close" style={{ zIndex: 10000 }}>
        <Box>
          <Button style={{backgroundColor: "white",color:'#006837',fontWeight:'bold'}} color="brand" margin="xsmall" icon=<Task color="brand"/> onClick={() => window.location.href = window.location.origin + "/dettaglio/"+event.id} label="Dettaglio" />
          <Button style={{backgroundColor: "white",color:'#006837',fontWeight:'bold'}} color="brand" margin="xsmall" icon=<FormClose color="brand"/> onClick={() => onDelete(event.id)} label="Elimina" />
        </Box>
      </Popover>
    );

    return (
      <Box>
        <Box>
          <OverlayTrigger id="help" trigger="click" rootClose container={this} placement="bottom" overlay={popoverClickRootClose}>
            <Box width="small">
            {moment.parseZone(new Date(event.start)).local().format('HH:mm') !== '00:00' && moment.parseZone(new Date(event.end)).local().format('HH:mm') !== '00:00'
            ?
            <Text>{event.resourceId.toUpperCase()+' '+event.title.substr(12, 1000)}</Text>
            :
            <Text truncate='true'>{event.resourceId.toUpperCase()+' '+event.title.substr(12, 1000)}</Text>}
            {/*{event.approvata == 'chiusura'
            ?
            <Text>{event.durata}</Text>
            :
            <Text truncate='true'>{event.durata}</Text>}*/}
            </Box>
          </OverlayTrigger>
        </Box>
      </Box>
    );
  }


  const formats = {
    eventTimeRangeFormat: () => {
      return "";
    },
  };

  /**
   * Funzione che cancella l'attività selezionata
   */
  function onDelete(event) {
    const db = firebase.firestore();
    const ask = window.confirm("SEI SICURO DI VOLER ELIMINARE L'ATTIVITA?");
    if (ask == true) {
      db.collection('Attivita').doc(event).delete();
    } else {
      alert("OPERAZIONE ANNULLATA!");
    }
  }

  /**
   * Funzione che legge il ruolo dell'utente loggato
   */
  function getRuoli() {
    role.where('Utente', '==', userLogged.email).onSnapshot((querySnapshot) => {
      const items = [];
      querySnapshot.forEach((doc) => {
        items.push( { ... doc.data(), id: doc.id } );
      });
      setRuoli(items);

      checkScadenza(items)
    });
  }

   /**
   * Modifica dev-mattia-mora
   */
  const [boolScadenza,setBoolScadenza]=React.useState(false);

  /**
   * Modifica dev-mattia-mora
   */
  function replaceRange(s, start, end, substitute) {
    return s.substring(0, start) + substitute + s.substring(end);
  }

  /**
   * Funzione che controlla la scadenza dei cantieri/zone o adl
   * Se trova elementi scaduti blocca la creazione delle attività
   * @param {*} ruoli passata da getRuoli, array dei ruoli
   */
  function checkScadenza(ruoli){
    const cant = firebase.firestore().collection('Aree');
    if(ruoli[0].Ruolo == 'Admin_Cantiere'){
      cant.where('Nome', '==', sessionStorage.getItem("Cantiere")).onSnapshot((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          var dataOdierna=moment(new Date()).toDate();
          //moment() non accetta date in formato dd:mm:yyyy, devo convertirle manualmente in mm:dd:yyyy
          var dataFineToConvert=doc.data().FineCantiere;
          var day=dataFineToConvert.substring(0,2);
          var month=dataFineToConvert.substring(3,5);
          dataFineToConvert=replaceRange(dataFineToConvert, 0, 2, month);
          dataFineToConvert=replaceRange(dataFineToConvert, 3, 5, day);
          var dataFine=moment(dataFineToConvert).toDate();
          if(dataOdierna.getFullYear() < dataFine.getFullYear()){

          }else if(dataOdierna.getFullYear() == dataFine.getFullYear() && dataOdierna.getMonth() < dataFine.getMonth()){

          }else if(dataOdierna.getMonth() == dataFine.getMonth() && dataOdierna.getDate() < dataFine.getDate()){

          }else if(dataOdierna.getMonth() == dataFine.getMonth() && dataOdierna.getDate() == dataFine.getDate()){
            setBoolScadenza(true);
          }else{
            setBoolScadenza(true);
          }
        })
      });
    }else if(ruoli[0].Ruolo == 'Admin_Zona'){
      cant.where('Zona', '==', sessionStorage.getItem("Cantiere")).onSnapshot((querySnapshot) => {
        querySnapshot.forEach((doc) => {

          var dataOdierna=moment(new Date()).toDate();
          //moment() non accetta date in formato dd:mm:yyyy, devo convertirle manualmente in mm:dd:yyyy
          var dataFineToConvert=doc.data().FineZona;
          var day=dataFineToConvert.substring(0,2);
          var month=dataFineToConvert.substring(3,5);
          dataFineToConvert=replaceRange(dataFineToConvert, 0, 2, month);
          dataFineToConvert=replaceRange(dataFineToConvert, 3, 5, day);
          var dataFine=moment(dataFineToConvert).toDate();
          if(dataOdierna.getFullYear() < dataFine.getFullYear()){

          }else if(dataOdierna.getFullYear() == dataFine.getFullYear() && dataOdierna.getMonth() < dataFine.getMonth()){

          }else if(dataOdierna.getMonth() == dataFine.getMonth() && dataOdierna.getDate() < dataFine.getDate()){

          }else if(dataOdierna.getMonth() == dataFine.getMonth() && dataOdierna.getDate() == dataFine.getDate()){
            setBoolScadenza(true);
          }else{
            setBoolScadenza(true);
          }
        })
      });
    }
   

  }

  useEffect(() => {
    function initDip() {
      setDropdownFiltriDip(sessionStorage.getItem('visualizationDip') !== null ? (sessionStorage.getItem('visualizationDip') !== '' ? sessionStorage.getItem('visualizationDip') : '') : '');
    }
    initDip();
    if(sessionStorage.getItem('DateStartFilter')!=="null"){
      setNewStartFilter(sessionStorage.getItem('DateStartFilter'));
    }else{
      setNewStartFilter(moment(new Date()).startOf('day').add(-7, 'd').format());
    }
    if(sessionStorage.getItem('DateEndFilter')!=="null"){
      setNewEndFilter(sessionStorage.getItem('DateEndFilter'));
    }else{
      setNewEndFilter(moment(new Date()).endOf('day').format());
    }
    if(sessionStorage.getItem("macFilter")!==null){
      setDropdownFiltriMac(sessionStorage.getItem("macFilter"));
      setTelaioMacchinarioSel(sessionStorage.getItem("telaioFilter"))
    }
    if(sessionStorage.getItem("dipFilter")!==null){
      setDropdownFiltriDip(sessionStorage.getItem("dipFilter"));
      setmatricolaDipendenteSel(sessionStorage.getItem("matricolaFilter"))
    }
    if(sessionStorage.getItem("skuFilter")!==null){
      setDropdownFiltriProd(sessionStorage.getItem("prodFilter"));
      setSku(sessionStorage.getItem("prodFilter"));
    }
    if(sessionStorage.getItem("lavFilter")!==null){
      setDropdownFiltriLav(sessionStorage.getItem("lavFilter"));
    }
    if(sessionStorage.getItem("zonaFilter")!==null){
      setDropdownFiltriZona(sessionStorage.getItem("zonaFilter"));
    }
    if(sessionStorage.getItem("cantFilter")!==null){
      setDropdownFiltriCant(sessionStorage.getItem("cantFilter"));
    }
    if(sessionStorage.getItem("adlFilter")!==null){
      setdropdownFiltriAdl(sessionStorage.getItem("adlFilter"));
    }
    if(sessionStorage.getItem("statoFilter")!==null){
      setDropdownFiltriStato(sessionStorage.getItem("statoFilter"));
    }
    if(sessionStorage.getItem('visualizationDate')!==null && sessionStorage.getItem('visualization')!=='month'){
      setViewDate(sessionStorage.getItem('visualizationDate'));
    }

    createMapCsv(); //PER EXPORT CSV
    getMapsGestionale() //PER EXPORT CSV
    getDipMap(); //Aggiunta dev-mattia-mora
    getMacMap(); //Aggiunta dev-mattia-mora
  }, []);

  useEffect(() => {
    setShowLayerCaricamento(true);
    getAttivita(dropdownFiltriDip,dropdownFiltriMac,dropdownFiltriLav,dropdownFiltriProd,dropdownFiltriStato,dropdownFiltriCant,dropdownFiltriZona,dropdownFiltriAdl,newStartFilter,newEndFilter)
    getResources();
    getRuoli();
  }, [dropdownFiltriDip, dropdownFiltriLav, dropdownFiltriProd, dropdownFiltriMac, newStartFilter, newEndFilter, dropdownFiltriStato,dropdownFiltriAdl,dropdownFiltriCant,dropdownFiltriZona]);

  /**
   * Utilizzato per la rimozione dei filtri, triggera lo useEffect sopra che richiama getAttivita, quest'ultima ottenendo il dropDownFiltro==="" non applicherà il filtro
   * appena rimosso 
   */
  useEffect(()=>{
    if(!filtroDip){
      setDropdownFiltriDip("");
    }

    if(!filtroMac){
      setDropdownFiltriMac("");
    }

    if(!filtroLav){
      setDropdownFiltriLav("");
    }

    if(!filtroProd){
      setDropdownFiltriProd("");
    }

    if(!filtroStato){
      setDropdownFiltriStato("");
    }

    if(!filtroCantiere){
      setDropdownFiltriCant("");
    }

    if(!filtroZona){
      setDropdownFiltriZona("");
    }

    if(!filtroArea){
      setdropdownFiltriAdl("");
    }
  },[filtroDip,filtroMac,filtroLav,filtroProd,filtroStato,filtroCantiere,filtroZona,filtroArea])

  if (sessionStorage.getItem("firebase:authUser:AIzaSyACdCazXkw1sBqvo1W7d066eLqk7Crzr0A:[DEFAULT]") == null) {
    return <Redirect to='/'  />
  }

  const userLogged = JSON.parse(sessionStorage.getItem("firebase:authUser:AIzaSyACdCazXkw1sBqvo1W7d066eLqk7Crzr0A:[DEFAULT]"));

  /*
    Funzione che rileva quale tab è stato selezionato
  */
  const onActive = (nextIndex) =>{ console.log(nextIndex);setTab(nextIndex)}

  return (
    <Grommet theme={theme} themeMode="dark">

      <ResponsiveContext.Consumer>
        {size => (
        <Box fill pad="small">
          <Box height="97vh" direction="row" overflow="auto" pad="small" flex="false">
            <Box margin="5%" direction='row' flex>
                    {showLayerCaricamento && (
                       <Layer onEsc={()=>setShowLayerCaricamento(false)} onClickOutside={()=>setShowLayerCaricamento(false)}>
                        <Box align="center" gap="medium" pad="small">
                          <CircularProgress style={{'color': 'green'}} variant="determinate" value={90} />
                          <Text>Caricamento...</Text>
                        </Box>
                      </Layer>
                    )
                    }
                  <Tabs alignControls="start" onActive={onActive}>
                    <Tab title="Calendario">
                      <Box direction='row' flex="grow" overflow={{ horizontal: 'hidden' }} justify="start">
                        <Box gap="medium" direction="row" margin='small' alignSelf="end"style={{position:'fixed',bottom:'5%',zIndex:'5',right:'5%'}}>
                        {!boolScadenza?
                          <>
                          <Box alignSelf="end" id="tooltip">
                            <Button
                              icon={<Add color="white"/>}
                              onClick={() => history.push('/attivitaDettaglio')}
                              style={{fontSize:'28px',background:'#8FBCA7', padding:'10px',color:'white',fontWeight:'bold',textTransform: 'uppercase'}}>
                            </Button>
                            <span id="tooltiptext">Crea Attività</span>
                          </Box>
                          </>
                            :
                            null
                            }
                        <Box id="tooltip">
                        <DropButton
                            icon={<Filter color='white'/>}
                            style={{fontSize:'28px',background:'#8FBCA7', padding:'10px',color:'white',fontWeight:'bold',textTransform: 'uppercase'}}
                            dropAlign={{ bottom: 'bottom', right: 'left' }}
                            dropContent={
                              <>
                              <Box pad="small">
                                <CheckBox
                                  checked={filtroDip}
                                  label="Filtro Dipendente"
                                  onChange={(event) => handleSelectDipendente(event.target.checked)}
                                />
                              </Box>
                              <Box pad="small">
                                <CheckBox
                                  checked={filtroMac}
                                  label="Filtro Macchinario"
                                  onChange={(event) => handleSelectMacchinari(event.target.checked)}
                                />
                              </Box>
                              <Box pad="small">
                                <CheckBox
                                  checked={filtroLav}
                                  label="Filtro Lavoro"
                                  onChange={(event) => handleSelectLavori(event.target.checked)}
                                />
                              </Box>
                              <Box pad="small">
                                <CheckBox
                                  checked={filtroProd}
                                  label="Filtro Prodotto"
                                  onChange={(event) => handleSelectProdotti(event.target.checked)}
                                />
                              </Box>
                              <Box pad="small">
                                <CheckBox
                                  checked={filtroData}
                                  label="Filtro Data"
                                  onChange={(event) => handleSelectData(event.target.checked)}
                                />
                              </Box>
                              <Box pad="small">
                                <CheckBox
                                  checked={filtroStato}
                                  label="Filtro Stato"
                                  onChange={(event) => handleSelectStato(event.target.checked)}
                                />
                              </Box>
                              {
                                ruoli[0] !== undefined?
                              ruoli[0].Ruolo=='Admin'?
                              <>
                              <Box pad="small">
                                <CheckBox
                                  checked={filtroCantiere}
                                  label="Filtro Cantiere"
                                  onChange={(event) => handleSelectCantiere(event.target.checked)}
                                />
                              </Box>
                              </>
                              :
                              null
                              :
                              null
                              }
                              <Box pad="small">
                                <CheckBox
                                  checked={filtroZona}
                                  label="Filtro Zona"
                                  onChange={(event) => handleSelectZona(event.target.checked)}
                                />
                              </Box>
                              <Box pad="small">
                                <CheckBox
                                  checked={filtroArea}
                                  label="Filtro Area di Lavoro"
                                  onChange={(event) => handleSelectAdl(event.target.checked)}
                                />
                              </Box>
                              </>
                            }
                          />
                          <span id="tooltiptext">Filtri</span>
                          </Box>
                        </Box>
                      </Box>
                      <Box alignSelf="start" direction='row'  style={{marginLeft:'10px'}}>
                        {
                            filtroCantiere?
                            <Box margin='xxsmall'>
                            <Text>Seleziona il Cantiere: </Text>
                            <Select
                              options={[...new Set(attivita.sort(function(a, b) { return a.Cantiere.localeCompare(b.Cantiere) }).map(attivita => (attivita.Cantiere)))]}
                              value={dropdownFiltriCant}
                              onChange={({ option }) => {setDropdownFiltriCant(option);sessionStorage.setItem("cantFilter",option)}}
                            />
                            { dropdownFiltriCant== '' ?
                             null
                              :
                              <Anchor onClick={() =>{setDropdownFiltriCant('');sessionStorage.removeItem("cantFilter")}} label="Rimuovi Filtro" />
                            }
                          </Box>
                          : null
                          }
                           {
                            filtroZona?
                            <Box margin='xxsmall'>
                            <Text>Seleziona la zona: </Text>
                            <Select
                              options={[...new Set(attivita.sort(function(a, b) { return a.ZonaFilter.localeCompare(b.ZonaFilter) }).map(attivita => (attivita.ZonaFilter)))]}
                              value={dropdownFiltriZona}
                              onChange={({ option }) => {setDropdownFiltriZona(option);sessionStorage.setItem("zonaFilter",option)}}
                            />
                            { dropdownFiltriZona== '' ?
                             null
                              :
                              <Anchor onClick={() =>{setDropdownFiltriZona('');sessionStorage.removeItem("zonaFilter")}} label="Rimuovi Filtro" />
                            }
                          </Box>
                          : null
                          }
                         {
                        filtroArea ?
                        <Box margin='xxsmall'>
                          <Text>Seleziona Adl: </Text>
                          <Select
                            options={[...new Set(attivita.sort(function(a, b) { return a.Zona.localeCompare(b.Zona) }).map(attivita => (attivita.Zona)))]}
                            value={dropdownFiltriAdl}
                            onChange={({ option }) =>{setdropdownFiltriAdl(option);;sessionStorage.setItem("adlFilter",option)}}
                          />
                          { dropdownFiltriAdl == '' ?
                           null
                            :
                            <Anchor onClick={() => {setdropdownFiltriAdl('');;sessionStorage.removeItem("adlFilter")}} label="Rimuovi Filtro" />
                          }
                        </Box>
                        :
                        null
                        }
                        { filtroDip ?
                        <Box margin='xxsmall'>
                          <Text >Seleziona l'operatore: </Text>
                          <Select
                            options={[...new Set(attivita.sort(function(a, b) { return a.Dipendente.localeCompare(b.Dipendente) }).filter(
                              function(a){
                                if(elencoMatricole.indexOf(a.Matricola)===-1){ //Logica per la gestione dei dipendenti, filtra dalle attività presenti
                                    elencoMatricole.push(a.Matricola);        //in base al numero di matricola in modo che non ci siano ripetizioni nel Select
                                    return true;
                                  }
                                  return false;

                            }).map((item)=> mappaNomeCognome.get(item.Matricola)))]}
                            value={dropdownFiltriDip}
                            onChange={(option ) => onChangedSetDip(option)}
                          />
                          { dropdownFiltriDip == '' ?
                           null
                            :
                            <Anchor onClick={() => {setDropdownFiltriDip('');sessionStorage.removeItem("dipFilter");sessionStorage.removeItem("matricolaFilter")}} label="Rimuovi Filtro" />
                          }
                        </Box>
                        :
                        null
                        }
                        </Box>
                     
                        <Box alignSelf="start" direction='row'  style={{marginLeft:'10px'}}>
                        { filtroMac ?
                        <Box margin='xxsmall'>
                          <Text >Seleziona il Macchinario: </Text>
                          <Select
                            options={[...new Set(attivita.sort(function(a, b) { return a.Macchina.localeCompare(b.Macchina)}).filter(
                              function(a){
                                
                                if((typeof a.Telaio)=="string" && elencoMacchinari.indexOf(a.Telaio)===-1){ //Logica per la gestione dei macchinari, filtra dalle attività present  
                                  elencoMacchinari.push(a.Telaio);         //in base al numero di telaio in modo che non ci siano ripetizioni nel Select
                                    return true;
                                  }
                                  return false;

                            }).map((item)=>item.Macchina))]}
                            value={dropdownFiltriMac}
                            onChange={( option ) => onChangedSetMac(option)}
                          />
                          { dropdownFiltriMac == '' ?
                           null
                            :
                            <Anchor onClick={() => {setDropdownFiltriMac('');sessionStorage.removeItem("macFilter");sessionStorage.removeItem("telaioFilter")}} label="Rimuovi Filtro" />
                          }
                        </Box>
                        :
                        null
                        }
                        { filtroLav ?
                        <Box margin='xxsmall'>
                          <Text>Seleziona il Lavoro: </Text>
                          <Select
                            options={[...new Set(attivita.sort(function(a, b) { return a.Lavoro.localeCompare(b.Lavoro) }).map(attivita => (attivita.Lavoro)))]}
                            value={dropdownFiltriLav}
                            onChange={({ option }) => {setDropdownFiltriLav(option);;sessionStorage.setItem("lavFilter",option)}}
                          />
                          { dropdownFiltriLav == '' ?
                           null
                            :
                            <Anchor onClick={() => {setDropdownFiltriLav('');sessionStorage.removeItem("lavFilter")}} label="Rimuovi Filtro" />
                          }
                        </Box>
                        :
                        null
                        }
                        { filtroProd ?
                        <Box margin='xxsmall'>
                          <Text>Seleziona il Prodotto: </Text>
                          <Select
                            options={[...new Set(attivita.sort(function(a, b) { return a.Prodotto.localeCompare(b.Prodotto) }).filter(
                              function(a){
                                if(elencoSku.indexOf(a.Sku)===-1){ //Logica per la gestione dei prodotti, filtra dalle attività presenti
                                  elencoSku.push(a.Sku);          //in base al numero Sku in modo che non ci siano ripetizioni nel Select
                                  return true;
                                  }
                                  return false;
                                }
                            ).map((item)=>item.Prodotto))]}
                            value={dropdownFiltriProd}
                            onChange={( option ) => onChangedSetSku(option)}
                          />
                          { dropdownFiltriProd == '' ?
                           null
                            :
                            <Anchor onClick={() => {setDropdownFiltriProd('');sessionStorage.removeItem("prodFilter");sessionStorage.removeItem("skuFilter")}} label="Rimuovi Filtro" />
                          }
                        </Box>
                        :
                        null
                        }
                      </Box>
                      <Box margin='xxsmall' alignSelf="start" direction='row' overflow={{ horizontal: 'hidden' }} style={{marginLeft:'10px'}}>
                        {filtroData ?
                        <>
                        <Box margin='xxsmall'>
                          <Text>Seleziona la data (da): </Text>
                          <DateInput
                            format="dd/mm/yyyy"
                            value={newStartFilter}
                            onChange={({ value }) => {setNewStartFilter(moment(new Date(value)).format('YYYY-MM-DD'))}}
                            calendarProps={{ size: "small", locale: "it-IT", daysOfWeek: true, firstDayOfWeek: 1 }}
                          />
                        </Box>
                        <Box margin='xxsmall'>
                          <Text >Seleziona la data (a): </Text>
                          <DateInput
                            format="dd/mm/yyyy"
                            value={newEndFilter}
                            onChange={({ value }) => {setNewEndFilter(moment(new Date(value)).format('YYYY-MM-DD'))}}
                            calendarProps={{ size: "small", locale: "it-IT", daysOfWeek: true, firstDayOfWeek: 1 }}
                          />
                        </Box>
                        </>
                        :
                        null
                        }
                        {filtroStato ?
                        <Box margin='xxsmall'>
                          <Text>Seleziona lo stato dell'attività: </Text>
                          <Select
                            options={[...new Set(attivita.sort(function(a, b) { return a.Approvata.localeCompare(b.Approvata)}).map((attivita)=>attivita.Approvata=="si"?"Terminata":attivita.Approvata=="partita"?"In corso":attivita.Approvata==="manuale"?"Chiusura manuale":"Chiusura giornaliera"))]}
                            value={dropdownFiltriStato}
                            onChange={({ option }) => {setDropdownFiltriStato(option);sessionStorage.setItem("statoFilter",option)}}
                          />
                          { dropdownFiltriStato == '' ?
                           null
                            :
                            <Anchor onClick={() => {setDropdownFiltriStato('');sessionStorage.removeItem("stato")}} label="Rimuovi Filtro" />
                          }
                        </Box>
                        :
                        null
                        }
                      </Box>
                      <Box width="100vw"  alignSelf="center" direction='row' overflow={{ horizontal: 'hidden' }}>
                        {
                        /**
                         * Modifica dev-mattia-mora
                         */
                        !boolScadenza?
                        <RBCalendar
                          selectable={visualization!=='day'?false:true}
                          popup
                          backgroundColor="#0350281A"
                          formats={formats}
                          localizer={localizer}
                          date={visualizationDate}
                          defaultView={visualization}
                          onNavigate={date => handleDateView(date)}
                          onView={view => handleView(view)}
                          messages={messages}
                          doShowMoreDrillDown={false}
                          onSelectSlot={handleSelectSlot}
                          step={30}
                          views={['day', 'week', 'month', 'agenda']}
                          events={events}
                          style={{ width: "100%", height: "80vh", backgroundColor: 'white' }}
                          resources={resourceMap}
                          resourceIdAccessor="resourceId"
                          resourceTitleAccessor="resourceTitle"
                          min={moment('5:00am', 'h:mma').toDate()}
                          max={moment('9:00pm', 'h:mma').toDate()}
                          components={{
                            event: Event,
                            agenda: {event: Event2},
                            month: {event: Event2}
                          }}
                          eventPropGetter={event => ({
                            style: {
                              backgroundColor: event.approvata == 'si' ? '#66FF66' : (event.approvata == 'no' ? '#FF6666' : (event.approvata == 'partita' ? '#FFFF66' : (event.approvata == 'manuale' ? 'rgb(34, 119, 178)' : (event.approvata == 'chiusura' ? '#CC0099' : '#E0E0E0')))),
                              color: 'black'
                            },
                          })}
                        />
                   
                        :
                        <RBCalendar
                        popup
                        formats={formats}
                        localizer={localizer}
                        date={visualizationDate}
                        defaultView={visualization}
                        messages={messages}
                        doShowMoreDrillDown={false}
                        step={30}
                        views={['day', 'week', 'month', 'agenda']}
                        events={events}
                        style={{ width: "100%", height: "80vh", backgroundColor: 'white' }}
                        resources={resourceMap}
                        resourceIdAccessor="resourceId"
                        resourceTitleAccessor="resourceTitle"
                        min={moment('5:00am', 'h:mma').toDate()}
                        max={moment('9:00pm', 'h:mma').toDate()}
                        components={{
                          event: Event,
                          agenda: {event: Event2},
                          month: {event: Event2}
                        }}
                        eventPropGetter={event => ({
                          style: {
                            backgroundColor: event.approvata == 'si' ? '#66FF66' : (event.approvata == 'no' ? '#FF6666' : (event.approvata == 'partita' ? '#FFFF66' : (event.approvata == 'manuale' ? 'rgb(34, 119, 178)' : (event.approvata == 'chiusura' ? '#CC0099' : '#E0E0E0')))),
                            color: 'black'
                          },
                        })}
                      />
                    }
                    </Box> 
                    </Tab>
                    <Tab title="Tabella">
                      <Box direction='row' flex="grow" overflow={{ horizontal: 'hidden' }}>
                      <Box gap="medium" direction="row" margin='small' alignSelf="end" style={{position:'fixed',bottom:'5%',zIndex:'5',right:'5%'}}>
                          {!boolScadenza? 
                            <Box alignSelf="end" id="tooltip">
                              <Button
                                icon={<Add color="white"/>}
                                onClick={() => history.push('/attivitaDettaglio')}
                                style={{fontSize:'28px',background:'#8FBCA7', padding:'10px',color:'white',fontWeight:'bold',textTransform: 'uppercase'}}>
                              </Button>
                              <span id="tooltiptext">Crea Attività</span>
                            </Box>
                            :
                            null
                            }
                         <>
                          <Box alignSelf="end" id="tooltip">
                            
                            {ruoli[0] !== undefined ?
                              (ruoli[0].Ruolo == 'Admin' ?
                                  
                                  <ExcelFile element={<Button icon={<Download color="white"/>}  style={{fontSize:'28px',background:'#8FBCA7', padding:'10px',color:'white',fontWeight:'bold',textTransform: 'uppercase'}}/>} >
                                    <ExcelSheet data={attivita} name="Attività">
                                        <ExcelColumn label="Nome" value="Dipendente"
                                           value={(col) =>mappaNomeCognome.get(col.Matricola) !== undefined ? mappaNomeCognome.get(col.Matricola) : ""}/>
                                        <ExcelColumn label="Data" value="Data"/>
                                        <ExcelColumn label="Stato"
                                         value={(col) => col.Approvata == 'si' ? "Terminata" : (col.Approvata == 'partita' ? "In corso" : (col.Approvata == 'manuale' ? "Chiusa Manualmente" : "Pending"))}/>
                                        <ExcelColumn label="Durata Stimata (hh:mm)"
                                         value={(col) => col.Approvata == 'si' || col.Approvata == 'manuale' ? durataTrasformTable(Number(parseFloat(col.Durata).toFixed(0))) : col.Approvata==="chiusura"?durataTrasformTable(col.Durata*60): '00:00'}/>
                                        <ExcelColumn label="Inizio Attività"
                                         value={(col) => col.Approvata == 'si' || col.Approvata == 'manuale' ? col.Start : "Attività non iniziata"}/>
                                        <ExcelColumn label="Fine Attività"
                                         value={(col) => col.Approvata == 'si' || col.Approvata == 'manuale' ? col.End : "Attività non terminata"}/>
                                        <ExcelColumn label="Attività" value="Lavoro"/>
                                        <ExcelColumn label="Macchinario" value="Macchina"/>
                                        <ExcelColumn label="Area di Lavoro" value="Zona"/>
                                        <ExcelColumn label="Zona" value="ZonaFilter"/>
                                        <ExcelColumn label="Cantiere" value="Cantiere"/>
                                        <ExcelColumn label="Prodotto" value="Prodotto"/>
                                        <ExcelColumn label="Nota" value="Messaggio"/>
                                        <ExcelColumn label="Lavoro" value="Lavoro"/>
                                        <ExcelColumn label="Ordinaria/Straordinaria"
                                         value={(col) => col.Ordinaria == 'Ordinaria' ? "Ordinaria" : (col.Approvata == 'Straordinaria' ? "Straordinaria" : "Ordinaria")}/>
                                        <ExcelColumn label="Durata Consuntivo (hh:mm)"
                                         value={(col) => col.Approvata == 'si' || col.Approvata == 'manuale' ? durataTrasformTable(Number(parseFloat(((Date.parse(col.End) - Date.parse(col.Start))/1000/60), 10).toFixed(0))) : '00:00'}/>
                                        <ExcelColumn label="Costo Esterno Dipendente" 
                                          value={(col)=>mappaCostiEst.get(col.Matricola)}  //Aggiunta dev-mattia-mora
                                        />
                                        <ExcelColumn label="Costo Interno Dipendente" 
                                          value={(col)=>mappaCostiInt.get(col.Matricola)} //Aggiunta dev-mattia-mora
                                        /> 
                                        <ExcelColumn label="Costo Orario Macchinario" 
                                          value={(col)=>mappaCostiMac.get(col.Macchina)} //Aggiunta dev-mattia-mora
                                        />
                                        <ExcelColumn label="Percentuale completamento"    //Aggiunta dev-mattia-mora
                                          value={(col)=>col.percentuale+"%"}/>
                                    </ExcelSheet>
                                  </ExcelFile>
                              :
                                <ExcelFile element={<Button icon={<Download color="white"/>}  style={{fontSize:'28px',background:'#8FBCA7', padding:'10px',color:'white',fontWeight:'bold',textTransform: 'uppercase'}}/>} >
                                    <ExcelSheet data={attivita} name="Attività">
                                        <ExcelColumn label="Nome" value="Dipendente"
                                           value={(col) =>mappaNomeCognome.get(col.Matricola) !== undefined ? mappaNomeCognome.get(col.Matricola) : ""}/>
                                        <ExcelColumn label="Data" value="Data"/>
                                        <ExcelColumn label="Stato"
                                         value={(col) => col.Approvata == 'si' ? "Terminata" : (col.Approvata == 'partita' ? "In corso" : (col.Approvata == 'manuale' ? "Chiusa Manualmente" : "Pending"))}/>
                                        <ExcelColumn label="Durata Stimata (hh:mm)"
                                         value={(col) => col.Approvata == 'si' || col.Approvata == 'manuale' ? durataTrasformTable(Number(parseFloat(col.Durata).toFixed(0))) : col.Approvata==="chiusura"?durataTrasformTable(col.Durata*60): '00:00'}/>
                                        <ExcelColumn label="Inizio Attività"
                                         value={(col) => col.Approvata == 'si' || col.Approvata == 'manuale' ? col.Start : "Attività non iniziata"}/>
                                        <ExcelColumn label="Fine Attività"
                                         value={(col) => col.Approvata == 'si' || col.Approvata == 'manuale' ? col.End : "Attività non terminata"}/>
                                        <ExcelColumn label="Attività" value="Lavoro"/>
                                        <ExcelColumn label="Macchinario" value="Macchina"/>
                                        <ExcelColumn label="Area di Lavoro" value="Zona"/>
                                        <ExcelColumn label="Zona" value="ZonaFilter"/>
                                        <ExcelColumn label="Cantiere" value="Cantiere"/>
                                        <ExcelColumn label="Prodotto" value="Prodotto"/>
                                        <ExcelColumn label="Nota" value="Messaggio"/>
                                        <ExcelColumn label="Lavoro" value="Lavoro"/>
                                        <ExcelColumn label="Ordinaria/Straordinaria"
                                         value={(col) => col.Ordinaria == 'Ordinaria' ? "Ordinaria" : (col.Approvata == 'Straordinaria' ? "Straordinaria" : "Ordinaria")}/>
                                        <ExcelColumn label="Durata Consuntivo (hh:mm)"
                                         value={(col) => col.Approvata == 'si' || col.Approvata == 'manuale' ? durataTrasformTable(Number(parseFloat(((Date.parse(col.End) - Date.parse(col.Start))/1000/60), 10).toFixed(0))) : '00:00'}/>
                                    </ExcelSheet>
                                  </ExcelFile>
                                )
                                :
                                null
                            }
                            <span id="tooltiptext">Download Excel</span>
                          </Box>
                          </>
                        <Box id="tooltip">
                        <DropButton
                            icon={<Filter color='white'/>}
                            style={{fontSize:'28px',background:'#8FBCA7', padding:'10px',color:'white',fontWeight:'bold',textTransform: 'uppercase'}}
                            dropAlign={{ bottom: 'bottom', right: 'left' }}
                            dropContent={
                              <>
                              <Box pad="small">
                                <CheckBox
                                  checked={filtroDip}
                                  label="Filtro Dipendente"
                                  onChange={(event) => handleSelectDipendente(event.target.checked)}
                                />
                              </Box>
                              <Box pad="small">
                                <CheckBox
                                  checked={filtroMac}
                                  label="Filtro Macchinario"
                                  onChange={(event) => handleSelectMacchinari(event.target.checked)}
                                />
                              </Box>
                              <Box pad="small">
                                <CheckBox
                                  checked={filtroLav}
                                  label="Filtro Lavoro"
                                  onChange={(event) => handleSelectLavori(event.target.checked)}
                                />
                              </Box>
                              <Box pad="small">
                                <CheckBox
                                  checked={filtroProd}
                                  label="Filtro Prodotto"
                                  onChange={(event) => handleSelectProdotti(event.target.checked)}
                                />
                              </Box>
                              <Box pad="small">
                                <CheckBox
                                  checked={filtroData}
                                  label="Filtro Data"
                                  onChange={(event) => handleSelectData(event.target.checked)}
                                />
                              </Box>
                              <Box pad="small">
                                <CheckBox
                                  checked={filtroStato}
                                  label="Filtro Stato"
                                  onChange={(event) => handleSelectStato(event.target.checked)}
                                />
                              </Box>
                              {
                                ruoli[0] !== undefined?
                              ruoli[0].Ruolo=='Admin'?
                              <>
                              <Box pad="small">
                                <CheckBox
                                  checked={filtroCantiere}
                                  label="Filtro Cantiere"
                                  onChange={(event) => handleSelectCantiere(event.target.checked)}
                                />
                              </Box>
                              </>
                              :
                              null
                              :
                              null
                              }
                              <Box pad="small">
                                <CheckBox
                                  checked={filtroZona}
                                  label="Filtro Zona"
                                  onChange={(event) => handleSelectZona(event.target.checked)}
                                />
                              </Box>
                              <Box pad="small">
                                <CheckBox
                                  checked={filtroArea}
                                  label="Filtro Area di Lavoro"
                                  onChange={(event) => handleSelectAdl(event.target.checked)}
                                />
                              </Box>
                              </>
                            }
                          />
                          <span id="tooltiptext">Filtri</span>
                          </Box>
                          </Box>
                        </Box>
                      <Box alignSelf="start" direction='row' overflow={{ horizontal: 'hidden' }} style={{marginLeft:'10px'}}>
                      {
                            filtroCantiere?
                            <Box margin='xxsmall'>
                            <Text>Seleziona il Cantiere: </Text>
                            <Select
                              options={[...new Set(attivita.sort(function(a, b) { return a.Cantiere.localeCompare(b.Cantiere) }).map(attivita => (attivita.Cantiere)))]}
                              value={dropdownFiltriCant}
                              onChange={({ option }) =>  {setDropdownFiltriCant(option);sessionStorage.setItem("cantFilter",option)}}
                            />
                            { dropdownFiltriCant== '' ?
                             null
                              :
                              <Anchor onClick={() => {setDropdownFiltriCant('');sessionStorage.removeItem("cantFilter")}} label="Rimuovi Filtro" />
                            }
                          </Box>
                          : null
                          }
                           {
                            filtroZona?
                            <Box margin='xxsmall'>
                            <Text>Seleziona la zona: </Text>
                            <Select
                              options={[...new Set(attivita.sort(function(a, b) { return a.ZonaFilter.localeCompare(b.ZonaFilter) }).map(attivita => (attivita.ZonaFilter)))]}
                              value={dropdownFiltriZona}
                              onChange={({ option }) => {setDropdownFiltriZona(option);sessionStorage.setItem("zonaFilter",option)}}
                            />
                            { dropdownFiltriZona== '' ?
                             null
                              :
                              <Anchor onClick={() => {setDropdownFiltriZona('');sessionStorage.removeItem("zonaFilter")}} label="Rimuovi Filtro" />
                            }
                          </Box>
                          : null
                          }
                         {
                        filtroArea ?
                        <Box margin='xxsmall'>
                          <Text>Seleziona Adl: </Text>
                          <Select
                            options={[...new Set(attivita.sort(function(a, b) { return a.Zona.localeCompare(b.Zona) }).map(attivita => (attivita.Zona)))]}
                            value={dropdownFiltriAdl}
                            onChange={({ option }) => {setdropdownFiltriAdl(option);;sessionStorage.setItem("adlFilter",option)}}
                          />
                          { dropdownFiltriAdl == '' ?
                           null
                            :
                            <Anchor onClick={() => {setdropdownFiltriAdl('');sessionStorage.removeItem("adlFilter")}} label="Rimuovi Filtro" />
                          }
                        </Box>
                        :
                        null
                        }
                        { filtroDip ?
                        <Box margin='xxsmall'>
                          <Text >Seleziona l'operatore: </Text>
                          <Select
                            options={[...new Set(attivita.sort(function(a, b) { return a.Dipendente.localeCompare(b.Dipendente) }).filter(
                              function(a){
                                if(elencoMatricoleTabella.indexOf(a.Matricola)===-1){ //Logica per la gestione dei dipendenti, filtra dalle attività presenti
                                    elencoMatricoleTabella.push(a.Matricola);        //in base al numero di matricola in modo che non ci siano ripetizioni nel Select
                                    return true;
                                  }
                                  return false;

                            }).map((item)=> mappaNomeCognome.get(item.Matricola)))]}
                            value={dropdownFiltriDip}
                            onChange={(option ) => onChangedSetDipTabella(option)}
                          />
                          { dropdownFiltriDip == '' ?
                           null
                            :
                            <Anchor onClick={() => {setDropdownFiltriDip('');sessionStorage.removeItem("dipFilter");sessionStorage.removeItem("matricolaFilter")}} label="Rimuovi Filtro" />
                          }
                        </Box>
                        :
                        null
                        }
                        </Box>
                        <Box alignSelf="start" direction='row' overflow={{ horizontal: 'hidden' }} style={{marginLeft:'10px'}}>
                        { filtroMac ?
                        <Box margin='xxsmall'>
                          <Text >Seleziona il Macchinario: </Text>
                          <Select
                            options={[...new Set(attivita.sort(function(a, b) { return a.Macchina.localeCompare(b.Macchina)}).filter(
                              function(a){
                                
                                if((typeof a.Telaio)=="string" && elencoMacchinariTabella.indexOf(a.Telaio)===-1){ //Logica per la gestione dei macchinari, filtra dalle attività present  
                                  elencoMacchinariTabella.push(a.Telaio);         //in base al numero di telaio in modo che non ci siano ripetizioni nel Select
                                    return true;
                                  }
                                  return false;

                            }).map((item)=>item.Macchina))]}
                            value={dropdownFiltriMac}
                            onChange={( option ) => onChangedSetMacTabella(option)}
                          />
                          { dropdownFiltriMac == '' ?
                           null
                            :
                            <Anchor onClick={() => {setDropdownFiltriMac('');sessionStorage.removeItem('macFilter');sessionStorage.removeItem('telaioFilter')}} label="Rimuovi Filtro" />
                          }
                        </Box>
                        :
                        null
                        }
                        { filtroLav ?
                        <Box margin='xxsmall'>
                          <Text>Seleziona il Lavoro: </Text>
                          <Select
                            options={[...new Set(attivita.sort(function(a, b) { return a.Lavoro.localeCompare(b.Lavoro) }).map(attivita => (attivita.Lavoro)))]}
                            value={dropdownFiltriLav}
                            onChange={({ option }) =>{setDropdownFiltriLav(option);;sessionStorage.setItem("lavFilter",option)}}
                          />
                          { dropdownFiltriLav == '' ?
                           null
                            :
                            <Anchor onClick={() => {setDropdownFiltriLav('');sessionStorage.removeItem('lavFilter')}} label="Rimuovi Filtro" />
                          }
                        </Box>
                        :
                        null
                        }
                        { filtroProd ?
                        <Box margin='xxsmall'>
                          <Text>Seleziona il Prodotto: </Text>
                          <Select
                            options={[...new Set(attivita.sort(function(a, b) { return a.Prodotto.localeCompare(b.Prodotto) }).filter(
                              function(a){
                                if(elencoSkuTabella.indexOf(a.Sku)===-1){ //Logica per la gestione dei prodotti, filtra dalle attività presenti
                                  elencoSkuTabella.push(a.Sku);          //in base al numero Sku in modo che non ci siano ripetizioni nel Select
                                  return true;
                                  }
                                  return false;
                                }
                            ).map((item)=>item.Prodotto))]}
                            value={dropdownFiltriProd}
                            onChange={( option ) => onChangedSetSkuTabella(option)}
                          />
                          { dropdownFiltriProd == '' ?
                           null
                            :
                            <Anchor onClick={() => {setDropdownFiltriProd('');sessionStorage.removeItem("prodFilter");sessionStorage.removeItem('skuFilter')}} label="Rimuovi Filtro" />
                          }
                        </Box>
                        :
                        null
                        }       
                      </Box>
                      <Box margin='xxsmall' alignSelf="start" direction='row' overflow={{ horizontal: 'hidden' }} style={{marginLeft:'10px'}}>
                        {filtroData ?
                        <>
                        <Box margin='xxsmall'>
                          <Text>Seleziona la data (da): </Text>
                          <DateInput
                            format="dd/mm/yyyy"
                            value={newStartFilter}
                            onChange={({ value }) => {setNewStartFilter(value.split('T')[0])}}
                            calendarProps={{ size: "small", locale: "it-IT", daysOfWeek: true, firstDayOfWeek: 1 }}
                          />
                        </Box>
                        <Box margin='xxsmall'>
                          <Text >Seleziona la data (a): </Text>
                          <DateInput
                            format="dd/mm/yyyy"
                            value={newEndFilter}
                            onChange={({ value }) => {setNewEndFilter(value.split('T')[0])}}
                            calendarProps={{ size: "small", locale: "it-IT", daysOfWeek: true, firstDayOfWeek: 1 }}
                          />
                        </Box>
                        </>
                        :
                        null
                        }
                        {filtroStato ?
                        <Box margin='xxsmall'>
                          <Text>Seleziona lo stato dell'attività: </Text>
                          <Select
                            options={[...new Set(attivita.sort(function(a, b) { return a.Approvata.localeCompare(b.Approvata)}).map((attivita)=>attivita.Approvata=="si"?"Terminata":attivita.Approvata=="partita"?"In corso":attivita.Approvata==="manuale"?"Chiusura manuale":"Chiusura giornaliera"))]}
                            value={dropdownFiltriStato}
                            onChange={({ option }) => {setDropdownFiltriStato(option);sessionStorage.setItem("statoFilter",option)}}
                          />
                          { dropdownFiltriStato == '' ?
                           null
                            :
                            <Anchor onClick={() => {setDropdownFiltriStato('');sessionStorage.removeItem("statoFilter")}} label="Rimuovi Filtro" />
                          }
                        </Box>
                        :
                        null
                        }   
                        </Box>
                        
                      <Box direction='row' justify="center">
                        <Box round direction="row" width="100%" pad="small" style={{justifyContent:'space-around',alignItems:'center'}}>
                          <Box direction="column" style={{alignItems:'center'}}>
                            <Heading level="4" margin="none" size="small">
                                Durata Totale Effettiva
                              </Heading>
                              <Text size="22px" weight="bold">
                                {durataTrasform(parseFloat(TotalDurata).toFixed(0))}
                              </Text>
                          </Box>
                          <Box direction='row'>
                          <Box gap="medium"> 
                            
                       
                              <Button onClick={()=>{getDataExportCsv()}} 
                              icon={<LinkBottom color="white"/>}
                              label="Export CSV"
                                 style={{fontSize:'22px',background:'#006837',color:'white',fontWeight:'bold',textTransform: 'uppercase'}} />      
                            {
                              dataExport.length>0?
                                <CSVDownload   
                                  data={dataExport} 
                                  headers={headerExport}
                                  filename={"export.csv"}
                                > 
                                </CSVDownload>
                                
                              :
                              null
                            }
              
                                </Box> 
                            </Box>  
                          </Box>
                    </Box>
                      <Box flex="grow" style={{width:'100%',height:'67vh',overflow: 'auto'}}  >
                          <Table sortable>
                            <TableHeader  scrollable="true"  sort style={{fontWeight:'bold',color:'#006837'}}>
                              <TableRow>
                                {/*<TableCell scope="col" border="bottom">
                                  Stato
                                </TableCell>*/}
                                <TableCell scope="col" border="bottom">
                                  Stato
                                </TableCell>
                                <TableCell scope="col" border="bottom">
                                  Operatore
                                </TableCell>
                                <TableCell scope="col" border="bottom">
                                  Macchinario
                                </TableCell>
                                <TableCell scope="col" border="bottom">
                                  Lavoro
                                </TableCell>
                                <TableCell scope="col" border="bottom">
                                  Area di Lavoro
                                </TableCell>
                                <TableCell scope="col" border="bottom">
                                  Prodotto
                                </TableCell>
                                <TableCell scope="col" border="bottom">
                                  Data Del Lavoro (Stimato)
                                </TableCell>
                                <TableCell scope="col" border="bottom">
                                  Data Inizio Lavoro (Effettivo)
                                </TableCell>
                                <TableCell scope="col" border="bottom">
                                  Durata Stimata (hh:mm)
                                </TableCell>
                                <TableCell scope="col" border="bottom">
                                  Durata Consuntivo (hh:mm)
                                </TableCell>
                                {ruoli[0] !== undefined ?
                                      (ruoli[0].Ruolo == 'Admin' ?
                                      <>
                                        <TableCell scope="col" border="bottom">
                                          Indice Performance (%)
                                        </TableCell>
                                        <TableCell scope="col" border="bottom">
                                          Costo Totale Attività
                                        </TableCell>
                                      </>
                                      :
                                      null)
                                      :
                                      null
                                }
                                <TableCell scope="col" border="bottom">
                                  Ordinaria/
                                  Straordinaria
                                </TableCell>
                              </TableRow>
                            </TableHeader>
                            <TableBody>
                              {attivita.map(attivita => (
                                <AnagraficheAtt ruolo={ruoli[0]!==undefined?ruoli[0].Ruolo:""} attivita={attivita}/>
                              ))}
                            </TableBody>
                          </Table>
                        </Box>
                    </Tab>
                    {
                    /**
                     * Modifica dev-mattia-mora
                     */
                    !boolScadenza?  
                      null
                      :
                      <Box round="medium" background="#c51d34" pad="small" >
                      <Text>{ruoli[0].Ruolo == 'Admin_Cantiere' ?"CANTIERE SCADUTO":ruoli[0].Ruolo == 'Admin_Zona'?"ZONA SCADUTA":"CANTIERE O ZONA SCADUTA"}</Text>
                    </Box>}
                    
                  </Tabs>
               
            </Box>
          </Box>
        </Box>
        )}
      </ResponsiveContext.Consumer>
    </Grommet>
  );
}

export default App;