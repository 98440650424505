import { Avatar, Box, Card, CardContent, Grid, Typography } from '@mui/material';
import BlockIcon from '@mui/icons-material/Block';

export const StatoInterruttoreOlioIdr = (props) => (
  <Card
    sx={{ height: '100%' }}
    {...props}
  >
    <CardContent>
      <Grid
        container
        spacing={3}
        sx={{ justifyContent: 'space-between' }}
      >
        <Grid item>
          <Typography
            color="textSecondary"
            gutterBottom
            variant="overline"
            fontSize="11px"
          >
            Stato interruttore olio idraulico
          </Typography>
          <Typography
            color="textPrimary"
            variant="h5"
            fontSize={(props.statoInterruttoreOlioIdr==1)? "15px" : "20px"}
            
          >
            {props.statoInterruttoreOlioIdr!==-1?
            props.statoInterruttoreOlioIdr == 0 ?  "Nessuna restrizione" 
            :
            props.statoInterruttoreOlioIdr == 1 ? "Esiste una restrizione sul filtro dell'olio" 
            :
            props.statoInterruttoreOlioIdr == 2 ?  "Errore" 
            : 
            "Errore stato non disponibile"
          :
          "Dato non presente"} 
          </Typography>
        </Grid>
        <Grid item>
          <Avatar
            sx={{
              backgroundColor: '#d50000',
              height: 56,
              width: 56
            }}
          >
            <BlockIcon/>
          </Avatar>
        </Grid>
      </Grid>
      <Box
        sx={{
          pt: 2,
          display: 'flex',
          alignItems: 'center'
        }}
      >
      </Box>
    </CardContent>
  </Card>
);
