import firebase from 'firebase';

// Add the Firebase products that you want to use
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/messaging';

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
  apiKey: "AIzaSyACdCazXkw1sBqvo1W7d066eLqk7Crzr0A",
  authDomain: "arzaga-env.firebaseapp.com",
  projectId: "arzaga-env",
  storageBucket: "arzaga-env.appspot.com",
  messagingSenderId: "690340847895",
  appId: "1:690340847895:web:ca93b736ffb0d53fce9752"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export default firebase;