import React, { useState, useEffect } from "react";
import { Box, DateInput, Grommet, Button, TextInput, Table, TableHeader, TableBody, TableRow, TableCell,Layer } from 'grommet';
import { Edit, Checkmark, FormClose, Trash } from "grommet-icons";
import firebase from '../firebase';
import AddLav from "./AddProd";

export default function AnagraficheProd({ prodotto }) {

const [modify, setModify] = useState(false);

/**
 * Funzione che cancella un prodotto
 */
  const onDelete = () => {
	var result = window.confirm("Sei sicuro di voler eliminare il prodotto?");
	if(result){
		const db = firebase.firestore();
		db.collection('Prodotti').doc(prodotto.id).delete();
		setModify(false);
	}
  }

  /**
   * Funzione che chiude il layer di modifica anagrafica
   */
  const onEscLayer=()=>{
	  setModify(false);
  }

  const userLogged = JSON.parse(sessionStorage.getItem("firebase:authUser:AIzaSyACdCazXkw1sBqvo1W7d066eLqk7Crzr0A:[DEFAULT]"));

return (
<>
	<TableRow id={prodotto.id}>
		<TableCell scope="row">
				<>
				<strong>{prodotto.Nome}</strong>
				</>
			
		</TableCell>
		<TableCell>
				<>
				<strong>{prodotto.Produttore}</strong>
				</>
			
		</TableCell>
		<TableCell>
				<>
				<strong>{prodotto.Qta}</strong>
				</>
			
		</TableCell>
		<TableCell>
				<>
				<strong>{prodotto.Um}</strong>
				</>
			
		</TableCell>
		<TableCell>
				<>
				<strong>{prodotto.Valore}</strong>
				</>
			
		</TableCell>
		<TableCell>
				<>
				<strong>{prodotto.Sku}</strong>
				</>
			
		</TableCell>
		<TableCell>
			{ modify ?
				<>
				<Layer full={window.screen.width<1100?true:false} onClickOutside={onEscLayer}>
					<Box height="100%" round="medium" pad="15px" background="#E5EDE9">
						<Button  alignSelf="end" icon={<FormClose color="grey" size="35px" onClick={onEscLayer}/>}></Button>
						<AddLav prodotto={prodotto} onEscLayer={onEscLayer} onDelete={onDelete}/>
					</Box>
				</Layer>
				</>
				:
				<>
				<Button icon=<Edit /> onClick={() => setModify(true)} label="" />
				</>
			}
		</TableCell>
	
	</TableRow>
</>
)

}