import React, { useState, useEffect } from "react";
import { Box, Text, Heading, Form, FormField, DateInput, TextInput, Button, Select, Anchor, MaskedInput, CheckBox,TextArea } from "grommet";
import { Table as TableIcon, LinkPrevious } from "grommet-icons";
import firebase from '../firebase';
import { useHistory } from "react-router-dom";
import moment from 'moment';
import { FullscreenExit } from "@material-ui/icons";


export default function AddDip() {
  
  const history = useHistory();

  /**
   * Funzione che crea un attività con i dati inseriti dall'utente
   */
  const onAssignActivity = () => {
    const db = firebase.firestore();
    if (dropdownLavSel == '' || dropdownMacSel == '' || newDataLavoro == '' || newEndLavoro == '' || newOraInizio == '' || newOraFine == '' || dropdownZonaSel == '' || newCantiere == '' || newZona == '') {
      alert('Hai dimenticato di selezionare una voce tra: Lavoro, Area di Lavoro, Zona, Cantiere, Macchinario, Data Inizio, Data Fine, o Durata Attività!')
    } else {
      const DateStart = new Date(Number(newDataLavoro.substring(0, 4)), Number(newDataLavoro.substring(5, 7))-1, Number(newDataLavoro.substring(8, 10)), newOraInizio.split(':')[0], newOraInizio.split(':')[1], 0).toISOString();
      
      const DateEnd = new Date(Number(newEndLavoro.substring(0, 4)), Number(newEndLavoro.substring(5, 7))-1, Number(newEndLavoro.substring(8, 10)), newOraFine.split(':')[0], newOraFine.split(':')[1], 0).toISOString();
      console.log(DateStart);
      console.log(DateEnd);
      console.log(dropdownZonaSel.Adl);
      console.log(dropdownDipSel);
      console.log(dropdownLavSel);
      console.log(dropdownMacSel);
      console.log(dropdownProdSel);
      console.log(newMessaggio);
      console.log(newDataLavoro);
      console.log(Number(parseFloat(((Date.parse(DateEnd) - Date.parse(DateStart))/1000/60), 10).toFixed(2)));
      console.log(dropdownOrdinariaSel);
      console.log(newCantiere.Cantiere);
      console.log(newZona.Zona);
      console.log(matricola);
      console.log(telaioMacchinario);
      console.log(sku);
      db.collection('Attivita').add({ Approvata: (manuale === true ? 'manuale' : ''), Start: DateStart, End: DateEnd, Zona: dropdownZonaSel.Adl, Dipendente: dropdownDipSel, Lavoro: dropdownLavSel, Macchina: dropdownMacSel, Prodotto: dropdownProdSel, Data: newDataLavoro, Messaggio: newMessaggio, Durata: Number(parseFloat(((Date.parse(DateEnd) - Date.parse(DateStart))/1000/60), 10).toFixed(2)), Ordinaria: dropdownOrdinariaSel, Cantiere: newCantiere.Cantiere, ZonaFilter: newZona.Zona, Matricola: matricola, Telaio: telaioMacchinario, Sku: sku })
      .then((res)=>{
        alert('Attività Creata!');
        goBack();
      })
    }
  }
  const [newMessaggio, setNewMessaggio] = useState("");
  const [manuale, setManuale] = useState(false);

  const dip = firebase.firestore().collection('Dipendenti');
  const [dipendenti, setDipendenti] = useState([]);
  let [dropdownDipSel, setDropdownDipSel] = useState('');

  //Usata nel caso in cui ci sia stata una selezione da calendario
  if (sessionStorage.getItem("Dipendente") !== null) {
    dropdownDipSel = sessionStorage.getItem("Dipendente") !== 'NON_ASSEGNATA' ? sessionStorage.getItem("Dipendente") : '';
  }

  let [matricola,setMatricola]=React.useState();
  const [telaioMacchinario,setTelaioMacchinario]=React.useState('');
  const [sku,setSku]=React.useState('');

  //Usata nel caso in cui ci sia stata una selezione da calendario
  if(sessionStorage.getItem('Matricola')!==null){
    matricola=sessionStorage.getItem('Matricola');
  }

  const mac = firebase.firestore().collection('Macchinari');
  const [macchine, setMacchinari] = useState([]);
  const [dropdownMacSel, setDropdownMacSel] = useState('');

  const lav = firebase.firestore().collection('Lavori');
  const [lavori, setLavori] = useState([]);
  const [dropdownLavSel, setDropdownLavSel] = useState('');

  let [newOraInizio, setNewOraInizio] = useState('');

  //Usata nel caso in cui ci sia stata una selezione da calendario
  if (sessionStorage.getItem("StartDateSelected") !== null) {
    newOraInizio = moment.parseZone(new Date(sessionStorage.getItem("StartDateSelected"))).local().format('HH:mm');
    //
  }

  let [newOraFine, setNewOraFine] = useState('');

  //Usata nel caso in cui ci sia stata una selezione da calendario
  if (sessionStorage.getItem("EndDateSelected") !== null) {
    newOraFine = moment.parseZone(new Date(sessionStorage.getItem("EndDateSelected"))).local().format('HH:mm');
    //
  }

  let [newDataLavoro, setNewDataLavoro] = useState((new Date()).toISOString().split('T')[0]);

  //Usata nel caso in cui ci sia stata una selezione da calendario
  if (sessionStorage.getItem("StartDateSelected") !== null) {
    newDataLavoro = moment.parseZone(new Date(sessionStorage.getItem("StartDateSelected"))).local().format('YYYY-MM-DD');
  } else {
    newDataLavoro = (new Date()).toISOString().split('T')[0];
  }

  let [newEndLavoro, setNewEndLavoro] = useState((new Date()).toISOString().split('T')[0]);

  //Usata nel caso in cui ci sia stata una selezione da calendario
  if (sessionStorage.getItem("EndDateSelected") !== null) {
    newEndLavoro = moment.parseZone(new Date(sessionStorage.getItem("EndDateSelected"))).local().format('YYYY-MM-DD');
  } else {
    newEndLavoro = (new Date()).toISOString();
  }

  const [newDurataLav, setNewDurataLav] = useState('');

  const prod = firebase.firestore().collection('Prodotti');
  const [prodotti, setProdotti] = useState([]);
  const [dropdownProdSel, setDropdownProdSel] = useState('');

  const aree = firebase.firestore().collection('Aree');
  const [dropdownZonaSel, setDropdownZonaSel] = useState([]);
  const [zona, setZona] = useState([]);

  const [dropdownOrdinariaSel, setDropdownOrdinariaSel] = useState('Ordinaria');
  const [ordinaria, setOrdinaria] = useState(['Ordinaria', 'Straordinaria']);

  const [elencoLavori, setElencoLavori] = useState('');

  /*CHANGE CANTIERE*/
  const [newCantiere, setNewCantiere] = useState('');

  const [newZona, setNewZona] = useState('');

  const [cantieri, setCantieri] = useState([]);
  const [cantieriZona, setCantieriZona] = useState([]);
  const cant = firebase.firestore().collection('Aree');


  const [timeError,setTimeError]=React.useState(false);

  /**
   * Usato per controllare se l'ora di inizio attività sia effettivamente precedente all'ora di fine attività
   * In caso di errore blocca la creazione di attività negative
   */
   React.useEffect(()=>{
    var oraInizio=parseInt(newOraInizio.split(":")[0])*60;
    var oraFine=parseInt(newOraFine.split(":")[0])*60;
    var minInizio=parseInt(newOraInizio.split(":")[1]);
    var minFine=parseInt(newOraFine.split(":")[1]);
    var sommaInizio=oraInizio+minInizio;
    var sommaFine=oraFine+minFine;
    if(oraInizio===0 && minInizio===0 && oraFine===0 && minFine===0){
      setTimeError(false);
    }else if(sommaInizio>=sommaFine){
      setTimeError(true);
    }else{
      setTimeError(false);
    }  
  },[newOraInizio,newOraFine])

  /**
   * Funzione che in base al ruolo dell'utente loggato e al cantiere/zona selezionato/a rileva i cantieri 
   * da mostrare nella select
   * Controlla inoltre eventuali Cantieri scaduti e non li mostra
   */
  function getCantieri() {
    if (sessionStorage.getItem('Role') == 'Admin_Cantiere' && sessionStorage.getItem('Cantiere') !== null) {
      cant.where('Nome', '==', sessionStorage.getItem('Cantiere')).onSnapshot((querySnapshot) => {
        const items = [];
        querySnapshot.forEach((doc) => {
           /**
           * Modifica dev-mattia-mora
           */
            var dataOdierna=moment(new Date()).toDate();
            //moment() non accetta date in formato dd:mm:yyyy, devo convertirle manualmente in mm:dd:yyyy
             var dataFineToConvert=doc.data().FineCantiere;
             var day=dataFineToConvert.substring(0,2);
             var month=dataFineToConvert.substring(3,5);
             dataFineToConvert=replaceRange(dataFineToConvert, 0, 2, month);
             dataFineToConvert=replaceRange(dataFineToConvert, 3, 5, day);
             var dataFine=moment(dataFineToConvert).toDate();
             if(dataOdierna.getFullYear() < dataFine.getFullYear()){
               items.push( { ... doc.data(), id: doc.id } );
             }else if(dataOdierna.getFullYear() == dataFine.getFullYear() && dataOdierna.getMonth() < dataFine.getMonth()){
               items.push( { ... doc.data(), id: doc.id } );
             }else if(dataOdierna.getMonth() == dataFine.getMonth() && dataOdierna.getDate() < dataFine.getDate()){
               items.push( { ... doc.data(), id: doc.id } );
             }else if(dataOdierna.getMonth() == dataFine.getMonth() && dataOdierna.getDate() == dataFine.getDate()){

             }else{

             }
        });

        
        setCantieri(items);
        var cantieriFilter=[... new Set(cantieri.sort(function(a, b) { return a.Nome.localeCompare(b.Nome) }).map(cantiere => (cantiere.Nome)))]

        if(cantieriFilter.length==0){


          setNewCantiere({Cantiere: items[0].Nome});
        }
      });
    } else if (sessionStorage.getItem('Role') == 'Admin_Zona' && sessionStorage.getItem('Cantiere') !== null)
    {
      cant.where('Zona', '==', sessionStorage.getItem('Cantiere')).onSnapshot((querySnapshot) => {
        const items = [];
        querySnapshot.forEach((doc) => {
           /**
           * Modifica dev-mattia-mora
           */
            var dataOdierna=moment(new Date()).toDate();
            //moment() non accetta date in formato dd:mm:yyyy, devo convertirle manualmente in mm:dd:yyyy
            var dataFineToConvert=doc.data().FineCantiere;
            var day=dataFineToConvert.substring(0,2);
            var month=dataFineToConvert.substring(3,5);
            dataFineToConvert=replaceRange(dataFineToConvert, 0, 2, month);
            dataFineToConvert=replaceRange(dataFineToConvert, 3, 5, day);
            var dataFine=moment(dataFineToConvert).toDate();
            if(dataOdierna.getFullYear() < dataFine.getFullYear()){
              items.push( { ... doc.data(), id: doc.id } );
            }else if(dataOdierna.getFullYear() == dataFine.getFullYear() && dataOdierna.getMonth() < dataFine.getMonth()){
              items.push( { ... doc.data(), id: doc.id } );
            }else if(dataOdierna.getMonth() == dataFine.getMonth() && dataOdierna.getDate() < dataFine.getDate()){
              items.push( { ... doc.data(), id: doc.id } );
            }else if(dataOdierna.getMonth() == dataFine.getMonth() && dataOdierna.getDate() == dataFine.getDate()){

            }else{

            }
        });
        setCantieri(items);
        var cantieriFilter=[... new Set(cantieri.sort(function(a, b) { return a.Nome.localeCompare(b.Nome) }).map(cantiere => (cantiere.Nome)))]
        if(cantieriFilter.length==0){
          setNewCantiere({Cantiere: items[0].Nome});
        }
      });
    } else {
      cant.onSnapshot((querySnapshot) => {
        const items = [];
        querySnapshot.forEach((doc) => {
           /**
           * Modifica dev-mattia-mora
           */
            var dataOdierna=moment(new Date()).toDate();
            //moment() non accetta date in formato dd:mm:yyyy, devo convertirle manualmente in mm:dd:yyyy
            var dataFineToConvert=doc.data().FineCantiere;
            var day=dataFineToConvert.substring(0,2);
            var month=dataFineToConvert.substring(3,5);
            dataFineToConvert=replaceRange(dataFineToConvert, 0, 2, month);
            dataFineToConvert=replaceRange(dataFineToConvert, 3, 5, day);
            var dataFine=moment(dataFineToConvert).toDate();
            if(dataOdierna.getFullYear() < dataFine.getFullYear()){
              items.push( { ... doc.data(), id: doc.id } );
            }else if(dataOdierna.getFullYear() == dataFine.getFullYear() && dataOdierna.getMonth() < dataFine.getMonth()){
              items.push( { ... doc.data(), id: doc.id } );
            }else if(dataOdierna.getMonth() == dataFine.getMonth() && dataOdierna.getDate() < dataFine.getDate()){
              items.push( { ... doc.data(), id: doc.id } );
            }else if(dataOdierna.getMonth() == dataFine.getMonth() && dataOdierna.getDate() == dataFine.getDate()){

            }else{

            }
        });
        setCantieri(items);
        var cantieriFilter=[... new Set(cantieri.sort(function(a, b) { return a.Nome.localeCompare(b.Nome) }).map(cantiere => (cantiere.Nome)))]
        if(cantieriFilter.length==0){
          setNewCantiere({Cantiere: items[0].Nome});
        }
      });
    }
  }

  /** */
  function replaceRange(s, start, end, substitute) {
    return s.substring(0, start) + substitute + s.substring(end);
  }

   /**
   * Funzione che in base al ruolo dell'utente loggato e al cantiere/zona selezionato/a rileva le zone
   * da mostrare nella select
   * Controlla inoltre eventuali Zone scadute e non le mostra
   */
  function getCantieriZona() {
    if (sessionStorage.getItem('Role') == 'Admin_Cantiere' && sessionStorage.getItem('Cantiere') !== null) {
      cant.where('Nome', '==', sessionStorage.getItem('Cantiere')).onSnapshot((querySnapshot) => {
        const items = [];
        querySnapshot.forEach((doc) => {
           /**
           * Modifica dev-mattia-mora
           */
            var dataOdierna=moment(new Date()).toDate();
            //moment() non accetta date in formato dd:mm:yyyy, devo convertirle manualmente in mm:dd:yyyy
            var dataFineToConvert=doc.data().FineZona;
            var day=dataFineToConvert.substring(0,2);
            var month=dataFineToConvert.substring(3,5);
            dataFineToConvert=replaceRange(dataFineToConvert, 0, 2, month);
            dataFineToConvert=replaceRange(dataFineToConvert, 3, 5, day);
            var dataFine=moment(dataFineToConvert).toDate();
            if(dataOdierna.getFullYear() < dataFine.getFullYear()){
              items.push( { ... doc.data(), id: doc.id } );
            }else if(dataOdierna.getFullYear() == dataFine.getFullYear() && dataOdierna.getMonth() < dataFine.getMonth()){
              items.push( { ... doc.data(), id: doc.id } );
            }else if(dataOdierna.getMonth() == dataFine.getMonth() && dataOdierna.getDate() < dataFine.getDate()){
              items.push( { ... doc.data(), id: doc.id } );
            }else if(dataOdierna.getMonth() == dataFine.getMonth() && dataOdierna.getDate() == dataFine.getDate()){

            }else{

            }
        });
        setCantieriZona(items);
        
      });
    } else if (sessionStorage.getItem('Role') == 'Admin_Zona' && sessionStorage.getItem('Cantiere') !== null)
    {
      cant.where('Zona', '==', sessionStorage.getItem('Cantiere')).onSnapshot((querySnapshot) => {
        const items = [];
        querySnapshot.forEach((doc) => {
           /**
           * Modifica dev-mattia-mora
           */
            var dataOdierna=moment(new Date()).toDate();
            //moment() non accetta date in formato dd:mm:yyyy, devo convertirle manualmente in mm:dd:yyyy
            var dataFineToConvert=doc.data().FineZona;
            var day=dataFineToConvert.substring(0,2);
            var month=dataFineToConvert.substring(3,5);
            dataFineToConvert=replaceRange(dataFineToConvert, 0, 2, month);
            dataFineToConvert=replaceRange(dataFineToConvert, 3, 5, day);
            var dataFine=moment(dataFineToConvert).toDate();
            if(dataOdierna.getFullYear() < dataFine.getFullYear()){
              items.push( { ... doc.data(), id: doc.id } );
            }else if(dataOdierna.getFullYear() == dataFine.getFullYear() && dataOdierna.getMonth() < dataFine.getMonth()){
              items.push( { ... doc.data(), id: doc.id } );
            }else if(dataOdierna.getMonth() == dataFine.getMonth() && dataOdierna.getDate() < dataFine.getDate()){
              items.push( { ... doc.data(), id: doc.id } );
            }else if(dataOdierna.getMonth() == dataFine.getMonth() && dataOdierna.getDate() == dataFine.getDate()){

            }else{

            }
        });
        setCantieriZona(items);
        
      });
    } else {
      cant.onSnapshot((querySnapshot) => {
        const items = [];
        querySnapshot.forEach((doc) => {
           /**
           * Modifica dev-mattia-mora
           */
            var dataOdierna=moment(new Date()).toDate();
            //moment() non accetta date in formato dd:mm:yyyy, devo convertirle manualmente in mm:dd:yyyy
            var dataFineToConvert=doc.data().FineZona;
            var day=dataFineToConvert.substring(0,2);
            var month=dataFineToConvert.substring(3,5);
            dataFineToConvert=replaceRange(dataFineToConvert, 0, 2, month);
            dataFineToConvert=replaceRange(dataFineToConvert, 3, 5, day);
            var dataFine=moment(dataFineToConvert).toDate();
            if(dataOdierna.getFullYear() < dataFine.getFullYear()){
              items.push( { ... doc.data(), id: doc.id } );
            }else if(dataOdierna.getFullYear() == dataFine.getFullYear() && dataOdierna.getMonth() < dataFine.getMonth()){
              items.push( { ... doc.data(), id: doc.id } );
            }else if(dataOdierna.getMonth() == dataFine.getMonth() && dataOdierna.getDate() < dataFine.getDate()){
              items.push( { ... doc.data(), id: doc.id } );
            }else if(dataOdierna.getMonth() == dataFine.getMonth() && dataOdierna.getDate() == dataFine.getDate()){

            }else{

            }
        });
        setCantieriZona(items);
        //

      });
    }
  }
  /*END CHANGE CANTIERE*/

   /**
   * Funzione che in base al ruolo dell'utente loggato e al cantiere/zona selezionato/a rileva i dipendenti
   * da mostrare nella select
   */
  function getDipendenti() {

    if (sessionStorage.getItem('Role') == 'Admin_Cantiere' && sessionStorage.getItem('Cantiere') !== null) {
      dip.where('Cantiere', 'array-contains', sessionStorage.getItem('Cantiere')).onSnapshot((querySnapshot) => {
          const items = [];
          querySnapshot.forEach((doc) => {
            if(doc.data().Matricola!=="000"){
              items.push( { ... doc.data(), id: doc.id, Dipendente:doc.data().Nome+ " "+doc.data().Cognome } );
            } 
          });
          setDipendenti(items);
          
        });
    } else if (sessionStorage.getItem('Role') == 'Admin_Zona' && sessionStorage.getItem('Cantiere') !== null)
    {
      dip.where('Zona', 'array-contains', sessionStorage.getItem('Cantiere')).onSnapshot((querySnapshot) => {
          const items = [];
          querySnapshot.forEach((doc) => {
            if(doc.data().Matricola!=="000"){
              items.push( { ... doc.data(), id: doc.id, Dipendente:doc.data().Nome+ " "+doc.data().Cognome } );
            } 
          });
          setDipendenti(items);
          
        });
    } else if (newZona.Zona !== undefined) {
      dip.where('Zona', 'array-contains', newZona.Zona).onSnapshot((querySnapshot) => {
          const items = [];
          querySnapshot.forEach((doc) => {
            if(doc.data().Matricola!=="000"){
              items.push( { ... doc.data(), id: doc.id, Dipendente:doc.data().Nome+ " "+doc.data().Cognome } );
            } 
          });
          setDipendenti(items);
          
        });
    } else {
      dip.onSnapshot((querySnapshot) => {
          const items = [];
          querySnapshot.forEach((doc) => {
            if(doc.data().Matricola!=="000"){
              items.push( { ... doc.data(), id: doc.id, Dipendente:doc.data().Nome+ " "+doc.data().Cognome } );
            } 
          });
          setDipendenti(items);
          
        });
    }
  
  }
  
   /**
   * Funzione che in base al ruolo dell'utente loggato e al macchinario selezionato rileva i lavori
   * da mostrare nella select
   */
  function getLavori() {
    if (sessionStorage.getItem('Role') == 'Admin_Cantiere' && sessionStorage.getItem('Cantiere') !== null && newZona.Zona !== undefined) {
      mac.where('Zona', 'array-contains', newZona.Zona).where('Telaio', '==', telaioMacchinario).onSnapshot((querySnapshot) => {
          const lavori = [];
          querySnapshot.forEach((doc) => {
              doc.data().Lavori.forEach((lavoro) => {
                if(elencoLavori.indexOf(lavoro)!==-1){
                  lavori.push( { Nome: lavoro} );
                }
              });
          });
          setLavori(lavori);
        });
    } else if (sessionStorage.getItem('Role') == 'Admin_Zona' && sessionStorage.getItem('Cantiere') !== null)
    {
      mac.where('Zona', 'array-contains', sessionStorage.getItem('Cantiere')).where('Telaio', '==', telaioMacchinario).onSnapshot((querySnapshot) => {
          const items = [];
          const lavori = [];
          querySnapshot.forEach((doc) => {
            doc.data().Lavori.forEach((lavoro) => {
              if(elencoLavori.indexOf(lavoro)!==-1){
                lavori.push( { Nome: lavoro} );
              }
            });
        });
          setLavori(lavori);
        });
    } else if (newZona.Zona !== undefined) {

      mac.where('Zona', 'array-contains', newZona.Zona).where('Telaio', '==', telaioMacchinario).onSnapshot((querySnapshot) => {
          const lavori = [];
          querySnapshot.forEach((doc) => {


            doc.data().Lavori.forEach((lavoro) => {

              if(elencoLavori.indexOf(lavoro)!==-1){

                lavori.push( { Nome: lavoro} );
              }
            });
        });
          setLavori(lavori);
        });
    } else {
      mac.where('Telaio', '==', telaioMacchinario).onSnapshot((querySnapshot) => {
          const lavori = [];
          querySnapshot.forEach((doc) => {
            doc.data().Lavori.forEach((lavoro) => {
              if(elencoLavori.indexOf(lavoro)!==-1){
                lavori.push( { Nome: lavoro} );
              }
            });
        });
          setLavori(lavori);
        });
    }
  }

   /**
   * Funzione che in base al ruolo dell'utente loggato e al cantiere/zona/adli selezionati rileva i macchinari
   * da mostrare nella select
   */
  async function getMacchinari() {
    if (sessionStorage.getItem('Role') == 'Admin_Cantiere' && sessionStorage.getItem('Cantiere') !== null && newZona.Zona !== undefined) {
      const querySnapshot = await mac.where('Zona', 'array-contains', newZona.Zona).get();
      
      const items = [];
      const lavori = [];

      querySnapshot.forEach((doc) => {
        console.log(doc.data());
        if (doc.data().Adl.includes(dropdownZonaSel.Adl.toString())) {
          items.push({ ...doc.data(), id: doc.id });
        }
      });

      setMacchinari(items);
    } else if (sessionStorage.getItem('Role') == 'Admin_Zona' && sessionStorage.getItem('Cantiere') !== null){
      mac.where('Zona', 'array-contains', sessionStorage.getItem('Cantiere')).onSnapshot((querySnapshot) => {
          const items = [];
          querySnapshot.forEach((doc) => {
            if(doc.data().Adl.includes(dropdownZonaSel.Adl.toString())) {
              items.push( { ... doc.data(), id: doc.id } );
            }
          });
          setMacchinari(items);
          
        });
    } else if (newZona.Zona !== undefined) {
      mac.where('Zona', 'array-contains', newZona.Zona).onSnapshot((querySnapshot) => {
          const items = [];
          querySnapshot.forEach((doc) => {
            console.log(doc.data());
            if(doc.data().Adl.includes(dropdownZonaSel.Adl.toString())) {
              items.push( { ... doc.data(), id: doc.id } );
            }
          });
          setMacchinari(items);
        });
    } else {
      console.log("4 if");
      mac.onSnapshot((querySnapshot) => {
          const items = [];
          querySnapshot.forEach((doc) => {
            if(doc.data().Adl.includes(dropdownZonaSel.Adl.toString())) {
              items.push( { ... doc.data(), id: doc.id } );
            }
          });
          setMacchinari(items);
          
        });
    }

  }

   /**
   * Funzione che in base al ruolo dell'utente loggato e al cantiere/zona/adl e macchinario selezionato rileva i prodotti
   * da mostrare nella select
   */
  function getProdotti() {

    if (sessionStorage.getItem('Role') == 'Admin_Cantiere' && sessionStorage.getItem('Cantiere') !== null && newZona.Zona !== undefined) {
      prod.where('Zona', 'array-contains', newZona.Zona).onSnapshot((querySnapshot) => {
          const items = [];
          querySnapshot.forEach((doc) => {
            items.push( { ... doc.data(), id: doc.id } );
          });
          setProdotti(items);
          
        });
    } else if (sessionStorage.getItem('Role') == 'Admin_Zona' && sessionStorage.getItem('Cantiere') !== null)
    {
      prod.where('Zona', 'array-contains', sessionStorage.getItem('Cantiere')).onSnapshot((querySnapshot) => {
          const items = [];
          querySnapshot.forEach((doc) => {
            items.push( { ... doc.data(), id: doc.id } );
          });
          setProdotti(items);
          
        });
    } else if (newZona.Zona !== undefined) {
      prod.where('Zona', 'array-contains', newZona.Zona).onSnapshot((querySnapshot) => {
          const items = [];
          querySnapshot.forEach((doc) => {
            items.push( { ... doc.data(), id: doc.id } );
          });
          setProdotti(items);
          
        });
    } else {
      prod.onSnapshot((querySnapshot) => {
          const items = [];
          querySnapshot.forEach((doc) => {
            items.push( { ... doc.data(), id: doc.id } );
          });
          setProdotti(items);
          
        });
    }

  }

   /**
   * Funzione che in base al ruolo dell'utente loggato e al cantiere/zona selezionato/a rileva le ADL
   * da mostrare nella select
   */
  function getAree() {
    if (newZona.Zona !== undefined)
    {
      aree.where('Zona', '==', newZona.Zona).onSnapshot((querySnapshot) => {
        const items = [];
        querySnapshot.forEach((doc) => {
           /**
           * Modifica dev-mattia-mora
           */
            var dataOdierna=moment(new Date()).toDate();
            //moment() non accetta date in formato dd:mm:yyyy, devo convertirle manualmente in mm:dd:yyyy
             var dataFineToConvert=doc.data().FineAdl;
             var day=dataFineToConvert.substring(0,2);
             var month=dataFineToConvert.substring(3,5);
             dataFineToConvert=replaceRange(dataFineToConvert, 0, 2, month);
             dataFineToConvert=replaceRange(dataFineToConvert, 3, 5, day);
             var dataFine=moment(dataFineToConvert).toDate();
             if(dataOdierna.getFullYear() < dataFine.getFullYear()){
               items.push( { ... doc.data(), id: doc.id } );
             }else if(dataOdierna.getFullYear() == dataFine.getFullYear() && dataOdierna.getMonth() < dataFine.getMonth()){
               items.push( { ... doc.data(), id: doc.id } );
             }else if(dataOdierna.getMonth() == dataFine.getMonth() && dataOdierna.getDate() < dataFine.getDate()){
               items.push( { ... doc.data(), id: doc.id } );
             }else if(dataOdierna.getMonth() == dataFine.getMonth() && dataOdierna.getDate() == dataFine.getDate()){

             }else{

             }
        });
        setZona(items);
      });
    }
  }


  /**
   * Serie di useEffect che permettono di lanciare le funzioni filtro in base alle selezioni effettuate
   * dall'utente
   */
  useEffect(() => {
    if(dropdownDipSel=='') {
      getDipendenti();
    }
    getCantieri();
  }, [dropdownLavSel]);

  useEffect(() => {
    getCantieriZona();
  }, [getCantieri]);

  useEffect(() => {
    getAree();
    getProdotti();
    sessionStorage.setItem('Zona', newZona.Zona);
  }, [newZona]);

  useEffect(() => {
    if(dropdownZonaSel.Adl !== undefined) {
      getMacchinari();
    }
  }, [dropdownZonaSel]);

  useEffect(() => {
    if(telaioMacchinario !== '') {
     getLavori();
    }
  }, [telaioMacchinario]);

  //Creo prima un array contente i lavori della Adl selezionata e lo passo alla funzione
  //che filtra in base ai lavori dei macchinari
  useEffect(()=>{
    if(dropdownZonaSel.Adl!==undefined){
      lav.where('Adl', 'array-contains', dropdownZonaSel.Adl).onSnapshot((querySnapshot)=>{        
        const elencoLavoriApp=[];
         querySnapshot.forEach((doc)=>{
           elencoLavoriApp.push(doc.data().Nome);
         })
         setElencoLavori(elencoLavoriApp);
       })
    }
  },[dropdownZonaSel.Adl])
  
  /**
   * Per gestire la chiamata dal componente AttivitaDet1 attraverso la sua funzione "handleSelectSlot", il dipendente selezionato è uno
   * quindi l'elenco dei dipendenti deve contenere solo il dipendente selezionato.
   * La matricola,il nome e il cognome vengono letti da session storage alla chiamata del componente
   */
  useEffect(()=>{
    if(dropdownDipSel!=''){  
      let dipendentiApp=[];   
      dipendentiApp.push({Dipendente:dropdownDipSel});
      setDipendenti(dipendentiApp);
    }else{
      getDipendenti();
    }
  },[dropdownDipSel])

  /*
    Serie di funzioni che permettono di assegnare la chiave univoca corretta in base all'anagrafica selezionata
 */
  function onChangedSetDip(option){
    setMatricola(elencoMatricole[option.selected])
    setDropdownDipSel(option.value);
  }
  
  function onChangedSetMac(option){
    setTelaioMacchinario(elencoMacchinari[option.selected]);
    setDropdownMacSel(option.value);
  }

  function onChangedSetSku(option){
    setSku(elencoSku[option.selected]);
    setDropdownProdSel(option.value);
  }

  const elencoMatricole=[]; //array ordinato che permette di avere il numero di matricola corretto in base all'indice del dipendente selezionato nel Select
  const elencoNomiDip=[]; //array utilizzato per la rilevazione di nomi e cognomi uguali
  const elencoMacchinari=[]; //Contiene i numeri di telai, array ordinato che permette di avere il numero di telaio corretto in base all'indice del macchinario selezionato nel Select
  const elencoNomiMacchinari=[]; //array utilizzato per la rilevazione di macchinari con lo stesso nome
  const elencoSku=[];  //array ordinato che permette di avere il numero Sku corretto in base all'indice del prodotto selezionato nel Select
  const elencoNomiProd=[]; //array utilizzato per la rilevazione di prodotti con lo stesso nome

  /**
   * Funzione che cancella il session storage, serve per cancellare i dati nel caso in cui
   * sia avvenuto un trascinamento sul calendario
   */
  function goBack() {
    sessionStorage.removeItem('StartDateSelected');
    sessionStorage.removeItem('EndDateSelected');
    sessionStorage.removeItem('Dipendente');
    sessionStorage.removeItem('Matricola');
    sessionStorage.setItem("ricarica",true); // per ricaricare calendario in vista mese, evita crash
    window.location.href = window.location.origin + "/home";
  }


  return (
    <Box margin="small" direction="column">
       <Button margin="small" style={{width:'150px',fontSize:'15px',background:"#006837",color:'white',fontWeight:'bold',textTransform: 'uppercase',borderStyle: 'solid',borderColor:'#006837',borderWidth:"3px", borderRadius:"10px"}}  onClick={goBack}>
          <Box pad="small" direction="row" align="center" gap="medium">
              <LinkPrevious color="white"/>
              <Text size="small" color="white">Indietro</Text>
          </Box>
        </Button>
      <Box round pad="medium" direction="column" background="white">
        <Heading level="2" alignSelf="center" margin="small" size="small" style={{textTransform:'uppercase'}}>
          Crea Attività
        </Heading>
        <Box>
        <Form>
          <Box direction="row" align="center">
            <FormField required margin="medium">
              <Box direction="row">
                <Text style={{marginRight:'10px',marginTop:'-3px'}}>Seleziona il dipendente: *</Text>
                { dropdownDipSel == '' || (sessionStorage.getItem("Dipendente")!==null) ? 
                    null
                    : 
                    <Anchor onClick={() => setDropdownDipSel('')} label="Rimuovi" /> 
                }
              </Box>
              <Select
                options={(dipendenti.sort(function(a, b) { return a.Dipendente.localeCompare(b.Dipendente) }).filter(   
                  function(a){
                    if(elencoNomiDip.indexOf(a.Dipendente)===-1){ //Logica per rilevare nomi e cognomi doppi, se ne trova aggiunge il numero di matricola per permettere
                      elencoNomiDip.push(a.Dipendente);           //all'utente di distinguere tra loro i dipendenti
                    }else{
                      a.Dipendente=a.Dipendente+" "+a.Matricola;
                    }
                    if(elencoMatricole.indexOf(a.Matricola)===-1){ //Logica per la gestione del numero di matricole dei dipendenti nella creazione di una nuova attività
                        elencoMatricole.push(a.Matricola);        
                        return true;
                      }
                    
                      return false;
                    
                }).map((item)=>item.Dipendente))}
                value={dropdownDipSel}
                onChange={( option ) =>onChangedSetDip(option)}
              />
            </FormField>
            <Box pad="xsmall">
              <FormField required label="Cantiere: *">
                <Select
                  options={[... new Set(cantieri.sort(function(a, b) { return a.Nome.localeCompare(b.Nome) }).map(cantiere => (cantiere.Nome)))]}
                  value={newCantiere.Cantiere}
                  onChange={event =>
                    setNewCantiere({
                      Cantiere: event.value
                    })
                  }
                />
              </FormField>
            </Box>
              <Box pad="xsmall">
                <FormField required label="Zona: *">
                  <Select
                    options={[... new Set(cantieriZona.sort(function(a, b) { return a.Zona.localeCompare(b.Zona) }).map(cantiere => (cantiere.Zona)))]}
                    value={newZona.value}
                    onChange={event =>
                    setNewZona({
                      Zona: event.value
                    })
                  }
                  />
                </FormField>
              </Box>
          </Box>
          <Box direction="row" align="center">
              <FormField required margin="medium">
                <Box direction="row">
                  <Text style={{marginRight:'10px',marginTop:'-3px'}}>Area di Lavoro: *</Text>
                </Box>
                <Select
                  options={[... new Set(zona.sort(function(a, b) { return a.Localita.localeCompare(b.Localita) }).map(area => (area.Localita)))]}
                  value={dropdownZonaSel.value}
                  onChange={event => 
                  setDropdownZonaSel({
                    Adl: event.value
                  })
                }
                />
              </FormField>
              <FormField required margin="medium">
                <Box direction="row">
                  <Text style={{marginRight:'10px',marginTop:'-3px'}}>Seleziona il macchinario: *</Text>
                  { dropdownMacSel == '' ? 
                      null
                      : 
                      <Anchor onClick={() => setDropdownMacSel('')} label="Rimuovi" /> 
                  }
                </Box>
                <Select
                  options={(macchine.sort(function(a, b) { return a.NomeMacchina.localeCompare(b.NomeMacchina) }).filter(   
                    function(a){
                      if(elencoNomiMacchinari.indexOf(a.NomeMacchina)===-1){ //Logica per rilevare macchinari con nomi doppi, se ne trova aggiunge il numero di telaio per permettere
                        elencoNomiMacchinari.push(a.NomeMacchina);            //una facile selezione all'utente
                      }else{
                        a.NomeMacchina=a.NomeMacchina+" "+a.Telaio;
                      }
                      if(elencoMacchinari.indexOf(a.Telaio)===-1){      //Logica per la gestione del numero di telaio dei macchinari nella creazione di una nuova attività
                          elencoMacchinari.push(a.Telaio);
                          return true;
                        }
                        return false;
                      
                  }).sort(function(a, b) { return a.NomeMacchina.localeCompare(b.NomeMacchina) }).map((item)=>item.NomeMacchina))}
                  value={dropdownMacSel}
                  onChange={( option ) => onChangedSetMac(option)}
                />
              </FormField>
              <FormField required margin="medium">
                <Box direction="row">
                  <Text style={{marginRight:'10px',marginTop:'-3px'}}>Seleziona il lavoro: *</Text>
                  { dropdownLavSel == '' ? 
                      null
                      : 
                      <Anchor onClick={() => setDropdownLavSel('')} label="Rimuovi" /> 
                  }
                </Box>
                <Select
                  options={[... new Set(lavori.sort(function(a, b) { return a.Nome.localeCompare(b.Nome) }).map(lavoro => (lavoro.Nome)))]}
                  value={dropdownLavSel}
                  onChange={({ option }) => setDropdownLavSel(option)}
                />
              </FormField>
          </Box>
          <Box direction="row" align="center">
            <FormField required margin="medium">
              <Box direction="row">
                <Text style={{marginRight:'10px',marginTop:'-3px'}}>Seleziona il prodotto:</Text>
                { dropdownProdSel == '' ? 
                    null
                    : 
                    <Anchor onClick={() => setDropdownProdSel('')} label="Rimuovi" /> 
                }
              </Box>
            <Select
                options={prodotti.sort(function(a, b) { return a.Nome.localeCompare(b.Nome) }).filter(   
                  function(a){
                    if(elencoNomiProd.indexOf(a.Nome)===-1){ //Logica per rilevare i prodotto con nomi ugali, se ne trova aggiunge il numero di Sku per permettere
                      elencoNomiProd.push(a.Nome);           //una facile selezione all'utente
                    }else{
                      a.Nome=a.Nome+" "+a.Sku;
                    }
                    if(elencoSku.indexOf(a.Sku)===-1){ //Logica per la gestione del numero Sku dei prodotti nella creazione di una nuova attività
                      elencoSku.push(a.Sku);
                      return true;
                      }
                      return false;
                    }
                ).map((item)=>item.Nome)}
                value={dropdownProdSel}
                onChange={(option ) => onChangedSetSku(option)}
            />
            </FormField>
            <FormField required margin="medium">
              <Text style={{marginRight:'10px',marginTop:'-3px'}}>Seleziona la data di inzio: *</Text>
                <DateInput
                  format="dd/mm/yyyy"
                  value={newDataLavoro}
                  onChange={({ value }) => {setNewDataLavoro(value.split('T')[0])}}
                  calendarProps={{ size: "small", locale: "it-IT", daysOfWeek: true, firstDayOfWeek: 1 }}
                />
              </FormField>
            <FormField required margin="medium">
              <Text style={{marginRight:'10px',marginTop:'-3px'}}>Seleziona l'orario di inizio attività: *</Text>
              <MaskedInput
                style={{background:'white'}}
                mask={[
                        {
                          length: [1, 2],
                          options: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24'],
                          regexp: /^1[1-2]$|^[0-9]$/,
                          placeholder: 'hh',
                        },
                        { fixed: ':' },
                        {
                          length: 2,
                          options: ['00', '15', '30', '45'],
                          regexp: /^[0-5][0-9]$|^[0-9]$/,
                          placeholder: 'mm',
                        },
                      ]}
                value={newOraInizio}
                onChange={event => setNewOraInizio(event.target.value)}
              />
            </FormField>
            <FormField required margin="medium">
              <Text style={{marginRight:'10px',marginTop:'-3px'}}>Seleziona la data di fine: *</Text>
                <DateInput
                  format="dd/mm/yyyy"
                  value={newEndLavoro}
                  onChange={({ value }) => {setNewEndLavoro(value.split('T')[0])}}
                  calendarProps={{ size: "small", locale: "it-IT", daysOfWeek: true, firstDayOfWeek: 1 }}
                />
            </FormField>
          </Box>
          <Box direction="row" align="center">
            <FormField required margin="medium">
              <Text style={{marginRight:'10px',marginTop:'-3px'}}>Seleziona l'orario di fine attività: *</Text>
              <MaskedInput
                style={{background:'white'}}
                mask={[
                        {
                          length: [1, 2],
                          options: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24'],
                          regexp: /^1[1-2]$|^[0-9]$/,
                          placeholder: 'hh',
                        },
                        { fixed: ':' },
                        {
                          length: 2,
                          options: ['00', '15', '30', '45'],
                          regexp: /^[0-5][0-9]$|^[0-9]$/,
                          placeholder: 'mm',
                        },
                      ]}
                value={newOraFine}
                onChange={event => setNewOraFine(event.target.value)}
              />
            </FormField>
            <FormField required margin="medium">
              <Box direction="row">
                <Text style={{marginRight:'10px',marginTop:'-3px'}}>Ordinaria/Straordinaria:</Text>
                { dropdownOrdinariaSel == '' ? 
                    null
                    : 
                    <Anchor onClick={() => setDropdownOrdinariaSel('')} label="Rimuovi" /> 
                }
              </Box>
              <Select
                options={ordinaria}
                value={dropdownOrdinariaSel}
                onChange={({ option }) => setDropdownOrdinariaSel(option)}
              />
            </FormField>
            <Box margin="medium" alignSelf="center" justify="end">
            <CheckBox
                checked={manuale}
                label="Chiusura Manuale"
                onChange={(event) => setManuale(event.target.checked)}
              />
            </Box>
          </Box>
            <Box >
              <FormField required margin="medium">
                <Text style={{marginRight:'10px',marginTop:'-3px'}}>Note (MAX 40 CARATTERI)</Text>
                <TextArea
                  resize={false}
                  maxLength="40"
                  placeholder="es. Effettuare una foto terminato il lavoro"
                  onChange={e => setNewMessaggio(e.target.value)}
                />
              </FormField>
            </Box>
          <Box direction="row" justify="end">
            {
              timeError?
              <Text color="red" weight="bold">L'orario inserito non è corretto</Text>
              :
            <Button label="Crea" onClick={onAssignActivity} style={{width:'138px',height:'34px',fontSize:'15px',background:'#006837',color:'white',fontWeight:'bold',textTransform: 'uppercase'}}/>
            }
            <Button type="reset" label="Cancella" style={{marginLeft:'20px',width:'138px',fontSize:'15px',background:'#c51d34',color:'white',fontWeight:'bold',textTransform: 'uppercase', borderColor:'#c51d34'}} />
          </Box>
        </Form>
        </Box>
      </Box>
    </Box>
  )
}