import React, { useState, useEffect } from "react";
import { Box, DateInput, Text, Heading, Form, FormField, Select, TextInput, Button } from "grommet";
import firebase from '../firebase';


export default function AddLav(props) {
  
  /**
   * Funzione che aggiunge un anagrafica a database
   */
  const onAdd = () => {
    const db = firebase.firestore();
    db.collection('Prodotti').add({ Nome: newNomeProdotto, Produttore: newProduttore, Qta: newQta, Um: newUm, Valore: newValore, Cantiere: newCantiere.Cantiere, Zona: newZona.Zona, Sku:sku });
    props.onEscLayer();
  }
  
  const [newNomeProdotto, setNewNomeProdotto] = useState("");
  const [newProduttore, setNewProduttore] = useState("");
  const [newQta, setNewQta] = useState("");
  const [newUm, setNewUm] = useState("");
  const [newValore, setNewValore] = useState("");

  /*CHANGE CANTIERE*/
  const [newCantiere, setNewCantiere] = useState([]);
  const [newZona, setNewZona] = useState([]);

  const [cantieri, setCantieri] = useState([]);
  const [cantieriZona, setCantieriZona] = useState([]);
  const [sku,setSku]=React.useState();
  const cant = firebase.firestore().collection('Aree');

  /**
   * Funzione che ottiene i cantieri presenti a sistema
   */
  function getCantieri() {
      cant.onSnapshot((querySnapshot) => {
        const items = [];
        querySnapshot.forEach((doc) => {
          items.push( { ... doc.data(), id: doc.id } );
        });
        setCantieri(items);
        
      });
  }

  /**
   * Funzione che in base al cantiere selezionato rileva le zone
   */
  function getCantieriZona() {
    if (newCantiere.Cantiere !== undefined && newCantiere.Cantiere.length > 0) {
      cant.where('Nome', 'in', newCantiere.Cantiere).onSnapshot((querySnapshot) => {
        const items = [];
        querySnapshot.forEach((doc) => {
          items.push( { ... doc.data(), id: doc.id } );
        });
        setCantieriZona(items);
        
      });
    }
  }

   /**
   * Controlla se apro il componente per modificare un anagrafica e aggiorna i campi di conseguenza
   */
  useEffect(() => {
    getCantieri();
    if(props.prodotto!==undefined){
      setNewNomeProdotto(props.prodotto.Nome);
      setNewProduttore(props.prodotto.Produttore);
      setNewQta(props.prodotto.Qta);
      setNewUm(props.prodotto.Um);
      setNewValore(props.prodotto.Valore);
      setNewCantiere({Cantiere: props.prodotto.Cantiere});
      setNewZona({Zona: props.prodotto.Zona});
      setSku(props.prodotto.Sku);
    }
  }, []);

  useEffect(() => {
    getCantieriZona();  
  }, [newCantiere]);

  /*
    Funzione che resetta i campi dell'anagrafica
  */
  const reset=()=>{
    setNewNomeProdotto("");
    setNewProduttore("");
    setNewQta("");
    setNewUm("");
    setNewValore("");
    setNewCantiere([]);
    setNewZona([]);
    setSku("");
  }

  /**
   * Funzione che aggiorna un anagrafica con i nuovi dati
   */
  const onUpdate=()=>{
    const db = firebase.firestore();
    db.collection('Prodotti').doc(props.prodotto.id).set({ Nome: newNomeProdotto, Produttore: newProduttore, Qta: newQta, Um: newUm, Valore: newValore, Cantiere: newCantiere.Cantiere, Zona: newZona.Zona, Sku:sku });
    props.onEscLayer();
  }

  return (
    <Box round pad="medium" direction="column" background="white" align="center" overflow="auto">
      <Heading level="2" margin="none" size="small">
        <Box direction="row" gap="large">
          Inserisci il Prodotto
          <Button label="Scarica modello" href={"./modelliCsv/Modello_Prodotto.csv"} style={{width:'200px',fontSize:'20px',background:'#006837',color:'white',fontWeight:'bold',textTransform: 'uppercase',textAlign:"center"}}></Button>  
        </Box>
      </Heading>
      <Box fill gap="medium" pad={{ vertical: "small" }}>
        <Form>
        <Box direction="row" align="center">
          <Box pad="xsmall">
            <FormField required label="Nome Prodotto">
              <TextInput
                placeholder="es. Concime X"
                value={newNomeProdotto}
                onChange={e => setNewNomeProdotto(e.target.value)}
              />
            </FormField>
          </Box>
          <Box pad="xsmall">
            <FormField required label="Produttore">
              <TextInput
                placeholder="es. Solabiol"
                value={newProduttore}
                onChange={e => setNewProduttore(e.target.value)}
              />
            </FormField>
          </Box>
          <Box pad="xsmall">
            <FormField required label="Quantità in Magazzino">
              <TextInput
                placeholder="es. 5"
                value={newQta}
                onChange={e => setNewQta(e.target.value)}
              />
            </FormField>
          </Box>
        </Box>
        <Box direction="row" align="center">
          <Box pad="xsmall">
            <FormField required label="Unità di Misura (Um)">
              <TextInput
                placeholder="es. l (per litri) - kg (per chilogrammi)"
                value={newUm}
                onChange={e => setNewUm(e.target.value)}
              />
            </FormField>
          </Box>
          <Box pad="xsmall">
            <FormField required label="Valore in Um">
              <TextInput
                placeholder="es. 10 (rif. a campo Um)"
                value={newValore}
                onChange={e => setNewValore(e.target.value)}
              />
            </FormField>
          </Box>
          <Box pad="xsmall">
            <FormField required label="Inserisci SKU">
              <TextInput
                placeholder="es. 12345"
                value={sku}
                onChange={e => setSku(e.target.value)}
              />
            </FormField>
          </Box>
        </Box>

        <Box direction="row" align="center">
          <Box pad="xsmall">
            <FormField required label="Cantiere">
              <Select
                multiple={true}
                closeOnChange={false}
                options={[... new Set(cantieri.sort(function(a, b) { return a.Nome.localeCompare(b.Nome) }).map(cantiere => (cantiere.Nome)))]}
                value={newCantiere.Cantiere}
                onChange={event =>
                  setNewCantiere({
                    Cantiere: event.value
                  })
                }
              />
            </FormField>
          </Box>
            <Box pad="xsmall">
              <FormField required label="Zona">
                <Select
                  multiple={true}
                  closeOnChange={false}
                  options={[... new Set(cantieriZona.sort(function(a, b) { return a.Zona.localeCompare(b.Zona) }).map(cantiere => (cantiere.Zona)))]}
                  value={newZona.Zona}
                  onChange={event =>
                  setNewZona({
                    Zona: event.value
                  })
                }
                />
              </FormField>
            </Box>
        </Box>
      </Form>
      <Box direction="row" gap="medium" alignSelf="end">
          <Button label={(props.prodotto===undefined)?"Aggiungi":"Aggiorna"} onClick= {(props.prodotto===undefined)?onAdd:onUpdate} style={{width:'138px',height:'34px',fontSize:'15px',background:'#006837',color:'white',fontWeight:'bold',textTransform: 'uppercase'}}/>
          <Button type="reset" label={(props.prodotto!==undefined)?"Cancella":"Reset"} onClick={(props.prodotto===undefined)?reset:props.onDelete} style={{marginLeft:'5px',width:'138px',height:'34px',fontSize:'15px',background:'#c51d34',color:'white',fontWeight:'bold',textTransform: 'uppercase', borderColor:'#c51d34'}}/>
        </Box>
      </Box>
    </Box>
  )
}