import React, { useState, useEffect } from "react";
import { Box, DateInput, Text, Heading, Form, FormField, Select, TextInput, Button } from "grommet";
import firebase from '../firebase';


export default function AddLav(props) {
  
  /**
   * Funzione che aggiunge un anagrafica a database
   */
  const onAdd = () => {
    const db = firebase.firestore();
    db.collection('Lavori').add({ Nome: newName, Durata: newDurata, Cantiere: newCantiere.Cantiere, Zona: newZona.Zona, Adl: newAdl.Adl, CodiceGestionale: newCode });
    props.onEscLayer();
  }
  
  const [newName, setNewName] = useState("");
  const [newDataLavoro, setNewDataLavoro] = useState("");
  const [newDurata, setNewDurata] = useState("");

  /*CHANGE CANTIERE*/
  const [newCantiere, setNewCantiere] = useState([]);
  const [newZona, setNewZona] = useState([]);

  const [cantieri, setCantieri] = useState([]);
  const [cantieriZona, setCantieriZona] = useState([]);
  const cant = firebase.firestore().collection('Aree');
  const [newAdl, setNewAdl] = useState([]);

  /*CODICE GESTIONALE ESTERNO*/
  const [newCode, setNewCode] = useState("");
  /**/

  /**
   * Funzione che ottiene i cantieri presenti a sistema
   */
  function getCantieri() {
      cant.onSnapshot((querySnapshot) => {
        const items = [];
        querySnapshot.forEach((doc) => {
          items.push( { ... doc.data(), id: doc.id } );
        });
        setCantieri(items);
        
      });
  }

   /**
   * Funzione che in base al cantiere selezionato rileva le zone
   */
  function getCantieriZona() {
    if (newCantiere.Cantiere !== undefined && newCantiere.Cantiere.length > 0) {
      cant.where('Nome', 'in', newCantiere.Cantiere).onSnapshot((querySnapshot) => {
        const items = [];
        querySnapshot.forEach((doc) => {
          items.push( { ... doc.data(), id: doc.id } );
        });
        setCantieriZona(items);
        
      });
    }
  }
  /*END CHANGE CANTIERE*/

  /**
   * Funzione che aggiorna un anagrafica con i nuovi dati
   */
  const onUpdate=()=>{
    const db = firebase.firestore();
    db.collection('Lavori').doc(props.lavoro.id).set({ Nome: newName, Durata: newDurata, Cantiere: newCantiere.Cantiere, Zona: newZona.Zona, Adl: newAdl.Adl, CodiceGestionale: newCode });
    props.onEscLayer();
  }

   /**
   * Controlla se apro il componente per modificare un anagrafica e aggiorna i campi di conseguenza
   */
  useEffect(() => {
    getCantieri();
    if(props.lavoro!==undefined){    
      setNewName(props.lavoro.Nome);
      setNewDataLavoro(props.lavoro.Durata);
      setNewCantiere({Cantiere: props.lavoro.Cantiere});
      setNewZona({Zona: props.lavoro.Zona});
      if (props.lavoro.CodiceGestionale === undefined) {
        setNewCode("");
      } else {
        setNewCode(props.lavoro.CodiceGestionale);
      }
      if (props.lavoro.Adl === undefined) {
        setNewAdl({Adl: ""});
      } else {
        setNewAdl({Adl: props.lavoro.Adl});
      }
    }
  }, []);

  /*
    Funzione che resetta i campi dell'anagrafica
  */
  const reset=()=>{
    setNewName("");
    setNewDataLavoro("");
    setNewCantiere({Cantiere: ""});
    setNewZona({Zona: ""});
    setNewAdl({Adl: ""});
    setNewCode("");
  }

  useEffect(() => {
    getCantieriZona();    
  }, [newCantiere]);

  return (
    <Box round pad="medium" direction="column" background="white" align="center" overflow="auto">
      <Heading level="2" margin="none" size="small">
        <Box direction="row" gap="large">
          {(props.lavoro===undefined)?"Inserisci Lavoro":"Aggiorna lavoro"}
          <Button label="Scarica modello" href={"./modelliCsv/Modello_Lavoro.csv"} style={{width:'200px',fontSize:'20px',background:'#006837',color:'white',fontWeight:'bold',textTransform: 'uppercase',textAlign:"center"}}></Button>  
        </Box>
      </Heading>
      <Box fill gap="medium" pad={{ vertical: "small" }}>
        <Form>
        <Box direction="row" align="center">
          <Box pad="small">
            <FormField required label="Nome">
              <TextInput
                placeholder="es. Taglio Erba"
                value={newName}
                onChange={e => setNewName(e.target.value)}
              />
            </FormField>
          </Box>
        </Box>
        <Box direction="row" align="center">
          <Box pad="xsmall">
            <FormField required label="Durata (minuti)">
              <TextInput
                placeholder="es. 1200"
                value={newDurata}
                onChange={e => setNewDurata(e.target.value)}
              />
            </FormField>
          </Box>
          <Box pad="xsmall">
            <FormField required label="Codice Gestionale (ESTERNO)">
              <TextInput
                placeholder="es. "
                value={newCode}
                onChange={e => setNewCode(e.target.value)}
              />
            </FormField>
          </Box>
        </Box>
        <Box direction="row" align="center">
          <Box pad="xsmall">
            <FormField required label="Cantiere">
              <Select
                multiple={true}
                closeOnChange={false}
                options={[... new Set(cantieri.sort(function(a, b) { return a.Nome.localeCompare(b.Nome) }).map(cantiere => (cantiere.Nome)))]}
                value={newCantiere.Cantiere}
                onChange={event =>
                  setNewCantiere({
                    Cantiere: event.value
                  })
                }
              />
            </FormField>
          </Box>
            <Box pad="xsmall">
              <FormField required label="Zona">
                <Select
                  multiple={true}
                  closeOnChange={false}
                  options={[... new Set(cantieriZona.sort(function(a, b) { return a.Zona.localeCompare(b.Zona) }).map(cantiere => (cantiere.Zona)))]}
                  value={newZona.Zona}
                  onChange={event =>
                  setNewZona({
                    Zona: event.value
                  })
                }
                />
              </FormField>
            </Box>
            <Box pad="xsmall">
              <FormField required label="Area Di Lavoro">
                <Select
                  multiple={true}
                  closeOnChange={false}
                  options={[... new Set(cantieriZona.sort(function(a, b) { return a.Localita.localeCompare(b.Localita) }).map(cantiere => (cantiere.Localita)))]}
                  value={newAdl.Adl}
                  onChange={event =>
                  setNewAdl({
                    Adl: event.value
                  })
                }
                />
              </FormField>
            </Box>
        </Box>
        </Form>
        <Box direction="row" gap="medium" alignSelf="end" >
          <Button margin="small" label= {(props.lavoro===undefined)?"Aggiungi":"Aggiorna"} onClick={(props.lavoro===undefined)?onAdd:onUpdate} style={{width:'138px',height:'34px',fontSize:'15px',background:'#006837',color:'white',fontWeight:'bold',textTransform: 'uppercase'}}/>
          <Button margin="small" type="reset" label={(props.lavoro!==undefined)?"Cancella":"Reset"} onClick={(props.lavoro===undefined)?reset:props.onDelete} style={{marginLeft:'5px',width:'138px',height:'34px',fontSize:'15px',background:'#c51d34',color:'white',fontWeight:'bold',textTransform: 'uppercase', borderColor:'#c51d34'}} />     
        </Box>
      </Box>
    </Box>
  )
}