import React, { useState, useEffect } from "react";
import { Box, Card, CardBody, Grommet, Form, FormField, TextInput, ResponsiveContext, Button, Image,Text } from 'grommet';
import { Hide, View } from 'grommet-icons';
import firebase, { auth } from '../firebase';
import { useHistory } from "react-router-dom";
import { isChrome} from "react-device-detect";

const theme = {
  global: {
      input:{
        font:{
          weight: 18,
        }
      },
    colors: {
      brand: '#0C6B13',
      'focus': 'rgba(0, 0, 0, 0)',
      "my-text-color": "",
      background: '#0350281A',
    },
    font: {
      family: 'Rubik sans-serif',
      size: '18px',
      height: '20px',
    },
  },
  formField: {
    border: false
  },
};


const LogIn = () => {

  const [value, setValue] = React.useState({});
  const history = useHistory();
  const [reveal, setReveal] = React.useState(false);
  const [landScapeError,setLandScapeError]=React.useState(false);

  useEffect(() => {
    if(!isChrome){
      alert("Devi installare google chrome per utilizzare taskonnect")
    }
    sessionStorage.clear();
  }, []);

  const [width, setWidth]   = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
    /**
     * Funzione che permette di sapere le dimensioni aggiornate dello schermo
     */
  const updateDimensions = () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
  }
  useEffect(() => {
      window.addEventListener("resize", updateDimensions);
      return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  /**
   * Controllo in base a width e height l'orientamento dello schermo.
   * Per essere respondive blocco la web app in LandScape.
   */
  React.useEffect(()=>{
    if(width<1100){
      if(width<height){
        setLandScapeError(true);
      }else{
        setLandScapeError(false);
      }    
    }
  },[width,height])

  return (
    <Grommet theme={theme} className="font-link" themeMode="dark" style={{height:'100%',position:'fixed',width:'100%',marginTop:'-8px',marginLeft:'-8px'}}>
      <ResponsiveContext.Consumer>
        {size => (
          !landScapeError?
          <Box pad="small" direction="row" gap="large" align="center" height="97vh" flex="false">  
            <Box height="95vh" width="60%">
              <Image fit="contain" src="./images/Login_Image.svg" />
            </Box>
            <Box round="large" gap={width>=1100?"large":"small"}align="center" alignSelf="center" height={height>=370?"70vh":"90vh"} flex="false" width={width>=1100?"30%":"40%"}>
                <Card fit elevation={0}  alignSelf="center" round="large" width="100%" background="light-1" style={{borderStyle: 'solid',borderColor:'#707070',borderWidth:"1px"}}>
                  {/*<CardHeader pad="medium" alignSelf="center">Welcome</CardHeader>*/}
                  <CardBody align="center" >
                    {width>=1100 && height>=570?
                    <Box round="large" height="small" width="small" >
                        <Image fit="cover" src="./images/Logo1.png" />
                    </Box>
                    :
                    null  
                  }
                    <Form
                      value={value}
                      onChange={nextValue => setValue(nextValue)}
                      onReset={() => setValue({})}
                      onSubmit=
                      {
                        ({ value }) => 
                          {
                            auth.setPersistence(firebase.auth.Auth.Persistence.SESSION)
                            .then(() => {
                              return auth.signInWithEmailAndPassword(value.Email, value.Password)
                              .then(() => {history.push('/home')})
                              .catch((error) => {alert("Email o Password errata!")})
                            })
                            
                          }
                      }
                    >
                      <Box pad="small">
                        <FormField plain name="Email" htmlFor="text-input-id" label={<Text size="12px">e-mail</Text>}>
                          <Box
                            direction="row"
                            round="small"
                            border
                            style={{borderStyle: 'solid',borderColor:'#707070',borderWidth:"1px"}}
                          >
                          <TextInput plain id="text-input-id" name="Email" />
                          </Box>
                        </FormField>
                        <FormField name="Password" htmlFor="text-input-id" label={<Text size="12px">password</Text>}>
                          <Box
                            direction="row"
                            round="small"
                            border
                            style={{borderStyle: 'solid',borderColor:'#707070',borderWidth:"1px"}}
                          >
                          <TextInput plain type={reveal ? 'text' : 'password'} id="text-input-id" name="Password" />
                          <Button
                            icon={reveal ? <View size="medium" /> : <Hide size="medium" />}
                            onClick={() => setReveal(!reveal)}
                          />
                          </Box>
                        </FormField>
                        <Box direction="row" gap="large" alignSelf="center" style={{justifyContent: 'center',marginTop:'30px',marginBottom:'30px'}} >
                          {
                            isChrome?
                            <Button type="submit" primary label="Accedi" />
                            :
                            <Button onClick={()=>window.location.href = "https://www.google.com/intl/it_it/chrome/"} primary label="Scarica Google Chrome" />
                          }       
                          <Button type="reset" label="Annulla" style={{borderStyle: 'solid',borderColor:'#707070',borderWidth:"1px"}}/>
                        </Box>
                    </Box>
                    </Form>
                  </CardBody>
                </Card>
              </Box>
            </Box>
            :
            <Box width="100%" height="100%" align="center" alignSelf="center">
              <Text margin="small" color="#04671E" size="large" weight="bold">GIRA LO SCHERMO PER UTILIZZARE TASKONNECT</Text>
            </Box>
        )}
      </ResponsiveContext.Consumer>
    </Grommet>
  );
}

export default LogIn;
