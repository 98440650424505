import React from "react";
import { TextInput } from "grommet";


/**
 * Componente utilizzato per non ricaricare la mappa durante l'inserimento
 * della label
 * Implementa il textInput di inserimento label per i poligoni
 */

const TextFieldLabel =(props)=>{

    const [labelBuca,setLabelBuca]=React.useState("");

    function onChange(event){
        props.handleLabel(event);
    }

    return(
        <TextInput
            placeholder="es. Buca 1"
            value={labelBuca}
            onChange={event =>{ onChange(event.target.value);setLabelBuca(event.target.value)}}
            />
    );
}

export default TextFieldLabel;