import React, { useState, useEffect } from "react";
import { Box, DateInput, Text, Heading, Form, FormField, Select, TextInput, Button } from "grommet";
import firebase from '../firebase';
import moment from 'moment';

export default function AddLav(props) {
  
  /**
   * Funzione che aggiunge un anagrafica a database
   */
  const onAdd = () => {
    if (newTelaio.Telaio.split('-')[1] == "") {
      alert("Non hai selezionato nessun macchinario!");
    } else {
      const db = firebase.firestore();
      db.collection('RilievoOre').add({ NomeOperatore: newNomeOperatore, CognomeOperatore: newCognomeOperatore, DataRilievo: newDataRilievo, MatricolaOperatore: newMatricolaOperatore.Matricola.substr(0,6), Telaio: newTelaio.Telaio.split('-')[1], Modello: newModello, OreCalcolate: newOreCalcolate, OreRilevate: newOreRilevate.Ore, Delta: newDelta});
      updateOre(newTelaio.Telaio.split('-')[1]);
      props.onEscLayer();
    }
  }
  
  const [newDataRilievo, setNewDataRilievo] = useState("");
  const [newTelaio, setNewTelaio] = useState({Telaio: ""});
  const [newModello, setNewModello] = useState("");
  const [newOreCalcolate, setNewOreCalcolate] = useState("");
  const [newOreRilevate, setNewOreRilevate] = useState({Ore: ""});
  const [newDelta, setNewDelta] = useState("");

  /*CHANGE DIPENDENTI*/
  const [newNomeOperatore, setNewNomeOperatore] = useState("");
  const [newCognomeOperatore, setNewCognomeOperatore] = useState("");
  const [newMatricolaOperatore, setNewMatricolaOperatore] = useState({Matricola: ""});

  const [Matricola, setMatricola] = useState([]);
  const [TelaioMac, setTelaio] = useState([]);

  const dip = firebase.firestore().collection('Dipendenti');
  const mac = firebase.firestore().collection('Manutenzione');

  /*
    Funzione che in base al ruolo e al cantiere selezionato trova i dipendenti a sistema da mostrare nella select
  */
  function getMatricolaOperatore() {
    const role = window.sessionStorage.getItem('Role');
    const cantiere = window.sessionStorage.getItem('Cantiere');
    if (role == 'Admin') {
      dip.onSnapshot((querySnapshot) => {
        const items = [];
        querySnapshot.forEach((doc) => {
          items.push( { ... doc.data(), id: doc.id } );
        });
        setMatricola(items);
      });
    } else if (role == 'Admin_Cantiere') {
      dip.where('Cantiere', 'array-contains', cantiere).onSnapshot((querySnapshot) => {
        const items = [];
        querySnapshot.forEach((doc) => {
          items.push( { ... doc.data(), id: doc.id } );
        });
        setMatricola(items);
      });
    } else {
      dip.where('Zona', 'array-contains', cantiere).onSnapshot((querySnapshot) => {
        const items = [];
        querySnapshot.forEach((doc) => {
          items.push( { ... doc.data(), id: doc.id } );
        });
        setMatricola(items);
      });
    }
  }

  /*
    Funzione che filtra i macchinari in basi al ruolo dell'utente loggato, al
    cantiere selezionato e se non sono 4.0 (canId=='').
    Saranno i macchinari mostrati nella Select
  */
  function getTelaio() {
    const role = window.sessionStorage.getItem('Role');
    const cantiere = window.sessionStorage.getItem('Cantiere');
    if (role == 'Admin') {
      mac.where('CanId', '==', '').onSnapshot((querySnapshot) => {
        const items = [];
        querySnapshot.forEach((doc) => {
          items.push( { ... doc.data(), id: doc.id } );
        });
        
        setTelaio(items);
      });
    } else if (role == 'Admin_Cantiere') {
      mac.where('Cantiere', 'array-contains', cantiere).where('CanId', '==', '').onSnapshot((querySnapshot) => {
        const items = [];
        querySnapshot.forEach((doc) => {
          items.push( { ... doc.data(), id: doc.id } );
        });
        
        setTelaio(items);
      });
    } else {
      mac.where('Zona', 'array-contains', cantiere).where('CanId', '==', '').onSnapshot((querySnapshot) => {
        const items = [];
        querySnapshot.forEach((doc) => {
          items.push( { ... doc.data(), id: doc.id } );
        });
        
        setTelaio(items);
      });
    }
  }

  /*
    Funzione che in base al dipendente selezionato trova il nome e il cognome dell'operatore
   */
  function getNome() {
    if (newMatricolaOperatore.Matricola.split(" - ")[0] !== undefined && newMatricolaOperatore.Matricola.length > 0 ) {
        dip.where('Matricola', '==', newMatricolaOperatore.Matricola.split(" - ")[0]).onSnapshot((querySnapshot) => {
        const items = [];
        querySnapshot.forEach((doc) => {
          items.push( { ... doc.data(), id: doc.id } );
        });
        
        setNewNomeOperatore(items[0].Nome);
        setNewCognomeOperatore(items[0].Cognome);
      });
    }
  }

  /*
    Funzione che in base al macchinario selezionato trova il nome macchina, le ore calcolate e la variazione
   */
  function getModello() {   
    if (newTelaio.Telaio.split('-')[1] !== undefined && newTelaio.Telaio.length>0 ) {
        mac.where('Telaio', '==', newTelaio.Telaio.split('-')[1]).where('CanId', '==', '').onSnapshot((querySnapshot) => {
        const items = [];
        querySnapshot.forEach((doc) => {
          items.push( { ... doc.data(), id: doc.id } );
        });
        setNewModello(items[0].NomeMacchina);
        setNewOreCalcolate(items[0].OreCalcolate);
        setNewDelta(Math.trunc((items[0].OreCalcolate - newOreRilevate.Ore)*100)/100);
      });
    }
  }

  /**
   * Funzione che in base al macchinario selezionato aggiorna le ore del macchinario
   * @param {*} telaio 
   */
  const updateOre = (telaio) => {
  	const db = firebase.firestore();
  	let docId;
  	db.collection('Manutenzione').where('Telaio', '==', telaio).onSnapshot((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          docId = doc.id
        });
        
    	db.collection('Manutenzione').doc(docId).update({OreCalcolate: Number(newOreRilevate.Ore)});
    });
    reset();
  }

   /**
   * Funzione che aggiorna un anagrafica con i nuovi dati
   */
  const onUpdate=()=>{
    const db = firebase.firestore();
    db.collection('RilievoOre').doc(props.rilievo.id).set({ NomeOperatore: newNomeOperatore, CognomeOperatore: newCognomeOperatore, DataRilievo: newDataRilievo, MatricolaOperatore: newMatricolaOperatore.Matricola.substr(0,6), Telaio: newTelaio.Telaio.split('-')[1], Modello: newModello, OreRilevate: newOreRilevate.Ore, Delta: newDelta}, {merge: true});
    updateOre(newTelaio.Telaio.split('-')[1]);
    props.onEscLayer();
  }

   /*
    Funzione che resetta i campi dell'anagrafica
  */
  const reset=()=>{
    setNewMatricolaOperatore({Matricola: ""});
    setNewDataRilievo("");
    setNewTelaio({Telaio: ""});
    setNewOreRilevate({Ore: ""});
  }

   /*
    Funzione che resetta i campi dell'anagrafica
  */
  useEffect(() => {
    if(props.rilievo!==undefined){    
      setNewNomeOperatore(props.rilievo.NomeOperatore);
      setNewCognomeOperatore(props.rilievo.CognomeOperatore);
      setNewMatricolaOperatore({Matricola: props.rilievo.MatricolaOperatore+' - '+props.rilievo.NomeOperatore+' '+props.rilievo.CognomeOperatore});
      setNewDataRilievo(props.rilievo.DataRilievo);
      setNewTelaio({Telaio: props.rilievo.Modello+"-"+ props.rilievo.Telaio});
      setNewOreRilevate({Ore: props.rilievo.OreRilevate});
      setNewModello(props.rilievo.Modello);
      setNewOreCalcolate(props.rilievo.OreCalcolate);
      setNewDelta(Math.trunc((props.rilievo.OreCalcolate - props.rilievo.OreRilevate)*100)/100)
    }
    if(props.parcoMacchine!==undefined && props.Telaio!=="" && props.NomeMacchina!==""){
      setNewTelaio({Telaio: props.Modello+"-"+ props.Telaio});
    }
    getMatricolaOperatore();
    getTelaio();
  }, []);

  /*
    Serie di useEffect che controllano i filtri in base ai dati selezionati dall'utente
  */
  useEffect(() => {
    getNome();
  }, [newMatricolaOperatore]);

    useEffect(() => {
    getModello();
  }, [newTelaio, newOreRilevate]);

  return (
    <Box round pad="medium" direction="column" background="white" overflow="auto">
      <Heading level="2" margin="none" size="small">
        Inserisci ore macchinario rilevate
      </Heading>
      <Box fill gap="medium" pad={{ vertical: "small" }}>
        <Form>
        <Box direction="row" align="center">
          <Box pad="xsmall">
            <FormField required label="Matricola Operatore">
              <Select
                multiple={false}
                closeOnChange={true}
                options={[... new Set(Matricola.sort(function(a, b) { return a.Nome.localeCompare(b.Nome) }).map(dipendente => (dipendente.Matricola+' - '+dipendente.Nome+' '+dipendente.Cognome)))]}
                value={newMatricolaOperatore.Matricola}
                onChange={event => 
                  setNewMatricolaOperatore({Matricola: event.value})
                }
              />
            </FormField>
          </Box>
          <Box pad="xsmall">
            <FormField required label="Data del Rilievo">
              <DateInput
                format="dd/mm/yyyy"
                value={newDataRilievo}
                onChange={({ value }) => {setNewDataRilievo(moment(new Date(value)).format('YYYY-MM-DD'))}}
                calendarProps={{ size: "small", locale: "it-IT", daysOfWeek: true, firstDayOfWeek: 1 }}
            />
            </FormField>
          </Box>
        </Box>
        <Box direction="row" align="center">
          <Box pad="xsmall">
            <FormField required label="Macchinario">
              <Select
                multiple={false}
                closeOnChange={true}
                options={[... new Set(TelaioMac.sort(function(a, b) { return a.NomeMacchina.localeCompare(b.NomeMacchina) }).map(macchinario => (macchinario.NomeMacchina+'-'+macchinario.Telaio)))]}
                value={newTelaio.Telaio}
                onChange={event => 
                  setNewTelaio({Telaio: event.value})
                }
              />
            </FormField>
          </Box>
          <Box pad="xsmall">
            <FormField required label="Ore Rilevate su macchinario">
              <TextInput
                placeholder="Inserire numero"
                value={newOreRilevate.Ore}
                onChange={e => setNewOreRilevate({Ore: e.target.value})}
              />
            </FormField>
          </Box>
        </Box>
      </Form>
      <Box direction="row" gap="medium" alignSelf="end">
          <Button margin="small" label={(props.rilievo==undefined) ? "Aggiungi" : "Aggiorna"} onClick={(props.rilievo==undefined) ? onAdd : onUpdate} style={{width:'138px',height:'34px',fontSize:'15px',background:'#006837',color:'white',fontWeight:'bold',textTransform: 'uppercase'}} />
          <Button margin="small" type="reset" label={(props.rilievo==undefined)?"Reset":"Cancella"}  onClick={(props.rilievo==undefined)?reset:props.onDelete} style={{marginLeft:'5px',width:'138px',height:'34px',fontSize:'15px',background:'#c51d34',color:'white',fontWeight:'bold',textTransform: 'uppercase', borderColor:'#c51d34'}}/>
        </Box>
      </Box>
    </Box>
  )
}